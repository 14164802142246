import "./main.css";
import {
    Elm
} from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import "../static/css/generated-tailwind.css";
import "../public/assets/style/main-style.css";
import SlideElement from "./slide_element";

// Static assets (e.g. images).
import bookshelfPath from "../public/images/bookshelf.png";
import prezanceLogoBlackPath from "../public/images/xequiv-logo.png";
import blankProfilePath from "../public/images/blank-profile.png";
import tablePath from "../public/images/table.png";
import tableauPath from "../public/images/tableau.svg";
import textPath from "../public/images/text.svg";
import graphPath from "../public/images/graph.svg";
import paysagePath from "../public/images/paysage.svg";
import videoPath from "../public/images/video.svg";
import pdfPath from "../public/images/pdf.svg";
import magnetWhitePath from "../public/images/magnetic-zone/magnetwhite.png";
import magnetUpdateWhitePath from "../public/images/magnetic-zone/magnetupdatewhite.png";
import iconModeZa1Path from "../public/images/magnetic-zone/icon-mode-za1.png";
import h1IconPath from "../public/images/h1-icon.svg";
import h2IconPath from "../public/images/h2-icon.svg";
import magnetIconPath from "../public/images/magnet-icon.svg";
import magnetColorPath from "../public/images/magnetic-zone/aimant-2d.png";
import attributeIconPath from "../public/images/variable.svg";
import addIconPath from "../public/images/common-icons/ajout.svg";
import videoLinkPath from "../public/images/videolink.svg";
import framePath from "../public/images/frame.svg";
import lorem3Path from "../public/images/lorem3.svg";
import lorem1Path from "../public/images/lorem1.svg";
import lorem2Path from "../public/images/lorem2.svg";
import fakeAlignPath from "../public/images/fake-align.svg";
import ticketPath from "../public/images/ticket.svg";
import basrosePath from "../public/images/bas-rose.svg";
import basnoirPath from "../public/images/bas-noir.svg";
import barPath from "../public/images/bar.svg";
import settingsPath from "../public/images/settings.svg";
import positionPath from "../public/images/position.svg"
import sonnettePath from "../public/images/sonnette.svg";
import ellipsePath from "../public/images/ellipse.svg"
import projectPath from "../public/images/icon-project.svg";
import stepPath from "../public/images/step.svg";


const prezanceUserKey = "prezance_user";

const storedUser = localStorage.getItem(prezanceUserKey);

SlideElement.register();


// -------------------------------------------------------------------------------------------------
// https://discourse.elm-lang.org/t/custom-elements-extend-svg-real-coordinates-on-mouse-events/1762
// Their solution did not work if the SVG was inside a div
// So I just query the SVG by class name "svg-events"
//
// We need a "svg-events" class on all the thing we need svg coordinates
// -------------------------------------------------------------------------------------------------
function onMouseUp(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseUp', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.preventDefault();
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onMouseDown(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseDown', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onMouseMove(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgMouseMove', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
}

function onContextMenu(event) {
    var point = this.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    var position = point.matrixTransform(this.getScreenCTM().inverse())
    var svgClickEvent = new CustomEvent('svgContextMenu', {
        detail: {
            x: position.x,
            y: position.y,
            clientX: event.clientX,
            clientY: event.clientY
        }
    });
    event.currentTarget.dispatchEvent(svgClickEvent);
    event.preventDefault()
}

var SVG_EVENTS_CLASS_NAME = "svg-events"
var observer = new MutationObserver(function (mutations) {
    // look through all mutations that just occured
    for (var i = 0; i < mutations.length; ++i) {
        // look through all added nodes of this mutation
        for (var j = 0; j < mutations[i].addedNodes.length; ++j) {
            var addedNode = mutations[i].addedNodes[j]

            // the node itself can be a svg-events
            if (addedNode.classList && addedNode.classList.contains(SVG_EVENTS_CLASS_NAME)) {
                //console.log("adding events listeners to SVG element:", addedNode)

                addedNode.removeEventListener('contextmenu', onContextMenu)
                addedNode.removeEventListener('mouseup', onMouseUp)
                addedNode.removeEventListener('mousedown', onMouseDown)
                addedNode.removeEventListener('mousemove', onMouseMove)
                addedNode.addEventListener('contextmenu', onContextMenu)
                addedNode.addEventListener('mouseup', onMouseUp)
                addedNode.addEventListener('mousedown', onMouseDown)
                addedNode.addEventListener('mousemove', onMouseMove)
            }

            // and maybe some of its children
            if (addedNode.getElementsByClassName) {
                Array.from(addedNode.getElementsByClassName(SVG_EVENTS_CLASS_NAME))
                    .forEach(function (node) {
                        //console.log("adding events listeners to SVG element:", node)

                        node.removeEventListener('contextmenu', onContextMenu)
                        node.removeEventListener('mouseup', onMouseUp)
                        node.removeEventListener('mousedown', onMouseDown)
                        node.removeEventListener('mousemove', onMouseMove)
                        node.addEventListener('contextmenu', onContextMenu)
                        node.addEventListener('mouseup', onMouseUp)
                        node.addEventListener('mousedown', onMouseDown)
                        node.addEventListener('mousemove', onMouseMove)
                    });
            }

            // sometimes the mutation is not on the SVG but on the G
            // example when adding a variant in viewer
            if (addedNode.tagName == "g" && addedNode.parentElement.classList.contains(SVG_EVENTS_CLASS_NAME)) {

                //console.log("adding events listeners to SVG element:", addedNode.parentElement)

                addedNode.parentElement.removeEventListener('contextmenu', onContextMenu)
                addedNode.parentElement.removeEventListener('mouseup', onMouseUp)
                addedNode.parentElement.removeEventListener('mousedown', onMouseDown)
                addedNode.parentElement.removeEventListener('mousemove', onMouseMove)
                addedNode.parentElement.addEventListener('contextmenu', onContextMenu)
                addedNode.parentElement.addEventListener('mouseup', onMouseUp)
                addedNode.parentElement.addEventListener('mousedown', onMouseDown)
                addedNode.parentElement.addEventListener('mousemove', onMouseMove)
            }
        }
    }
});
observer.observe(document.body, {
    childList: true,
    subtree: true
});
// -------------------------------------------------------------------------------------------------

const app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: {
        user: storedUser,
        locale: navigator.language || navigator.userLanguage,
        assets: {
            bookshelfPath: bookshelfPath,
            prezanceLogoBlackPath: prezanceLogoBlackPath,
            blankProfilePath: blankProfilePath,
            tablePath: tablePath,
            graphPath: graphPath,
            videoPath: videoPath,
            pdfPath: pdfPath,
            videoLinkPath: videoLinkPath,
            magnetWhitePath: magnetWhitePath,
            magnetUpdateWhitePath: magnetUpdateWhitePath,
            iconModeZa1Path: iconModeZa1Path,
            h1IconPath: h1IconPath,
            h2IconPath: h2IconPath,
            textPath: textPath,
            paysagePath: paysagePath,
            tableauPath: tableauPath,
            magnetIconPath: magnetIconPath,
            magnetColorPath: magnetColorPath,
            addIconPath: addIconPath,
            attributeIconPath: attributeIconPath,
            projectPath: projectPath,
            framePath: framePath,
            lorem3Path: lorem3Path,
            lorem1Path: lorem1Path,
            lorem2Path: lorem2Path,
            fakeAlignPath: fakeAlignPath,
            ticketPath: ticketPath,
            positionPath: positionPath,
            sonnettePath: sonnettePath,
            barPath: barPath,
            basrosePath: basrosePath,
            settingsPath: settingsPath,
            basnoirPath: basnoirPath,
            basrosePath: basrosePath,
            ellipsePath: ellipsePath,
            stepPath: stepPath
        },
    },
});


app.ports.storeUser.subscribe((user) => {
    localStorage.setItem(prezanceUserKey, user);
});

app.ports.logout.subscribe((_) => {
    localStorage.removeItem(prezanceUserKey);
});

window.addEventListener('storage', function (event) {
    if (event.key === prezanceUserKey && event.newValue === null) {
        app.ports.userLoggedOutOnAnotherTab.send(null);
    }
}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


