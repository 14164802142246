(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cW.by === region.ds.by)
	{
		return 'on line ' + region.cW.by;
	}
	return 'on lines ' + region.cW.by + ' through ' + region.ds.by;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gO,
		impl.jr,
		impl.i4,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		hn: func(record.hn),
		i_: record.i_,
		h8: record.h8
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.hn;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.i_;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.h8) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gO,
		impl.jr,
		impl.i4,
		function(sendToApp, initialModel) {
			var view = impl.jI;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gO,
		impl.jr,
		impl.i4,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cT && impl.cT(sendToApp)
			var view = impl.jI;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.D);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.jl) && (_VirtualDom_doc.title = title = doc.jl);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.hR;
	var onUrlRequest = impl.hS;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cT: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.dY === next.dY
							&& curr.dy === next.dy
							&& curr.dU.a === next.dU.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gO: function(flags)
		{
			return A3(impl.gO, flags, _Browser_getUrl(), key);
		},
		jI: impl.jI,
		jr: impl.jr,
		i4: impl.i4
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gG: 'hidden', fd: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gG: 'mozHidden', fd: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gG: 'msHidden', fd: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gG: 'webkitHidden', fd: 'webkitvisibilitychange' }
		: { gG: 'hidden', fd: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		d4: _Browser_getScene(),
		eo: {
			eq: _Browser_window.pageXOffset,
			er: _Browser_window.pageYOffset,
			ep: _Browser_doc.documentElement.clientWidth,
			dx: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ep: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		dx: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			d4: {
				ep: node.scrollWidth,
				dx: node.scrollHeight
			},
			eo: {
				eq: node.scrollLeft,
				er: node.scrollTop,
				ep: node.clientWidth,
				dx: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			d4: _Browser_getScene(),
			eo: {
				eq: x,
				er: y,
				ep: _Browser_doc.documentElement.clientWidth,
				dx: _Browser_doc.documentElement.clientHeight
			},
			fU: {
				eq: x + rect.left,
				er: y + rect.top,
				ep: rect.width,
				dx: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.j.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.j.b, xhr)); });
		$elm$core$Maybe$isJust(request.bg) && _Http_track(router, xhr, request.bg.a);

		try {
			xhr.open(request.aE, request.h, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.h));
		}

		_Http_configureRequest(xhr, request);

		request.D.a && xhr.setRequestHeader('Content-Type', request.D.a);
		xhr.send(request.D.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.aA; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.aK.a || 0;
	xhr.responseType = request.j.d;
	xhr.withCredentials = request.eN;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		h: xhr.responseURL,
		iX: xhr.status,
		iY: xhr.statusText,
		aA: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			iE: event.loaded,
			eb: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ie: event.loaded,
			eb: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.hu) { flags += 'm'; }
	if (options.fc) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$UrlRequested = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.p) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.q),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.q);
		} else {
			var treeLen = builder.p * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.t) : builder.t;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.p);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.q) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.q);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{t: nodeList, p: (len / $elm$core$Array$branchFactor) | 0, q: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {du: fragment, dy: host, dS: path, dU: port_, dY: protocol, ce: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$GotOpenUserResponse = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Main$NotFound = {$: 0};
var $author$project$Main$Unlogged = {$: 0};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$LoggedIn$Token = $elm$core$Basics$identity;
var $author$project$LoggedIn$User = F8(
	function (email, token, id, user, teams, enterprise, isAdmin, isManager) {
		return {dr: email, f5: enterprise, a$: id, gV: isAdmin, gW: isManager, jc: teams, jo: token, jt: user};
	});
var $elm$json$Json$Decode$map8 = _Json_map8;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$LoggedIn$decoderForLocalStorage = A9(
	$elm$json$Json$Decode$map8,
	$author$project$LoggedIn$User,
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'token',
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($krisajenkins$remotedata$RemoteData$NotAsked),
	$elm$json$Json$Decode$succeed($krisajenkins$remotedata$RemoteData$NotAsked),
	$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
	$elm$json$Json$Decode$succeed(false),
	$elm$json$Json$Decode$succeed(false));
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$LoggedIn$decodeStorage = function (user) {
	return A2(
		$elm$core$Maybe$andThen,
		function (value) {
			return $elm$core$Result$toMaybe(
				A2($elm$json$Json$Decode$decodeString, $author$project$LoggedIn$decoderForLocalStorage, value));
		},
		user);
};
var $author$project$Domain$Cart$Cart = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Domain$Cart$empty = $elm$core$Dict$empty;
var $author$project$Lang$En$mainLocale = {
	et: 'A message with confirmation link has been sent to',
	eu: 'About your new project',
	ew: 'Actualisation',
	ey: '+ slide',
	ez: 'Add new user',
	eA: 'Add a sub team',
	eB: 'Add user',
	eC: function (i) {
		return (!i) ? 'No alert' : ((i === 1) ? '1 alert' : ($elm$core$String$fromInt(i) + ' alerts'));
	},
	eE: 'Objects alignment',
	eF: 'Align bottom',
	eG: 'Align center',
	eH: 'Align left',
	eI: 'Align middle',
	eJ: 'Align right',
	eK: 'Align top',
	eL: 'Relative to selection',
	eM: 'Relative to slide',
	eO: 'Already have an account?',
	eQ: 'An account with this email already exists.',
	eR: 'An error occured. Please try again.',
	eV: 'Attributes',
	eX: 'Entities',
	eY: 'Names',
	eZ: 'Purposes',
	e_: 'Entity',
	e$: 'Name',
	e0: 'Purpose',
	e1: 'Author',
	e2: 'Authorization',
	e3: 'Back to login page',
	e4: 'Back to teams',
	fa: 'Cancel',
	fb: 'Panier',
	fe: 'Charts',
	fi: 'Confirm Password',
	fj: 'The validation failed, the token must be expired!',
	fk: 'Your account is being validated, it should take a few seconds.',
	fl: 'Your email address is now validated!',
	fm: 'Account\'s email validation',
	fn: function (i) {
		var plural = (i > 1) ? 'values' : 'value';
		return 'Constrained to ' + ($elm$core$String$fromInt(i) + (' ' + plural));
	},
	fp: 'Create a copy',
	fq: 'Create template',
	dh: 'Creation date',
	fr: 'Creation',
	fv: 'CSVs',
	fC: 'Decreasing',
	fD: 'Delete',
	fE: 'Delete all versions',
	fF: 'Delete this version',
	fG: 'Details',
	fH: 'Diffuse',
	fI: 'Diffuse the document',
	fJ: 'Diffusion',
	fK: 'Diffusions',
	fL: 'Objects distribution',
	fM: 'Distribute horizontally',
	fN: 'Distribute vertically',
	fO: 'Document',
	fP: 'Document\'s attributes',
	fQ: 'Documents',
	fS: 'Download CSV File',
	fT: 'edit',
	fV: 'Chart',
	fW: 'Image',
	fX: 'Table',
	fY: 'Text',
	fZ: 'Count of documents using it',
	f_: 'Video',
	f$: 'Count of receivers that viewed it',
	f0: 'Elements to share:',
	dr: 'Email',
	f2: 'you@company.com',
	f4: 'Enter team name',
	f6: 'Enterprises',
	f7: 'Manager',
	f8: 'Managers',
	f9: 'Enterprise\'s name',
	ga: 'Entities',
	gc: 'Error',
	gd: 'An error occurred when fetching the data from the server.',
	ge: 'An error occurred when fetching the data from the server.',
	gf: 'Error during users recuperation',
	gn: 'Fetching dimension',
	go: 'File',
	cA: 'First name',
	gr: 'Fixed color',
	gt: 'Forgot password?',
	gu: 'We\'ll send you an email with a link to reset your password.',
	gv: 'Forgot your password?',
	gx: 'From library',
	gz: 'Insert a chart',
	gB: 'Grid',
	gF: 'Heading',
	gJ: 'Image',
	gK: 'Images',
	gM: 'Increasing',
	gP: 'Inserting an image',
	gR: 'Invite',
	gS: 'Employee\'s email',
	gT: 'To invite someone in the enterprise, he/she must create a free xEquiv account first. Then fill-in his/her email address in the form below.',
	gU: 'Invitation instructions',
	gZ: 'Key words',
	dF: 'Last modification date',
	cI: 'Last name',
	g0: 'Legend',
	g1: 'Library',
	g3: 'List',
	g4: 'Loading...',
	g5: 'Events',
	g6: 'Your email or password is incorrect!',
	g7: 'Logout',
	hb: 'Magnet',
	hd: 'Display mode',
	hf: 'Match',
	hg: 'Match all',
	hh: 'Magnet insertion menu',
	hl: 'Media insertion',
	hm: 'Members',
	ho: 'Teams are reserved to the Enterprise offer !',
	hs: 'Send backward',
	ht: 'Bring forward',
	hw: 'Magnets',
	hx: 'Objects',
	hy: 'Create an enterprise',
	hz: 'New project',
	hA: 'New Team',
	hB: 'New user',
	hC: 'No',
	hD: 'No element',
	hE: 'No error!',
	hM: 'None',
	hN: 'N/A',
	hP: 'Object version',
	hV: 'otherWord',
	hW: 'Owner',
	hX: '404: Page not found',
	hY: 'Paragraph',
	hZ: 'Password (at least 12 characters)',
	dR: 'Password',
	h_: 'The password has to be at least 12 characters long',
	h0: 'PDF',
	h2: 'Period',
	h3: 'Authorization',
	h4: 'Please click on the link to confirm your email.',
	h9: 'Print',
	id: 'Don\'t have an account?',
	$8: 'Receiver',
	ig: 'Recipients',
	ih: 'You may now sign-in.',
	ij: 'Register',
	ik: 'Delete the document',
	il: 'Request reset link',
	im: 'Reset the password',
	$9: 'Please enter your new password (at least 12 characters).',
	io: 'Reset your password',
	ip: 'If the email provided is registered at xEquiv, a reset link should arrive in a few.',
	iq: 'Check your inbox!',
	iv: 'Save',
	iw: 'Change for all versions',
	ix: 'Change for current version only',
	iA: 'Search',
	iB: 'Search by name',
	iC: 'Search users',
	iF: 'Set as background',
	iG: 'Settings',
	iI: 'Shape insertion',
	iJ: 'Shared at',
	iK: 'Shared by',
	iL: 'Shared with',
	iM: 'Share',
	iP: 'Sign in',
	iQ: 'Slide\'s attributes',
	iR: 'Background',
	iS: 'Name the slide',
	iT: 'Slides',
	iV: 'Sources',
	iW: 'Spreadsheets',
	i$: 'Style',
	i0: 'Subheading',
	i2: 'Insert the image',
	i3: 'Create document',
	cX: 'Subteams',
	i6: 'Spreadsheet insertion',
	jb: 'Team',
	jc: 'Teams',
	je: 'Templates',
	jf: 'Text',
	jg: 'Text insertion',
	ji: 'Texts',
	jj: 'The email is invalid',
	jo: 'Token',
	jq: 'Type',
	jv: 'Members',
	jy: 'Validate',
	jA: 'Version',
	jB: 'Version Information',
	jC: 'Versionning',
	jE: 'video',
	jF: 'Link video',
	jJ: 'View full screen',
	jK: function (i) {
		return (!i) ? 'No warning' : ((i === 1) ? '1 warning' : ($elm$core$String$fromInt(i) + ' warnings'));
	},
	jL: 'Word',
	jN: 'Yes'
};
var $author$project$Lang$Fr$mainLocale = {
	et: 'Un courriel de validation a été envoyé à',
	eu: 'About your new project',
	ew: 'Actualisation',
	ey: '+ diapo',
	ez: 'Add new user',
	eA: 'Add a sub team',
	eB: 'Add user',
	eC: function (i) {
		return (!i) ? 'Aucune alerte' : ((i === 1) ? '1 alerte' : ($elm$core$String$fromInt(i) + ' alertes'));
	},
	eE: 'Alignement des objets',
	eF: 'Align en bas',
	eG: 'Aligner au centre',
	eH: 'Aligner à gauche',
	eI: 'Align au milieu',
	eJ: 'Aligner à droite',
	eK: 'Align en haut',
	eL: 'Sur la sélection',
	eM: 'Sur le diapo',
	eO: 'Vous avez déjà un compte ?',
	eQ: 'An account with this email already exists.',
	eR: 'An error occured. Please try again.',
	eV: 'Attributs',
	eX: 'Entités',
	eY: 'Libellés',
	eZ: 'Finalités',
	e_: 'Entité',
	e$: 'Libellé',
	e0: 'Finalité',
	e1: 'Author',
	e2: 'Authorization',
	e3: 'Retour à la page de connexion',
	e4: 'Back to teams',
	fa: 'Annuler',
	fb: 'Panier',
	fe: 'Graphiques',
	fi: 'Mot de passe à nouveau',
	fj: 'The validation failed, the token must be expired!',
	fk: 'Your account is being validated, it should take a few seconds.',
	fl: 'Your email address is now validated!',
	fm: 'Account\'s email validation',
	fn: function (i) {
		var plural = (i > 1) ? 'valeurs' : 'valeur';
		return 'Limité à ' + ($elm$core$String$fromInt(i) + (' ' + plural));
	},
	fp: 'Faire une copie',
	fq: 'Créer modèle',
	dh: 'Date de création',
	fr: 'Creation',
	fv: 'Données CSV',
	fC: 'Decreasing',
	fD: 'Supprimer',
	fE: 'Supprimer toutes les versions',
	fF: 'Supprimer cette version',
	fG: 'Details',
	fH: 'Diffuser',
	fI: 'Diffuser la présentation',
	fJ: 'Diffusion',
	fK: 'Diffusions',
	fL: 'Distribution des objets',
	fM: 'Distribuer horizontalement',
	fN: 'Distribuer verticalement',
	fO: 'Présentation',
	fP: 'Attr. de la présentation',
	fQ: 'Présentations',
	fS: 'Download CSV File',
	fT: 'Editer',
	fV: 'Graphique',
	fW: 'Image',
	fX: 'Tableau',
	fY: 'Texte',
	fZ: 'Nombre de documents qui l\'utilise',
	f_: 'Vidéo',
	f$: 'Nombre de destinataires qui l\'ont vu',
	f0: 'Liste des éléments à publier:',
	dr: 'Courriel',
	f2: 'j.dupont@entreprise.fr',
	f4: 'Enter team name',
	f6: 'Entreprises',
	f7: 'Responsable',
	f8: 'Responsables',
	f9: 'Nom de l\'entreprise',
	ga: 'Entities',
	gc: 'Error',
	gd: 'An error occurred when fetching the data from the server.',
	ge: 'Une erreur de chargement des données s\'est produite.',
	gf: 'Error during users recuperation',
	gn: 'Fetching dimension',
	go: 'Envoyer un fichier',
	cA: 'Prénom',
	gr: 'Couleur unie',
	gt: 'Mot de passe oublié ?',
	gu: 'Entrez votre courriel ci-dessous et nous vous enverrons un lien pour changer de mot de passe.',
	gv: 'Demande de réinitialisation du mot de passe',
	gx: 'From library',
	gz: 'Insérer un graphique',
	gB: 'Grid',
	gF: 'Heading',
	gJ: 'Image',
	gK: 'Images',
	gM: 'Increasing',
	gP: 'Inserting an image',
	gR: 'Inviter',
	gS: 'Courriel de l\'employé',
	gT: 'Pour inviter une personne dans l\'entreprise, il faut qu\'elle crée un compte gratuit sur xEquiv. Saisissez ensuite son adresse courriel ci-dessous.',
	gU: 'Instructions',
	gZ: 'Key words',
	dF: 'Date de dernière modification',
	cI: 'Nom de famille',
	g0: 'Legend',
	g1: 'Bibliothèque',
	g3: 'List',
	g4: 'Chargement...',
	g5: 'Evenements',
	g6: 'Le courriel ou le mot de passe est invalide !',
	g7: 'Déconnexion',
	hb: 'Z. aimanté',
	hd: 'Mode d\'affichage',
	hf: 'Trouver',
	hg: 'Trouver tous',
	hh: 'Menu d\'ajout de zone aimantées',
	hl: 'Insertion de média',
	hm: 'Members',
	ho: 'La gestion des équipes est réservée à l\'offre \'entreprise\' !',
	hs: 'Reculer',
	ht: 'Avancer',
	hw: 'Zone aimantées',
	hx: 'Insertion d\'objets',
	hy: 'Créer une entreprise',
	hz: 'Créer une présentation',
	hA: 'New Team',
	hB: 'New user',
	hC: 'Non',
	hD: 'No element',
	hE: 'No error!',
	hM: 'Aucune',
	hN: 'N/A',
	hP: 'Object version',
	hV: 'otherWord',
	hW: 'Propriétaire',
	hX: '404: Page not found',
	hY: 'Paragraph',
	hZ: '12 caractères minimum',
	dR: 'Mot de passe',
	h_: 'The password has to be at least 12 characters long',
	h0: 'PDF',
	h2: 'Period',
	h3: 'Permissions',
	h4: 'Veuillez cliquer sur le lien de confirmation.',
	h9: 'Imprimer',
	id: 'Vous n\'avez pas de compte ?',
	$8: 'Destinataire',
	ig: 'Destinataires',
	ih: 'Vous pouvez dès à présent vous identifier.',
	ij: 'Créez-en un',
	ik: 'Supprimer la présentation',
	il: 'Continuer',
	im: 'Reset the password',
	$9: 'Please enter your new password (at least 12 characters).',
	io: 'Reset your password',
	ip: 'Si l\'adresse correspond bien à un compte xEquiv, des instructions vous ont été envoyées.',
	iq: 'Vérifiez vos courriels',
	iv: 'Enregistrer',
	iw: 'Changer pour toutes les versions',
	ix: 'Changer pour cette version',
	iA: 'Search',
	iB: 'Recherche par libellé',
	iC: 'Search users',
	iF: 'Définir comme image de fond',
	iG: 'Settings',
	iI: 'Insertion de formes',
	iJ: 'Date de partage',
	iK: 'Partagée par',
	iL: 'Partagée avec',
	iM: 'Share',
	iP: 'Connexion',
	iQ: 'Attr. du diapo',
	iR: 'Fond',
	iS: 'Saisir un nom',
	iT: 'Diapos',
	iV: 'Sources',
	iW: 'Tableaux',
	i$: 'Style',
	i0: 'Subheading',
	i2: 'Insert the image',
	i3: 'Créer la présentation',
	cX: 'Subteams',
	i6: 'Insérer un tableau',
	jb: 'Team',
	jc: 'Équipes',
	je: 'Modèles',
	jf: 'Texte',
	jg: 'Insertion de texte',
	ji: 'Textes',
	jj: 'Le courriel n\'est pas valide',
	jo: 'Token',
	jq: 'Type',
	jv: 'Utilisateurs',
	jy: 'Validate',
	jA: 'Version',
	jB: 'Version Information',
	jC: 'Gestion des versions',
	jE: 'video',
	jF: 'Link video',
	jJ: 'Présenter',
	jK: function (i) {
		return (!i) ? 'Aucune info' : ((i === 1) ? '1 info' : ($elm$core$String$fromInt(i) + ' infos'));
	},
	jL: 'Word',
	jN: 'Oui'
};
var $author$project$Api$OpenUserResult = F5(
	function (user, enterprise, teams, isAdmin, isManager) {
		return {f5: enterprise, gV: isAdmin, gW: isManager, jc: teams, jt: user};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Api$buildUrl = F2(
	function (parts, params) {
		return A2(
			$elm$url$Url$Builder$absolute,
			A2($elm$core$List$cons, 'api', parts),
			params);
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Domain$Enterprise$Enterprise = F4(
	function (id, name, managers, members) {
		return {a$: id, dJ: managers, hm: members, dL: name};
	});
var $author$project$Domain$Author$Author = F2(
	function (id, name) {
		return {a$: id, dL: name};
	});
var $author$project$Domain$Author$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Domain$Author$Author,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Domain$Enterprise$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$Enterprise$Enterprise,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'managers',
		$elm$json$Json$Decode$list($author$project$Domain$Author$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'members',
		$elm$json$Json$Decode$list($author$project$Domain$Author$decoder)));
var $author$project$Domain$User$User = F4(
	function (id, email, firstname, lastname) {
		return {dr: email, cA: firstname, a$: id, cI: lastname};
	});
var $author$project$Domain$User$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$User$User,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'firstname', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'lastname', $elm$json$Json$Decode$string));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.iX));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {d0: reqs, eh: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.bg;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.d0));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.eh)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					eN: r.eN,
					D: r.D,
					j: A2(_Http_mapExpect, func, r.j),
					aA: r.aA,
					aE: r.aE,
					aK: r.aK,
					bg: r.bg,
					h: r.h
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{eN: true, D: r.D, j: r.j, aA: r.aA, aE: r.aE, aK: r.aK, bg: r.bg, h: r.h}));
};
var $author$project$LoggedIn$toString = function (_v0) {
	var token = _v0;
	return token;
};
var $author$project$Api$request = F2(
	function (token, data) {
		return $elm$http$Http$riskyRequest(
			{
				D: data.D,
				j: data.j,
				aA: A2(
					$elm$core$List$cons,
					A2(
						$elm$http$Http$header,
						'Authorization',
						'Bearer ' + $author$project$LoggedIn$toString(token)),
					data.aA),
				aE: data.aE,
				aK: data.aK,
				bg: data.bg,
				h: data.h
			});
	});
var $author$project$Api$get = F2(
	function (token, _v0) {
		var url = _v0.h;
		var expect = _v0.j;
		return A2(
			$author$project$Api$request,
			token,
			{D: $elm$http$Http$emptyBody, j: expect, aA: _List_Nil, aE: 'GET', aK: $elm$core$Maybe$Nothing, bg: $elm$core$Maybe$Nothing, h: url});
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Api$openUser = F2(
	function (token, toMsg) {
		var decoderTeams = A2(
			$elm$json$Json$Decode$field,
			'result',
			A2(
				$elm$json$Json$Decode$field,
				'teams',
				$elm$json$Json$Decode$list(
					A3(
						$elm$json$Json$Decode$map2,
						F2(
							function (id, name) {
								return {a$: id, dL: name};
							}),
						A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
						A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string)))));
		var decoder = A6(
			$elm$json$Json$Decode$map5,
			$author$project$Api$OpenUserResult,
			A2(
				$elm$json$Json$Decode$field,
				'result',
				A2($elm$json$Json$Decode$field, 'user', $author$project$Domain$User$decoder)),
			A2(
				$elm$json$Json$Decode$field,
				'result',
				A2(
					$elm$json$Json$Decode$field,
					'enterprise',
					$elm$json$Json$Decode$maybe($author$project$Domain$Enterprise$decoder))),
			decoderTeams,
			A2(
				$elm$json$Json$Decode$field,
				'result',
				A2($elm$json$Json$Decode$field, 'admin', $elm$json$Json$Decode$bool)),
			A2(
				$elm$json$Json$Decode$field,
				'result',
				A2($elm$json$Json$Decode$field, 'manager', $elm$json$Json$Decode$bool)));
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					decoder),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'OpenUser']),
					_List_Nil)
			});
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$Main$AdminPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$ConfirmRegistrationPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$EnterprisePage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotAdminPageMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotConfirmRegistrationPageMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$GotEnterprisePageMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$GotLibraryPageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotLoginPageMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotMainLayoutMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$GotRegisterPageMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$GotResetPasswordPageMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotTeamPageMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$GotTestPageMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Library = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Main$LibraryPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$LoggedIn = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Route$Login = {$: 1};
var $author$project$Main$LoginPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Main = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$RegisterPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$ResetPasswordPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$TeamPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$TestPage = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Page$Admin$GotUserAdminMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Admin$User = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$UserAdmin$GotUsersResponse = function (a) {
	return {$: 0, a: a};
};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Api$adminListUsers = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'users',
							$elm$json$Json$Decode$list($author$project$Domain$User$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'AdminListUsers']),
					_List_Nil)
			});
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $billstclair$elm_sortable_table$Table$State = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $billstclair$elm_sortable_table$Table$initialSort = function (header) {
	return A2($billstclair$elm_sortable_table$Table$State, header, false);
};
var $author$project$Page$UserAdmin$init = function (token) {
	return _Utils_Tuple2(
		{
			af: $elm$core$Maybe$Nothing,
			ce: '',
			cm: $elm$random$Random$initialSeed(0),
			cp: $billstclair$elm_sortable_table$Table$initialSort('Name'),
			jo: token,
			jv: $krisajenkins$remotedata$RemoteData$Loading
		},
		A2($author$project$Api$adminListUsers, token, $author$project$Page$UserAdmin$GotUsersResponse));
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Page$Admin$init = F2(
	function (key, user) {
		var _v0 = function () {
			var _v1 = $author$project$Page$UserAdmin$init(user.jo);
			var m = _v1.a;
			var c = _v1.b;
			return _Utils_Tuple2(
				$author$project$Page$Admin$User(m),
				A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotUserAdminMsg, c));
		}();
		var page = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			{k: key, d: page, jt: user},
			cmd);
	});
var $author$project$Page$Confirm$ConfirmationResponse = $elm$core$Basics$identity;
var $author$project$Page$Confirm$Pending = 0;
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{eN: false, D: r.D, j: r.j, aA: r.aA, aE: r.aE, aK: r.aK, bg: r.bg, h: r.h}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{D: $elm$http$Http$emptyBody, j: r.j, aA: _List_Nil, aE: 'GET', aK: $elm$core$Maybe$Nothing, bg: $elm$core$Maybe$Nothing, h: r.h});
};
var $author$project$Api$confirm = F2(
	function (token, toMsg) {
		return $elm$http$Http$get(
			{
				j: $elm$http$Http$expectWhatever(toMsg),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['accounts', 'users', 'confirm', token]),
					_List_Nil)
			});
	});
var $author$project$Page$Confirm$init = function (token) {
	return _Utils_Tuple2(
		{bG: 0, jo: token},
		A2($author$project$Api$confirm, token, $elm$core$Basics$identity));
};
var $author$project$Page$Enterprise$GotUsersResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$getUsers2 = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'users',
							$elm$json$Json$Decode$list($author$project$Domain$User$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'ListUsers']),
					_List_Nil)
			});
	});
var $author$project$Page$Enterprise$init = F2(
	function (user, enterprise) {
		return _Utils_Tuple2(
			{
				f5: enterprise,
				b5: '',
				cm: $elm$random$Random$initialSeed(0),
				cp: $billstclair$elm_sortable_table$Table$initialSort('Firstname'),
				jo: user.jo,
				jv: $krisajenkins$remotedata$RemoteData$Loading
			},
			A2($author$project$Api$getUsers2, user.jo, $author$project$Page$Enterprise$GotUsersResponse));
	});
var $author$project$Page$Library$Excel = 0;
var $author$project$Page$Library$GotCurrentTime = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Library$ListElementsResponse = function (a) {
	return {$: 7, a: a};
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Utils$getCurrentTime = function (toMsg) {
	return A2($elm$core$Task$perform, toMsg, $elm$time$Time$now);
};
var $author$project$Ui$SearchAttrsBar$State = $elm$core$Basics$identity;
var $author$project$Ui$Dropdown$State = $elm$core$Basics$identity;
var $author$project$Ui$Dropdown$init = {ac: _List_Nil, aS: false, cS: ''};
var $author$project$Ui$SearchAttrsBar$init = {
	bq: $author$project$Ui$Dropdown$init,
	br: $author$project$Ui$Dropdown$init,
	cx: $author$project$Ui$Dropdown$init,
	bs: {ga: _List_Nil, ic: _List_Nil, d_: _List_Nil},
	cS: ''
};
var $author$project$Domain$ElementForLibrary$ElementForLibrary = F5(
	function (currentVersion, versions, id, attrs, lifecycle) {
		return {eW: attrs, fx: currentVersion, a$: id, g2: lifecycle, jD: versions};
	});
var $author$project$Domain$Attributes$Attributes = F3(
	function (purpose, entity, name) {
		return {gb: entity, dL: name, ib: purpose};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Domain$Attributes$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Domain$Attributes$Attributes,
	A2($elm$json$Json$Decode$field, 'purpose', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'entity', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$Domain$Lifecycle$Lifecycle = F6(
	function (createdAt, createdAtTimestamp, createdBy, lastModifiedAt, lastModifiedAtTimestamp, lastModifiedBy) {
		return {dh: createdAt, di: createdAtTimestamp, dj: createdBy, dF: lastModifiedAt, dG: lastModifiedAtTimestamp, dH: lastModifiedBy};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Domain$Lifecycle$decoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Domain$Lifecycle$Lifecycle,
	A2(
		$elm$json$Json$Decode$map,
		function (ms) {
			return $elm$time$Time$millisToPosix(ms);
		},
		A2($elm$json$Json$Decode$field, 'created_at', $elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'created_at', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'created_by', $author$project$Domain$Author$decoder),
	A2(
		$elm$json$Json$Decode$map,
		function (ms) {
			return $elm$time$Time$millisToPosix(ms);
		},
		A2($elm$json$Json$Decode$field, 'last_modified_at', $elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'last_modified_at', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'last_modified_by', $author$project$Domain$Author$decoder));
var $author$project$Domain$Element$Version = F5(
	function (label, id, type_, attrs, permissions) {
		return {eW: attrs, a$: id, g_: label, h3: permissions, jq: type_};
	});
var $author$project$Domain$Permissions$Permissions = F2(
	function (teams, users) {
		return {jc: teams, jv: users};
	});
var $author$project$Domain$Permissions$decoderIdName = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (id, name) {
			return {a$: id, dL: name};
		}),
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$Domain$Permissions$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Domain$Permissions$Permissions,
	A2(
		$elm$json$Json$Decode$field,
		'teams',
		$elm$json$Json$Decode$list($author$project$Domain$Permissions$decoderIdName)),
	A2(
		$elm$json$Json$Decode$field,
		'users',
		$elm$json$Json$Decode$list($author$project$Domain$Permissions$decoderIdName)));
var $author$project$Domain$Element$Excel = $elm$core$Basics$identity;
var $author$project$Domain$ExcelElement$ExcelElement = F6(
	function (pngUrl, csvUrl, csvId, fileName, sheetUrl, comment) {
		return {fh: comment, ft: csvId, fu: csvUrl, gp: fileName, h5: pngUrl, iN: sheetUrl};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Domain$ExcelElement$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'comment',
	$elm$json$Json$Decode$string,
	'',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'json_url',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'file_name',
			$elm$json$Json$Decode$string,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'csv_url',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'png_url',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Domain$ExcelElement$ExcelElement)))))));
var $author$project$Domain$Element$decoderExcel = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Domain$ExcelElement$decoder);
var $author$project$Domain$Element$decoderType = $author$project$Domain$Element$decoderExcel;
var $author$project$Domain$Attributes$default = {gb: '', dL: '', ib: ''};
var $author$project$Domain$Element$decoderVersion = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'permissions',
	$author$project$Domain$Permissions$decoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'attrs',
		$author$project$Domain$Attributes$decoder,
		$author$project$Domain$Attributes$default,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'content',
			$author$project$Domain$Element$decoderType,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'label',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Domain$Element$Version))))));
var $author$project$Domain$ElementForLibrary$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lifecycle',
	$author$project$Domain$Lifecycle$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'attrs',
		$author$project$Domain$Attributes$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'versions',
				$elm$json$Json$Decode$list($author$project$Domain$Element$decoderVersion),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'current_version',
					$author$project$Domain$Element$decoderVersion,
					$elm$json$Json$Decode$succeed($author$project$Domain$ElementForLibrary$ElementForLibrary))))));
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Api$listElements = F3(
	function (token, elementType, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'elements',
							$elm$json$Json$Decode$list($author$project$Domain$ElementForLibrary$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'ListUserElements']),
					function () {
						if (elementType.$ === 1) {
							return _List_Nil;
						} else {
							var type_ = elementType.a;
							return _List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'type', type_)
								]);
						}
					}())
			});
	});
var $author$project$Page$Library$init = F4(
	function (key, user, cart, maybeSlidesEditorRoute) {
		return _Utils_Tuple2(
			{
				aa: $elm$core$Maybe$Nothing,
				fb: cart,
				$7: false,
				dp: false,
				ah: $krisajenkins$remotedata$RemoteData$Loading,
				k: key,
				cK: maybeSlidesEditorRoute,
				ap: $elm$core$Maybe$Nothing,
				a8: $author$project$Ui$SearchAttrsBar$init,
				cm: $elm$random$Random$initialSeed(0),
				y: $elm$core$Maybe$Nothing,
				a9: 0,
				jt: user
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Api$listElements, user.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse),
						$author$project$Utils$getCurrentTime($author$project$Page$Library$GotCurrentTime)
					])));
	});
var $author$project$Page$Login$LoginScreen = 0;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Page$Login$init = function (askedRoute) {
	return _Utils_Tuple2(
		{bR: askedRoute, aw: 0, dr: '', bz: false, dR: '', ao: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Register$EnteringEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Register$Step1 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Register$init = function () {
	var data = {
		dr: $author$project$Page$Register$EnteringEmail(''),
		gq: '',
		g$: '',
		dR: '',
		Y: '',
		an: false,
		ba: false
	};
	return _Utils_Tuple2(
		$author$project$Page$Register$Step1(data),
		$elm$core$Platform$Cmd$none);
}();
var $author$project$Page$ResetPassword$init = F2(
	function (key, token) {
		return _Utils_Tuple2(
			{k: key, dR: '', jo: token},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Team$GotCurrentTime = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Team$GotTeamsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Domain$Team$SubTeams = $elm$core$Basics$identity;
var $author$project$Domain$Team$Team = F6(
	function (id, name, lifecycle, users, totalUsers, subteams) {
		return {a$: id, g2: lifecycle, dL: name, cX: subteams, jp: totalUsers, jv: users};
	});
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
function $author$project$Domain$Team$cyclic$decoder() {
	return A7(
		$elm$json$Json$Decode$map6,
		$author$project$Domain$Team$Team,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'lifecycle', $author$project$Domain$Lifecycle$decoder),
		A2(
			$elm$json$Json$Decode$field,
			'users',
			$elm$json$Json$Decode$list($author$project$Domain$User$decoder)),
		A2($elm$json$Json$Decode$field, 'total_users', $elm$json$Json$Decode$int),
		A2(
			$elm$json$Json$Decode$field,
			'teams',
			$author$project$Domain$Team$cyclic$decoderSubTeams()));
}
function $author$project$Domain$Team$cyclic$decoderSubTeams() {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$identity,
		$elm$json$Json$Decode$list(
			$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $author$project$Domain$Team$cyclic$decoder();
				})));
}
var $author$project$Domain$Team$decoder = $author$project$Domain$Team$cyclic$decoder();
$author$project$Domain$Team$cyclic$decoder = function () {
	return $author$project$Domain$Team$decoder;
};
var $author$project$Domain$Team$decoderSubTeams = $author$project$Domain$Team$cyclic$decoderSubTeams();
$author$project$Domain$Team$cyclic$decoderSubTeams = function () {
	return $author$project$Domain$Team$decoderSubTeams;
};
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Api$getTeams = F3(
	function (token, shouldReturnSystemTeams, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					toMsg,
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'teams',
							$elm$json$Json$Decode$list($author$project$Domain$Team$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'ListTeams']),
					_List_fromArray(
						[
							A2(
							$elm$url$Url$Builder$int,
							'return_system_teams',
							shouldReturnSystemTeams ? 1 : 0)
						]))
			});
	});
var $author$project$Page$Team$init = function (user) {
	return _Utils_Tuple2(
		{
			ae: $elm$core$Maybe$Nothing,
			ce: '',
			cm: $elm$random$Random$initialSeed(0),
			cp: $billstclair$elm_sortable_table$Table$initialSort('Creation date'),
			jc: _List_Nil,
			cq: $elm$time$Time$millisToPosix(0),
			jt: user,
			bi: $elm$core$Maybe$Nothing
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Utils$getCurrentTime($author$project$Page$Team$GotCurrentTime),
					A3($author$project$Api$getTeams, user.jo, true, $author$project$Page$Team$GotTeamsResponse)
				])));
};
var $author$project$Page$Test$NoOp = 0;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Page$Test$init = F2(
	function (key, user) {
		return _Utils_Tuple2(
			{k: key, jt: user},
			A2(
				$elm$core$Task$perform,
				$elm$core$Basics$always(0),
				$elm$core$Process$sleep(1000)));
	});
var $author$project$Ui$Layout$Main$GotCurrentTime = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ui$UserMenu$init = {bN: false};
var $author$project$Ui$Layout$Main$init = F4(
	function (key, route, assets, user) {
		return _Utils_Tuple2(
			{
				v: assets,
				k: key,
				bE: route,
				cm: $elm$random$Random$initialSeed(0),
				jt: user,
				bM: $author$project$Ui$UserMenu$init
			},
			$author$project$Utils$getCurrentTime($author$project$Ui$Layout$Main$GotCurrentTime));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$logout = _Platform_outgoingPort(
	'logout',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {az: frag, aF: params, as: unvisited, jz: value, aL: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.as;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.jz);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.jz);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.dS),
					$elm$url$Url$Parser$prepareQuery(url.ce),
					url.du,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Admin = {$: 5};
var $author$project$Route$ConfirmRegistration = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Enterprise = {$: 10};
var $author$project$Route$Logout = {$: 2};
var $author$project$Route$Register = {$: 4};
var $author$project$Route$ResetPassword = function (a) {
	return {$: 6, a: a};
};
var $author$project$Route$Root = {$: 0};
var $author$project$Route$Team = {$: 9};
var $author$project$Route$Test = {$: 8};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$int = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$String$toInt(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aL;
		var unvisited = _v0.as;
		var params = _v0.aF;
		var frag = _v0.az;
		var value = _v0.jz;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aL;
			var unvisited = _v1.as;
			var params = _v1.aF;
			var frag = _v1.az;
			var value = _v1.jz;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aL;
		var unvisited = _v1.as;
		var params = _v1.aF;
		var frag = _v1.az;
		var value = _v1.jz;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aL;
		var unvisited = _v0.as;
		var params = _v0.aF;
		var frag = _v0.az;
		var value = _v0.jz;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aL;
			var unvisited = _v0.as;
			var params = _v0.aF;
			var frag = _v0.az;
			var value = _v0.jz;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Root, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Logout,
			$elm$url$Url$Parser$s('logout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Library,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('library'),
					$elm$url$Url$Parser$Query$string('returnTo')),
				$elm$url$Url$Parser$Query$int('slide'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Register,
			$elm$url$Url$Parser$s('register')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Admin,
			$elm$url$Url$Parser$s('admin')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Test,
			$elm$url$Url$Parser$s('test')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Team,
			$elm$url$Url$Parser$s('team')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Enterprise,
			$elm$url$Url$Parser$s('enterprise')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ResetPassword,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('resetpwd'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ConfirmRegistration,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('confirm'),
				$elm$url$Url$Parser$string))
		]));
var $author$project$Route$parse = $elm$url$Url$Parser$parse($author$project$Route$parser);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Route$toString = function (route) {
	switch (route.$) {
		case 0:
			return A2($elm$url$Url$Builder$absolute, _List_Nil, _List_Nil);
		case 1:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['login']),
				_List_Nil);
		case 2:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['logout']),
				_List_Nil);
		case 3:
			var maybeDocumentId = route.a;
			var maybeSlideIndex = route.b;
			var parameters = function () {
				var _v1 = _Utils_Tuple2(maybeDocumentId, maybeSlideIndex);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var docId = _v1.a.a;
					var slideIndex = _v1.b.a;
					return _List_fromArray(
						[
							A2($elm$url$Url$Builder$string, 'returnTo', docId),
							A2($elm$url$Url$Builder$int, 'slide', slideIndex)
						]);
				} else {
					return _List_Nil;
				}
			}();
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['library']),
				parameters);
		case 4:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['register']),
				_List_Nil);
		case 5:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['admin']),
				_List_Nil);
		case 9:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['team']),
				_List_Nil);
		case 8:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['test']),
				_List_Nil);
		case 10:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['enterprise']),
				_List_Nil);
		case 6:
			var token = route.a;
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['resetpwd', token]),
				_List_Nil);
		default:
			var token = route.a;
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['confirm', token]),
				_List_Nil);
	}
};
var $author$project$Route$push = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$toString(route));
	});
var $author$project$Main$updateUrl = F2(
	function (url, model) {
		var _v0 = _Utils_Tuple2(
			$author$project$Route$parse(url),
			model.jt);
		_v0$3:
		while (true) {
			if (_v0.a.$ === 1) {
				var _v1 = _v0.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: $author$project$Main$Unlogged, d: $author$project$Main$NotFound}),
					$elm$core$Platform$Cmd$none);
			} else {
				if (!_v0.b.$) {
					switch (_v0.a.a.$) {
						case 0:
							var _v2 = _v0.a.a;
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$push,
									model.k,
									A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
						case 2:
							break _v0$3;
						case 3:
							var route = _v0.a.a;
							var user = _v0.b.a;
							var _v6 = A4($author$project$Page$Library$init, model.k, user, model.fb, $elm$core$Maybe$Nothing);
							var pageModel = _v6.a;
							var subCmd = _v6.b;
							var _v7 = function () {
								var _v8 = model.o;
								if (_v8.$ === 1) {
									var l = _v8.a;
									return _Utils_Tuple2(
										_Utils_update(
											l,
											{bE: route}),
										$elm$core$Platform$Cmd$none);
								} else {
									return A4($author$project$Ui$Layout$Main$init, model.k, route, model.v, user);
								}
							}();
							var layoutModel = _v7.a;
							var layoutCmd = _v7.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Main(layoutModel),
										d: A2(
											$author$project$Main$LoggedIn,
											user,
											$author$project$Main$LibraryPage(pageModel))
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLibraryPageMsg, subCmd),
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, layoutCmd)
										])));
						case 5:
							var route = _v0.a.a;
							var user = _v0.b.a;
							var _v9 = A2($author$project$Page$Admin$init, model.k, user);
							var pageModel = _v9.a;
							var subCmd = _v9.b;
							var _v10 = function () {
								var _v11 = model.o;
								if (_v11.$ === 1) {
									var l = _v11.a;
									return _Utils_Tuple2(
										_Utils_update(
											l,
											{bE: route}),
										$elm$core$Platform$Cmd$none);
								} else {
									return A4($author$project$Ui$Layout$Main$init, model.k, route, model.v, user);
								}
							}();
							var layoutModel = _v10.a;
							var layoutCmd = _v10.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Main(layoutModel),
										d: A2(
											$author$project$Main$LoggedIn,
											user,
											$author$project$Main$AdminPage(pageModel))
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotAdminPageMsg, subCmd),
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, layoutCmd)
										])));
						case 8:
							var route = _v0.a.a;
							var user = _v0.b.a;
							var _v12 = A2($author$project$Page$Test$init, model.k, user);
							var pageModel = _v12.a;
							var subCmd = _v12.b;
							var _v13 = function () {
								var _v14 = model.o;
								if (_v14.$ === 1) {
									var l = _v14.a;
									return _Utils_Tuple2(
										_Utils_update(
											l,
											{bE: route}),
										$elm$core$Platform$Cmd$none);
								} else {
									return A4($author$project$Ui$Layout$Main$init, model.k, route, model.v, user);
								}
							}();
							var layoutModel = _v13.a;
							var layoutCmd = _v13.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Main(layoutModel),
										d: A2(
											$author$project$Main$LoggedIn,
											user,
											$author$project$Main$TestPage(pageModel))
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTestPageMsg, subCmd),
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, layoutCmd)
										])));
						case 9:
							var route = _v0.a.a;
							var user = _v0.b.a;
							var _v15 = $author$project$Page$Team$init(user);
							var pageModel = _v15.a;
							var subCmd = _v15.b;
							var _v16 = function () {
								var _v17 = model.o;
								if (_v17.$ === 1) {
									var l = _v17.a;
									return _Utils_Tuple2(
										_Utils_update(
											l,
											{bE: route}),
										$elm$core$Platform$Cmd$none);
								} else {
									return A4($author$project$Ui$Layout$Main$init, model.k, route, model.v, user);
								}
							}();
							var layoutModel = _v16.a;
							var layoutCmd = _v16.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Main(layoutModel),
										d: A2(
											$author$project$Main$LoggedIn,
											user,
											$author$project$Main$TeamPage(pageModel))
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTeamPageMsg, subCmd),
											A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, layoutCmd)
										])));
						case 10:
							var route = _v0.a.a;
							var user = _v0.b.a;
							var _v18 = user.f5;
							if (_v18.$ === 1) {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							} else {
								var enterprise = _v18.a;
								var _v19 = A2($author$project$Page$Enterprise$init, user, enterprise);
								var pageModel = _v19.a;
								var subCmd = _v19.b;
								var _v20 = function () {
									var _v21 = model.o;
									if (_v21.$ === 1) {
										var l = _v21.a;
										return _Utils_Tuple2(
											_Utils_update(
												l,
												{bE: route}),
											$elm$core$Platform$Cmd$none);
									} else {
										return A4($author$project$Ui$Layout$Main$init, model.k, route, model.v, user);
									}
								}();
								var layoutModel = _v20.a;
								var layoutCmd = _v20.b;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											o: $author$project$Main$Main(layoutModel),
											d: A2(
												$author$project$Main$LoggedIn,
												user,
												$author$project$Main$EnterprisePage(pageModel))
										}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2($elm$core$Platform$Cmd$map, $author$project$Main$GotEnterprisePageMsg, subCmd),
												A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, layoutCmd)
											])));
							}
						case 4:
							var _v28 = _v0.a.a;
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$push,
									model.k,
									A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
						case 6:
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$push,
									model.k,
									A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
						case 7:
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$push,
									model.k,
									A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
						default:
							var _v33 = _v0.a.a;
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$push,
									model.k,
									A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
					}
				} else {
					switch (_v0.a.a.$) {
						case 0:
							var _v3 = _v0.a.a;
							var _v4 = _v0.b;
							return _Utils_Tuple2(
								model,
								A2($author$project$Route$push, model.k, $author$project$Route$Login));
						case 2:
							break _v0$3;
						case 1:
							var _v22 = _v0.a.a;
							var _v23 = _v0.b;
							var _v24 = $author$project$Page$Login$init(
								A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
							var pageModel = _v24.a;
							var subCmd = _v24.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Unlogged,
										d: $author$project$Main$LoginPage(pageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLoginPageMsg, subCmd));
						case 4:
							var _v25 = _v0.a.a;
							var _v26 = _v0.b;
							var _v27 = $author$project$Page$Register$init;
							var pageModel = _v27.a;
							var subCmd = _v27.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Unlogged,
										d: $author$project$Main$RegisterPage(pageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotRegisterPageMsg, subCmd));
						case 6:
							var token = _v0.a.a.a;
							var _v29 = _v0.b;
							var _v30 = A2($author$project$Page$ResetPassword$init, model.k, token);
							var pageModel = _v30.a;
							var subCmd = _v30.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Unlogged,
										d: $author$project$Main$ResetPasswordPage(pageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotResetPasswordPageMsg, subCmd));
						case 7:
							var token = _v0.a.a.a;
							var _v31 = _v0.b;
							var _v32 = $author$project$Page$Confirm$init(token);
							var pageModel = _v32.a;
							var subCmd = _v32.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										o: $author$project$Main$Unlogged,
										d: $author$project$Main$ConfirmRegistrationPage(pageModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotConfirmRegistrationPageMsg, subCmd));
						default:
							var _v34 = _v0.b;
							return _Utils_Tuple2(
								model,
								A2($author$project$Route$push, model.k, $author$project$Route$Login));
					}
				}
			}
		}
		var _v5 = _v0.a.a;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{jt: $elm$core$Maybe$Nothing}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Ports$logout(0),
						A2($author$project$Route$push, model.k, $author$project$Route$Login)
					])));
	});
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var maybeUser = $author$project$LoggedIn$decodeStorage(flags.jt);
		var initialModel = {
			v: flags.v,
			fb: $author$project$Domain$Cart$empty,
			k: key,
			o: $author$project$Main$Unlogged,
			w: A2(
				$elm$core$String$contains,
				'fr',
				$elm$core$String$toLower(flags.w)) ? $author$project$Lang$Fr$mainLocale : $author$project$Lang$En$mainLocale,
			d: $author$project$Main$NotFound,
			jt: maybeUser
		};
		if (maybeUser.$ === 1) {
			return A2($author$project$Main$updateUrl, url, initialModel);
		} else {
			var user = maybeUser.a;
			return _Utils_Tuple2(
				initialModel,
				A2(
					$author$project$Api$openUser,
					user.jo,
					$author$project$Main$GotOpenUserResponse(url)));
		}
	});
var $author$project$Main$UserLoggedOutOnAnotherTab = {$: 2};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$Admin$GotEnterpriseAdminMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Admin$GotLogAdminMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$EnterpriseAdmin$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$LogAdmin$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$UserAdmin$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$Admin$subscriptions = function (model) {
	var _v0 = model.d;
	switch (_v0.$) {
		case 0:
			var m = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Page$Admin$GotUserAdminMsg,
				$author$project$Page$UserAdmin$subscriptions(m));
		case 2:
			var m = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Page$Admin$GotEnterpriseAdminMsg,
				$author$project$Page$EnterpriseAdmin$subscriptions(m));
		default:
			var m = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Page$Admin$GotLogAdminMsg,
				$author$project$Page$LogAdmin$subscriptions(m));
	}
};
var $author$project$Page$Library$GotAttributesFormMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Library$GotPermissionsFormMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Library$SearchBarChangeState = function (a) {
	return {$: 17, a: a};
};
var $author$project$Ui$AttributesForm$OnEntityComboState = function (a) {
	return {$: 8, a: a};
};
var $author$project$Ui$AttributesForm$OnNameComboState = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ui$AttributesForm$OnPurposeComboState = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ui$Combo$State = $elm$core$Basics$identity;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {dT: pids, eh: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {gg: event, k: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.dT,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.k;
		var event = _v0.gg;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.eh);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $author$project$Ui$Combo$subscriptions = F2(
	function (_v0, toMsg) {
		var state = _v0;
		return state.cU ? $elm$browser$Browser$Events$onClick(
			$elm$json$Json$Decode$succeed(
				toMsg(
					_Utils_update(
						state,
						{cU: false})))) : $elm$core$Platform$Sub$none;
	});
var $author$project$Ui$AttributesForm$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$Ui$Combo$subscriptions, model.bX, $author$project$Ui$AttributesForm$OnNameComboState),
				A2($author$project$Ui$Combo$subscriptions, model.bY, $author$project$Ui$AttributesForm$OnPurposeComboState),
				A2($author$project$Ui$Combo$subscriptions, model.bW, $author$project$Ui$AttributesForm$OnEntityComboState)
			]));
};
var $author$project$Ui$PermissionsForm$GotMultiselectTeamsMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$PermissionsForm$GotMultiselectUsersMsg = function (a) {
	return {$: 3, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Click = {$: 1};
var $inkuzmin$elm_multiselect$Multiselect$Opened = 2;
var $inkuzmin$elm_multiselect$Multiselect$subscriptions = function (_v0) {
	var model = _v0;
	return (model.m === 2) ? $elm$browser$Browser$Events$onClick(
		$elm$json$Json$Decode$succeed($inkuzmin$elm_multiselect$Multiselect$Click)) : $elm$core$Platform$Sub$none;
};
var $author$project$Ui$PermissionsForm$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Ui$PermissionsForm$GotMultiselectTeamsMsg,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.O)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Ui$PermissionsForm$GotMultiselectUsersMsg,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.P))
			]));
};
var $author$project$Ui$Dropdown$subscriptions = F2(
	function (_v0, toMsg) {
		var state = _v0;
		return state.aS ? $elm$browser$Browser$Events$onClick(
			$elm$json$Json$Decode$succeed(
				toMsg(
					_Utils_update(
						state,
						{aS: false})))) : $elm$core$Platform$Sub$none;
	});
var $author$project$Ui$SearchAttrsBar$subscriptions = F2(
	function (_v0, toMsg) {
		var state = _v0;
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					A2(
					$author$project$Ui$Dropdown$subscriptions,
					state.bq,
					function (dropdownState) {
						return toMsg(
							_Utils_update(
								state,
								{bq: dropdownState}));
					}),
					A2(
					$author$project$Ui$Dropdown$subscriptions,
					state.br,
					function (dropdownState) {
						return toMsg(
							_Utils_update(
								state,
								{br: dropdownState}));
					}),
					A2(
					$author$project$Ui$Dropdown$subscriptions,
					state.cx,
					function (dropdownState) {
						return toMsg(
							_Utils_update(
								state,
								{cx: dropdownState}));
					})
				]));
	});
var $author$project$Page$Library$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.ap;
				if (_v0.$ === 1) {
					return $elm$core$Platform$Sub$none;
				} else {
					var m = _v0.a;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Page$Library$GotPermissionsFormMsg,
						$author$project$Ui$PermissionsForm$subscriptions(m));
				}
			}(),
				function () {
				var _v1 = model.aa;
				if (_v1.$ === 1) {
					return $elm$core$Platform$Sub$none;
				} else {
					var m = _v1.a;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Page$Library$GotAttributesFormMsg,
						$author$project$Ui$AttributesForm$subscriptions(m));
				}
			}(),
				A2($author$project$Ui$SearchAttrsBar$subscriptions, model.a8, $author$project$Page$Library$SearchBarChangeState)
			]));
};
var $author$project$Page$Team$Tick = function (a) {
	return {$: 10, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {dX: processes, ei: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.dX;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.ei);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Page$Team$subscriptions = function (_v0) {
	return A2($elm$time$Time$every, 1000, $author$project$Page$Team$Tick);
};
var $author$project$Page$Test$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Ports$userLoggedOutOnAnotherTab = _Platform_incomingPort(
	'userLoggedOutOnAnotherTab',
	$elm$json$Json$Decode$null(0));
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Ports$userLoggedOutOnAnotherTab(
				function (_v0) {
					return $author$project$Main$UserLoggedOutOnAnotherTab;
				}),
				function () {
				var _v1 = model.d;
				_v1$4:
				while (true) {
					if (_v1.$ === 5) {
						switch (_v1.b.$) {
							case 1:
								var pageModel = _v1.b.a;
								return A2(
									$elm$core$Platform$Sub$map,
									$author$project$Main$GotAdminPageMsg,
									$author$project$Page$Admin$subscriptions(pageModel));
							case 2:
								var pageModel = _v1.b.a;
								return A2(
									$elm$core$Platform$Sub$map,
									$author$project$Main$GotTestPageMsg,
									$author$project$Page$Test$subscriptions(pageModel));
							case 3:
								var pageModel = _v1.b.a;
								return A2(
									$elm$core$Platform$Sub$map,
									$author$project$Main$GotTeamPageMsg,
									$author$project$Page$Team$subscriptions(pageModel));
							case 0:
								var pageModel = _v1.b.a;
								return A2(
									$elm$core$Platform$Sub$map,
									$author$project$Main$GotLibraryPageMsg,
									$author$project$Page$Library$subscriptions(pageModel));
							default:
								break _v1$4;
						}
					} else {
						break _v1$4;
					}
				}
				return $elm$core$Platform$Sub$none;
			}()
			]));
};
var $author$project$LoggedIn$encodeToken = function (token) {
	var tk = token;
	return tk;
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$LoggedIn$encodeForLocalStorage = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.dr)),
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(
					$author$project$LoggedIn$encodeToken(user.jo))),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(user.a$))
			]));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Ports$storeUser = _Platform_outgoingPort('storeUser', $elm$json$Json$Encode$string);
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.dY;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.du,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.ce,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.dU,
					_Utils_ap(http, url.dy)),
				url.dS)));
};
var $author$project$Page$Admin$Enterprise = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Admin$Log = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$EnterpriseAdmin$GotEnterprisesResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$EnterpriseAdmin$GotUsersResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$adminListEnterprises = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'enterprises',
							$elm$json$Json$Decode$list($author$project$Domain$Enterprise$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'AdminListEnterprises']),
					_List_Nil)
			});
	});
var $billstclair$elm_sortable_table$Table$sortBy = $billstclair$elm_sortable_table$Table$State;
var $author$project$Page$EnterpriseAdmin$init = function (user) {
	return _Utils_Tuple2(
		{
			ad: $elm$core$Maybe$Nothing,
			aU: $krisajenkins$remotedata$RemoteData$Loading,
			cm: $elm$random$Random$initialSeed(0),
			cp: A2($billstclair$elm_sortable_table$Table$sortBy, 'Date', true),
			jo: user.jo,
			jv: $krisajenkins$remotedata$RemoteData$Loading
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($author$project$Api$adminListEnterprises, user.jo, $author$project$Page$EnterpriseAdmin$GotEnterprisesResponse),
					A2($author$project$Api$adminListUsers, user.jo, $author$project$Page$EnterpriseAdmin$GotUsersResponse)
				])));
};
var $author$project$Page$LogAdmin$GotLogsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$LogAdmin$GotUsersResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Domain$Log$Log = F3(
	function (userId, event, insertedAt) {
		return {gg: event, gQ: insertedAt, ju: userId};
	});
var $author$project$Domain$Log$decoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Domain$Log$Log,
	A2($elm$json$Json$Decode$field, 'user_id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		function (ms) {
			return $elm$time$Time$millisToPosix(ms);
		},
		A2($elm$json$Json$Decode$field, 'inserted_at', $elm$json$Json$Decode$int)));
var $author$project$Api$adminLog = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'log',
							$elm$json$Json$Decode$list($author$project$Domain$Log$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'AdminLog']),
					_List_Nil)
			});
	});
var $author$project$Page$LogAdmin$init = function (user) {
	return _Utils_Tuple2(
		{
			b1: $elm$core$Maybe$Nothing,
			b7: $krisajenkins$remotedata$RemoteData$Loading,
			cp: A2($billstclair$elm_sortable_table$Table$sortBy, 'Date', true),
			jv: $krisajenkins$remotedata$RemoteData$Loading
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($author$project$Api$adminLog, user.jo, $author$project$Page$LogAdmin$GotLogsResponse),
					A2($author$project$Api$adminListUsers, user.jo, $author$project$Page$LogAdmin$GotUsersResponse)
				])));
};
var $author$project$Page$EnterpriseAdmin$GotCreateEnterpriseApiResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$EnterpriseAdmin$GotCreateEnterpriseMsg = function (a) {
	return {$: 4, a: a};
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Api$decoderSuccess = A2(
	$elm$json$Json$Decode$andThen,
	function (success) {
		return success ? $elm$json$Json$Decode$succeed(true) : $elm$json$Json$Decode$fail('backend failure');
	},
	A2($elm$json$Json$Decode$field, 'success', $elm$json$Json$Decode$bool));
var $author$project$Api$encoderCommand = F3(
	function (action, randomId, payload) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'action',
					$elm$json$Json$Encode$string(action)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(randomId)),
					_Utils_Tuple2('payload', payload)
				]));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Api$post = F2(
	function (token, _v0) {
		var url = _v0.h;
		var body = _v0.D;
		var expect = _v0.j;
		return A2(
			$author$project$Api$request,
			token,
			{D: body, j: expect, aA: _List_Nil, aE: 'POST', aK: $elm$core$Maybe$Nothing, bg: $elm$core$Maybe$Nothing, h: url});
	});
var $author$project$Api$command = F5(
	function (action, randomId, payload, token, toMsg) {
		return A2(
			$author$project$Api$post,
			token,
			{
				D: $elm$http$Http$jsonBody(
					A3($author$project$Api$encoderCommand, action, randomId, payload)),
				j: A2($elm$http$Http$expectJson, toMsg, $author$project$Api$decoderSuccess),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['commands']),
					_List_Nil)
			});
	});
var $author$project$Domain$Enterprise$encoderForCreateEnterprise = function (enterprise) {
	var _v0 = enterprise.dJ;
	if (_v0.b && (!_v0.b.b)) {
		var manager = _v0.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(enterprise.a$)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(enterprise.dL)),
					_Utils_Tuple2(
					'manager_id',
					$elm$json$Json$Encode$string(manager.a$)),
					_Utils_Tuple2(
					'enterprise_id',
					$elm$json$Json$Encode$string(enterprise.a$))
				]));
	} else {
		return $elm$json$Json$Encode$object(_List_Nil);
	}
};
var $author$project$Api$createEnterprise = F4(
	function (token, randomId, enterprise, toMsg) {
		return A5(
			$author$project$Api$command,
			'CreateEnterprise',
			randomId,
			$author$project$Domain$Enterprise$encoderForCreateEnterprise(enterprise),
			token,
			toMsg);
	});
var $TSFoster$elm_uuid$UUID$UUID = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$map4 = F5(
	function (func, _v0, _v1, _v2, _v3) {
		var genA = _v0;
		var genB = _v1;
		var genC = _v2;
		var genD = _v3;
		return function (seed0) {
			var _v4 = genA(seed0);
			var a = _v4.a;
			var seed1 = _v4.b;
			var _v5 = genB(seed1);
			var b = _v5.a;
			var seed2 = _v5.b;
			var _v6 = genC(seed2);
			var c = _v6.a;
			var seed3 = _v6.b;
			var _v7 = genD(seed3);
			var d = _v7.a;
			var seed4 = _v7.b;
			return _Utils_Tuple2(
				A4(func, a, b, c, d),
				seed4);
		};
	});
var $TSFoster$elm_uuid$UUID$forceUnsigned = $elm$core$Bitwise$shiftRightZfBy(0);
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $TSFoster$elm_uuid$UUID$randomU32 = A2(
	$elm$random$Random$map,
	$TSFoster$elm_uuid$UUID$forceUnsigned,
	A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt));
var $elm$core$Bitwise$or = _Bitwise_or;
var $TSFoster$elm_uuid$UUID$toVariant1 = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var d = _v0.d;
	return A4(
		$TSFoster$elm_uuid$UUID$UUID,
		a,
		b,
		$TSFoster$elm_uuid$UUID$forceUnsigned(2147483648 | (1073741823 & c)),
		d);
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $TSFoster$elm_uuid$UUID$toVersion = F2(
	function (v, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return A4(
			$TSFoster$elm_uuid$UUID$UUID,
			a,
			$TSFoster$elm_uuid$UUID$forceUnsigned((v << 12) | (4294905855 & b)),
			c,
			d);
	});
var $TSFoster$elm_uuid$UUID$generator = A2(
	$elm$random$Random$map,
	A2(
		$elm$core$Basics$composeR,
		$TSFoster$elm_uuid$UUID$toVersion(4),
		$TSFoster$elm_uuid$UUID$toVariant1),
	A5($elm$random$Random$map4, $TSFoster$elm_uuid$UUID$UUID, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32));
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$String$fromList = _String_fromList;
var $TSFoster$elm_uuid$UUID$toHex = F2(
	function (acc, _int) {
		toHex:
		while (true) {
			if (!_int) {
				return $elm$core$String$fromList(acc);
			} else {
				var _char = function () {
					var _v0 = 15 & _int;
					switch (_v0) {
						case 0:
							return '0';
						case 1:
							return '1';
						case 2:
							return '2';
						case 3:
							return '3';
						case 4:
							return '4';
						case 5:
							return '5';
						case 6:
							return '6';
						case 7:
							return '7';
						case 8:
							return '8';
						case 9:
							return '9';
						case 10:
							return 'a';
						case 11:
							return 'b';
						case 12:
							return 'c';
						case 13:
							return 'd';
						case 14:
							return 'e';
						default:
							return 'f';
					}
				}();
				var $temp$acc = A2($elm$core$List$cons, _char, acc),
					$temp$int = _int >>> 4;
				acc = $temp$acc;
				_int = $temp$int;
				continue toHex;
			}
		}
	});
var $TSFoster$elm_uuid$UUID$toStringWith = F2(
	function (sep, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return _Utils_ap(
			A3(
				$elm$core$String$padLeft,
				8,
				'0',
				A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, a)),
			_Utils_ap(
				sep,
				_Utils_ap(
					A3(
						$elm$core$String$padLeft,
						4,
						'0',
						A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, b >>> 16)),
					_Utils_ap(
						sep,
						_Utils_ap(
							A3(
								$elm$core$String$padLeft,
								4,
								'0',
								A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & b)),
							_Utils_ap(
								sep,
								_Utils_ap(
									A3(
										$elm$core$String$padLeft,
										4,
										'0',
										A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, c >>> 16)),
									_Utils_ap(
										sep,
										_Utils_ap(
											A3(
												$elm$core$String$padLeft,
												4,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & c)),
											A3(
												$elm$core$String$padLeft,
												8,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, d)))))))))));
	});
var $TSFoster$elm_uuid$UUID$toString = $TSFoster$elm_uuid$UUID$toStringWith('-');
var $author$project$RandomId$generateStringUuid = function (seed) {
	var _v0 = A2($elm$random$Random$step, $TSFoster$elm_uuid$UUID$generator, seed);
	var uuid = _v0.a;
	var newSeed = _v0.b;
	return _Utils_Tuple2(
		$TSFoster$elm_uuid$UUID$toString(uuid),
		newSeed);
};
var $author$project$Ui$CreateEnterprise$init = function (users) {
	return _Utils_Tuple2(
		{
			fs: false,
			jd: {a$: '', dJ: _List_Nil, hm: _List_Nil, dL: ''},
			jv: users,
			jx: false
		},
		$elm$core$Platform$Cmd$none);
};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$Ui$CreateEnterprise$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fs: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jx: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var name = msg.a;
				var tempEnterprise = model.jd;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jd: _Utils_update(
								tempEnterprise,
								{dL: name})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var id = msg.a;
				var tempEnterprise = model.jd;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jd: _Utils_update(
								tempEnterprise,
								{
									dJ: _List_fromArray(
										[
											{a$: id, dL: ''}
										])
								})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$EnterpriseAdmin$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var _v1 = model.jv;
				if (_v1.$ === 3) {
					var users = _v1.a;
					var _v2 = $author$project$Ui$CreateEnterprise$init(users);
					var m = _v2.a;
					var c = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ad: $elm$core$Maybe$Just(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$EnterpriseAdmin$GotCreateEnterpriseMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var r = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jv: r}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var r = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aU: r}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var subMsg = msg.a;
				var _v3 = model.ad;
				if (_v3.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var subModel = _v3.a;
					var _v4 = A2($author$project$Ui$CreateEnterprise$update, subMsg, subModel);
					var m = _v4.a;
					var c = _v4.b;
					if (m.jx) {
						var newEnterprise = m.jd;
						var _v5 = $author$project$RandomId$generateStringUuid(model.cm);
						var randomId = _v5.a;
						var newSeed = _v5.b;
						var _v6 = $author$project$RandomId$generateStringUuid(newSeed);
						var enterpriseId = _v6.a;
						var newSeed2 = _v6.b;
						var newEnterprise2 = _Utils_update(
							newEnterprise,
							{a$: enterpriseId});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ad: $elm$core$Maybe$Nothing,
									aU: A2(
										$krisajenkins$remotedata$RemoteData$map,
										function (enterprises) {
											return _Utils_ap(
												enterprises,
												_List_fromArray(
													[newEnterprise2]));
										},
										model.aU),
									cm: newSeed2
								}),
							A4($author$project$Api$createEnterprise, model.jo, randomId, newEnterprise2, $author$project$Page$EnterpriseAdmin$GotCreateEnterpriseApiResponse));
					} else {
						if (m.fs) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ad: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ad: $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$EnterpriseAdmin$GotCreateEnterpriseMsg, c));
						}
					}
				}
			case 5:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$LogAdmin$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var r = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b7: r}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var r = msg.a;
				var usersToDict = function (users) {
					return $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (u) {
								return _Utils_Tuple2(u.a$, u);
							},
							users));
				};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jv: A2($krisajenkins$remotedata$RemoteData$map, usersToDict, r)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
			default:
				var userId = msg.a;
				var filter = function () {
					if (userId === '') {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(userId);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b1: filter}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$UserAdmin$GotCreateUserApiResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$UserAdmin$GotCreateUserMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$UserAdmin$GotRemoveUserResponse = function (a) {
	return {$: 7, a: a};
};
var $author$project$Domain$User$encoder = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstname',
				$elm$json$Json$Encode$string(user.cA)),
				_Utils_Tuple2(
				'lastname',
				$elm$json$Json$Encode$string(user.cI)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.dr)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(user.a$))
			]));
};
var $author$project$Api$createUser = F4(
	function (token, randomId, user, toMsg) {
		return A5(
			$author$project$Api$command,
			'CreateUser',
			randomId,
			$author$project$Domain$User$encoder(user),
			token,
			toMsg);
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Api$deleteUsers = F4(
	function (token, randomId, usersId, toMsg) {
		return A5(
			$author$project$Api$command,
			'DeleteUsers',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'users_ids',
						A2(
							$elm$json$Json$Encode$list,
							function (userId) {
								return $elm$json$Json$Encode$string(userId);
							},
							usersId))
					])),
			token,
			toMsg);
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Ui$CreateUser$init = _Utils_Tuple2(
	{
		fs: false,
		hB: {dr: '', cA: '', a$: '', cI: ''},
		jx: false
	},
	$elm$core$Platform$Cmd$none);
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Ui$CreateUser$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fs: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jx: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var fname = msg.a;
				var newUser = model.hB;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hB: _Utils_update(
								newUser,
								{cA: fname})
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var lname = msg.a;
				var newUser = model.hB;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hB: _Utils_update(
								newUser,
								{cI: lname})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var newEmail = msg.a;
				var newUser = model.hB;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hB: _Utils_update(
								newUser,
								{dr: newEmail})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$UserAdmin$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var r = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jv: r}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var newTableState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newTableState}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ce: newQuery}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v1 = $author$project$Ui$CreateUser$init;
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							af: $elm$core$Maybe$Just(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$UserAdmin$GotCreateUserMsg, c));
			case 4:
				var subMsg = msg.a;
				var _v2 = model.af;
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var subModel = _v2.a;
					var _v3 = A2($author$project$Ui$CreateUser$update, subMsg, subModel);
					var m = _v3.a;
					var c = _v3.b;
					if (m.jx) {
						var newUser = m.hB;
						var _v4 = $author$project$RandomId$generateStringUuid(model.cm);
						var randomId = _v4.a;
						var newSeed = _v4.b;
						var _v5 = $author$project$RandomId$generateStringUuid(newSeed);
						var userId = _v5.a;
						var newSeed2 = _v5.b;
						var newUser2 = _Utils_update(
							newUser,
							{a$: userId});
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									af: $elm$core$Maybe$Nothing,
									cm: newSeed2,
									jv: A2(
										$krisajenkins$remotedata$RemoteData$map,
										function (users) {
											return _Utils_ap(
												users,
												_List_fromArray(
													[newUser2]));
										},
										model.jv)
								}),
							A4($author$project$Api$createUser, model.jo, randomId, newUser2, $author$project$Page$UserAdmin$GotCreateUserApiResponse));
					} else {
						if (m.fs) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{af: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										af: $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$UserAdmin$GotCreateUserMsg, c));
						}
					}
				}
			case 5:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var user = msg.a;
				var _v6 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v6.a;
				var newSeed = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: newSeed,
							jv: A2(
								$krisajenkins$remotedata$RemoteData$map,
								$elm$core$List$filter(
									function (u) {
										return !_Utils_eq(u.a$, user.a$);
									}),
								model.jv)
						}),
					A4(
						$author$project$Api$deleteUsers,
						model.jo,
						randomId,
						_List_fromArray(
							[user.a$]),
						$author$project$Page$UserAdmin$GotRemoveUserResponse));
			default:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Admin$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var _v1 = $author$project$Page$UserAdmin$init(model.jt.jo);
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$Page$Admin$User(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotUserAdminMsg, c));
			case 2:
				var _v2 = $author$project$Page$EnterpriseAdmin$init(model.jt);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$Page$Admin$Enterprise(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotEnterpriseAdminMsg, c));
			case 1:
				var _v3 = $author$project$Page$LogAdmin$init(model.jt);
				var m = _v3.a;
				var c = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$Page$Admin$Log(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotLogAdminMsg, c));
			case 3:
				var subMsg = msg.a;
				var _v4 = model.d;
				if (!_v4.$) {
					var subModel = _v4.a;
					var _v5 = A2($author$project$Page$UserAdmin$update, subMsg, subModel);
					var m = _v5.a;
					var c = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Page$Admin$User(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotUserAdminMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				var _v6 = model.d;
				if (_v6.$ === 2) {
					var subModel = _v6.a;
					var _v7 = A2($author$project$Page$EnterpriseAdmin$update, subMsg, subModel);
					var m = _v7.a;
					var c = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Page$Admin$Enterprise(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotEnterpriseAdminMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var subMsg = msg.a;
				var _v8 = model.d;
				if (_v8.$ === 1) {
					var subModel = _v8.a;
					var _v9 = A2($author$project$Page$LogAdmin$update, subMsg, subModel);
					var m = _v9.a;
					var c = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Page$Admin$Log(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Admin$GotLogAdminMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Confirm$Failed = 2;
var $author$project$Page$Confirm$Success = 1;
var $author$project$Page$Confirm$update = F2(
	function (msg, model) {
		if (!msg.$) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{bG: 1}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{bG: 2}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Enterprise$GotInviteUserResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$inviteUserEnterprise = F5(
	function (token, randomId, email, enterprise, toMsg) {
		var encodedReq = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'enterprise_id',
					$elm$json$Json$Encode$string(enterprise.a$)),
					_Utils_Tuple2(
					'user_email',
					$elm$json$Json$Encode$string(email))
				]));
		return A5($author$project$Api$command, 'InviteUserEnterprise', randomId, encodedReq, token, toMsg);
	});
var $author$project$Page$Enterprise$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var r = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jv: r}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var r = msg.a;
				if (!r.$) {
					if (r.a) {
						return _Utils_Tuple2(
							model,
							A2($author$project$Api$getUsers2, model.jo, $author$project$Page$Enterprise$GotUsersResponse));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: state}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var _v2 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v2.a;
				var newSeed = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cm: newSeed}),
					A5($author$project$Api$inviteUserEnterprise, model.jo, randomId, model.b5, model.f5, $author$project$Page$Enterprise$GotInviteUserResponse));
			default:
				var value = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: value}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Ui$PermissionsForm$Element = $elm$core$Basics$identity;
var $author$project$Page$Library$GotDeleteElementVersionResponse = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Library$GotSaveAttributesResponse = function (a) {
	return {$: 15, a: a};
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Api$handleJsonResponse = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 3:
				var statusCode = response.a.iX;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(statusCode));
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v1.$ === 1) {
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(body));
				} else {
					var result = _v1.a;
					return $elm$core$Result$Ok(result);
				}
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{eN: false, D: r.D, j: r.ir, aA: r.aA, aE: r.aE, aK: r.aK, bg: $elm$core$Maybe$Nothing, h: r.h});
};
var $author$project$Api$commandAsTask = F4(
	function (action, randomId, payload, token) {
		return $elm$http$Http$task(
			{
				D: $elm$http$Http$jsonBody(
					A3($author$project$Api$encoderCommand, action, randomId, payload)),
				aA: _List_fromArray(
					[
						A2(
						$elm$http$Http$header,
						'Authorization',
						'Bearer ' + $author$project$LoggedIn$toString(token))
					]),
				aE: 'POST',
				ir: $elm$http$Http$stringResolver(
					$author$project$Api$handleJsonResponse($author$project$Api$decoderSuccess)),
				aK: $elm$core$Maybe$Nothing,
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['commands']),
					_List_Nil)
			});
	});
var $author$project$Api$changeElementAttributesAsTask = F4(
	function (randomId, elementId, attrs, token) {
		return A4(
			$author$project$Api$commandAsTask,
			'ChangeElementAttributes',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'element_id',
						$elm$json$Json$Encode$string(elementId)),
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(attrs.dL)),
						_Utils_Tuple2(
						'purpose',
						$elm$json$Json$Encode$string(attrs.ib)),
						_Utils_Tuple2(
						'entity',
						$elm$json$Json$Encode$string(attrs.gb))
					])),
			token);
	});
var $author$project$Api$changeElementAttributes = F5(
	function (randomId, elementId, attrs, token, toMsg) {
		return A2(
			$elm$core$Task$attempt,
			toMsg,
			A4($author$project$Api$changeElementAttributesAsTask, randomId, elementId, attrs, token));
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Api$deleteElementAsTask = F4(
	function (randomId, elementId, hideFromLibrary, token) {
		return A4(
			$author$project$Api$commandAsTask,
			'DeleteElement',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string(elementId)),
						_Utils_Tuple2(
						'hide_from_library',
						$elm$json$Json$Encode$bool(hideFromLibrary))
					])),
			token);
	});
var $author$project$Api$deleteElement = F5(
	function (randomId, elementId, hideFromLibrary, token, toMsg) {
		return A2(
			$elm$core$Task$attempt,
			toMsg,
			A4($author$project$Api$deleteElementAsTask, randomId, elementId, hideFromLibrary, token));
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Ui$AttributesForm$GotListAttributesResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$Combo$init = function (val) {
	return {iA: $elm$core$Maybe$Nothing, iD: -1, cU: false, jz: val};
};
var $author$project$Domain$Attributes$AvailableAttributes = F2(
	function (purposes, entities) {
		return {ga: entities, ic: purposes};
	});
var $author$project$Domain$Attributes$listAttributesDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Domain$Attributes$AvailableAttributes,
	A2(
		$elm$json$Json$Decode$field,
		'purposes',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'entities',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$Api$listAttributes = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
					A2($elm$json$Json$Decode$field, 'result', $author$project$Domain$Attributes$listAttributesDecoder)),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'ListAttributes']),
					_List_Nil)
			});
	});
var $author$project$Ui$AttributesForm$init = F2(
	function (user, _v0) {
		var defaultAttrs = _v0.dl;
		var parentAttrs = _v0.dQ;
		var displaySearchButton = _v0.dn;
		var searchTypeWanted = _v0.d5;
		return _Utils_Tuple2(
			{
				eW: defaultAttrs,
				bS: $krisajenkins$remotedata$RemoteData$Loading,
				bW: $author$project$Ui$Combo$init(''),
				bX: $author$project$Ui$Combo$init(''),
				bY: $author$project$Ui$Combo$init(''),
				dn: displaySearchButton,
				dQ: parentAttrs,
				d5: searchTypeWanted,
				M: $elm$core$Maybe$Nothing,
				jo: user.jo
			},
			A2($author$project$Api$listAttributes, user.jo, $author$project$Ui$AttributesForm$GotListAttributesResponse));
	});
var $author$project$Ui$PermissionsForm$GotTeamsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ui$PermissionsForm$GotUsersResponse = function (a) {
	return {$: 1, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Hide = 1;
var $author$project$Api$getUsers = F2(
	function (token, toMsg) {
		return A2(
			$author$project$Api$get,
			token,
			{
				j: A2(
					$elm$http$Http$expectJson,
					toMsg,
					A2(
						$elm$json$Json$Decode$field,
						'result',
						A2(
							$elm$json$Json$Decode$field,
							'users',
							$elm$json$Json$Decode$list($author$project$Domain$User$decoder)))),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['queries', 'ListUsers']),
					_List_Nil)
			});
	});
var $inkuzmin$elm_multiselect$Multiselect$Closed = 0;
var $inkuzmin$elm_multiselect$Multiselect$Model = $elm$core$Basics$identity;
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $inkuzmin$elm_multiselect$Multiselect$initModel = F3(
	function (values, tag1, inputInMenu) {
		return {
			gc: $elm$core$Maybe$Nothing,
			l: values,
			n: $elm$core$List$head(values),
			s: $elm$core$Maybe$Just(''),
			cF: inputInMenu,
			cG: 23.0,
			Z: false,
			ea: _List_Nil,
			m: 0,
			cY: tag1,
			r: values
		};
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected = function (_v0) {
	var selected = _v0.ea;
	var values = _v0.r;
	return A2(
		$elm$core$List$filter,
		function (value) {
			return !A2($elm$core$List$member, value, selected);
		},
		values);
};
var $inkuzmin$elm_multiselect$Multiselect$populateValues = F3(
	function (_v0, values, selected) {
		var model = _v0;
		var filtered = $elm$core$List$isEmpty(selected) ? values : $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
			{ea: selected, r: values});
		return _Utils_update(
			model,
			{l: filtered, ea: selected, r: values});
	});
var $author$project$Ui$PermissionsForm$init = F4(
	function (token, seed, source, permissions) {
		var originalUsers = A2(
			$elm$core$List$map,
			function (_v1) {
				var id = _v1.a$;
				var name = _v1.dL;
				return _Utils_Tuple2(id, name);
			},
			permissions.jv);
		var originalTeams = A2(
			$elm$core$List$map,
			function (_v0) {
				var id = _v0.a$;
				var name = _v0.dL;
				return _Utils_Tuple2(id, name);
			},
			permissions.jc);
		return _Utils_Tuple2(
			{
				au: $krisajenkins$remotedata$RemoteData$Loading,
				bl: $krisajenkins$remotedata$RemoteData$Loading,
				O: A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, '_teams', 1),
					_List_Nil,
					originalTeams),
				P: A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, '_users', 1),
					_List_Nil,
					originalUsers),
				a4: originalTeams,
				a5: originalUsers,
				cm: seed,
				cV: source,
				jo: token
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Api$getTeams,
						token,
						false,
						A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Ui$PermissionsForm$GotTeamsResponse)),
						A2(
						$author$project$Api$getUsers,
						token,
						A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Ui$PermissionsForm$GotUsersResponse))
					])));
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Domain$Attributes$match = F2(
	function (attrs1, attrs2) {
		return _Utils_eq(attrs1, attrs2);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Domain$Cart$contains = F2(
	function (element, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$List$member,
			element.a$,
			$elm$core$Dict$keys(dict));
	});
var $author$project$Domain$Cart$insert = F2(
	function (element, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, element.a$, element, dict);
	});
var $author$project$Domain$Cart$remove = F2(
	function (element, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, element.a$, dict);
	});
var $author$project$Domain$Cart$toggle = F2(
	function (element, cart) {
		return A2($author$project$Domain$Cart$contains, element, cart) ? A2($author$project$Domain$Cart$remove, element, cart) : A2($author$project$Domain$Cart$insert, element, cart);
	});
var $author$project$Ui$AttributesForm$GotSearchElementMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ui$SearchElement$ListElementsResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ui$SearchElement$Loading = {$: 0};
var $author$project$Ui$SearchElement$NoReturn = 0;
var $author$project$Ui$SearchElement$init = F2(
	function (token, typeWanted) {
		return _Utils_Tuple2(
			{
				e: {bp: $elm$core$Maybe$Nothing, av: false, b0: $elm$core$Maybe$Nothing, ax: false, bw: $elm$core$Maybe$Nothing, aB: false, cd: $elm$core$Maybe$Nothing, aG: false},
				fs: false,
				ah: _List_Nil,
				ar: '',
				bG: $author$project$Ui$SearchElement$Loading,
				cs: 0,
				c1: typeWanted
			},
			A3(
				$author$project$Api$listElements,
				token,
				$elm$core$Maybe$Just(
					$elm$core$String$toLower(typeWanted)),
				$author$project$Ui$SearchElement$ListElementsResponse));
	});
var $author$project$Ui$SearchElement$Error = {$: 2};
var $author$project$Ui$SearchElement$Searching = {$: 1};
var $author$project$Ui$SearchElement$Success = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$SearchElement$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fs: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var response = msg.a;
				switch (response.$) {
					case 3:
						var elements = response.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ah: elements, bG: $author$project$Ui$SearchElement$Searching}),
							$elm$core$Platform$Cmd$none);
					case 2:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bG: $author$project$Ui$SearchElement$Error}),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bG: $author$project$Ui$SearchElement$Error}),
							$elm$core$Platform$Cmd$none);
				}
			case 2:
				var newInput = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ar: newInput}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var element = msg.a;
				var toReturn = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bG: $author$project$Ui$SearchElement$Success(element.eW),
							cs: toReturn
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{ax: !currentAttributesFilter.ax});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{aG: !currentAttributesFilter.aG});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{av: !currentAttributesFilter.av});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{aB: !currentAttributesFilter.aB});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var newEntity = msg.a;
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{b0: newEntity, ax: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var newPurpose = msg.a;
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{cd: newPurpose, aG: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var newOrder = msg.a;
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{bp: newOrder, av: false, bw: $elm$core$Maybe$Nothing});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
			default:
				var newOrder = msg.a;
				var currentAttributesFilter = model.e;
				var newAttributesFilter = _Utils_update(
					currentAttributesFilter,
					{bp: $elm$core$Maybe$Nothing, bw: newOrder, aB: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: newAttributesFilter}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Ui$Combo$value = function (_v0) {
	var state = _v0;
	return state.jz;
};
var $author$project$Ui$AttributesForm$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 6:
				var s = msg.a;
				var previousAttrs = model.eW;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eW: _Utils_update(
								previousAttrs,
								{
									dL: $author$project$Ui$Combo$value(s)
								}),
							bX: s
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var s = msg.a;
				var previousAttrs = model.eW;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eW: _Utils_update(
								previousAttrs,
								{
									ib: $author$project$Ui$Combo$value(s)
								}),
							bY: s
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var s = msg.a;
				var previousAttrs = model.eW;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eW: _Utils_update(
								previousAttrs,
								{
									gb: $author$project$Ui$Combo$value(s)
								}),
							bW: s
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var name = msg.a;
				var oldAttrs = model.eW;
				var newAttrs = _Utils_update(
					oldAttrs,
					{dL: name});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eW: newAttrs}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var purpose = msg.a;
				var oldAttrs = model.eW;
				var newAttrs = _Utils_update(
					oldAttrs,
					{ib: purpose});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eW: newAttrs}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var entity = msg.a;
				var oldAttrs = model.eW;
				var newAttrs = _Utils_update(
					oldAttrs,
					{gb: entity});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eW: newAttrs}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var response = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bS: response}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var _v1 = A2($author$project$Ui$SearchElement$init, model.jo, model.d5);
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							M: $elm$core$Maybe$Just(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Ui$AttributesForm$GotSearchElementMsg, c));
			default:
				var subMsg = msg.a;
				var _v2 = model.M;
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var subModel = _v2.a;
					var _v3 = A2($author$project$Ui$SearchElement$update, subMsg, subModel);
					var m = _v3.a;
					var c = _v3.b;
					var _v4 = m.bG;
					switch (_v4.$) {
						case 0:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										M: m.fs ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Ui$AttributesForm$GotSearchElementMsg, c));
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										M: m.fs ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Ui$AttributesForm$GotSearchElementMsg, c));
						case 3:
							var newAttributes = _v4.a;
							var _v5 = m.cs;
							switch (_v5) {
								case 1:
									var currentAttrs = model.eW;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												eW: _Utils_update(
													currentAttrs,
													{dL: newAttributes.dL}),
												M: $elm$core$Maybe$Nothing
											}),
										$elm$core$Platform$Cmd$none);
								case 2:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{eW: newAttributes, M: $elm$core$Maybe$Nothing}),
										$elm$core$Platform$Cmd$none);
								default:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{M: $elm$core$Maybe$Nothing}),
										$elm$core$Platform$Cmd$none);
							}
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{M: $elm$core$Maybe$Nothing}),
								A2($elm$core$Platform$Cmd$map, $author$project$Ui$AttributesForm$GotSearchElementMsg, c));
					}
				}
		}
	});
var $author$project$Ui$PermissionsForm$GotChangePermissionsResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$changeElementPermissions = F6(
	function (randomId, token, elementId, usersIds, teamsIds, toMsg) {
		return A5(
			$author$project$Api$command,
			'ChangeElementPermissions',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'element_id',
						$elm$json$Json$Encode$string(elementId)),
						_Utils_Tuple2(
						'permissions',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'teams_ids',
									A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, teamsIds)),
									_Utils_Tuple2(
									'users_ids',
									A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, usersIds))
								])))
					])),
			token,
			toMsg);
	});
var $author$project$Domain$User$fullName = function (user) {
	return user.cA + (' ' + user.cI);
};
var $inkuzmin$elm_multiselect$Multiselect$getSelectedValues = function (_v0) {
	var model = _v0;
	return model.ea;
};
var $inkuzmin$elm_multiselect$Multiselect$Cleared = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$DisableProtection = {$: 3};
var $inkuzmin$elm_multiselect$Multiselect$FocusResult = function (a) {
	return {$: 8, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$NotFound = function (a) {
	return {$: 3, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollResult = function (a) {
	return {$: 9, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollY = function (a) {
	return {$: 14, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Selected = function (a) {
	return {$: 0, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Show = 0;
var $inkuzmin$elm_multiselect$Multiselect$Unselected = function (a) {
	return {$: 1, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace = 8;
var $inkuzmin$elm_multiselect$Multiselect$delayInMs = F2(
	function (ms, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(ms));
	});
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $inkuzmin$elm_multiselect$Multiselect$domScrollToY = F2(
	function (id, y) {
		return A2(
			$elm$core$Task$andThen,
			function (vp) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, vp.eo.eq, y);
			},
			$elm$browser$Browser$Dom$getViewportOf(id));
	});
var $inkuzmin$elm_multiselect$Multiselect$domScrollY = function (id) {
	return A2(
		$elm$core$Task$map,
		function (vp) {
			return vp.eo.er;
		},
		$elm$browser$Browser$Dom$getViewportOf(id));
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow = 40;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$end = 35;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape = 27;
var $inkuzmin$elm_multiselect$Multiselect$fitViewPort = F2(
	function (_v0, _v1) {
		var top = _v0.a;
		var bottom = _v0.b;
		var vpTop = _v1.a;
		var vpBottom = _v1.b;
		return (_Utils_cmp(top, vpTop) < 0) ? top : ((_Utils_cmp(bottom, vpBottom) > 0) ? (vpTop + (bottom - vpBottom)) : vpTop);
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight = 32;
var $inkuzmin$elm_multiselect$Multiselect$getBoundaries = function (i) {
	return _Utils_Tuple2(i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight, (i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight) + $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight = 200;
var $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries = function (i) {
	return _Utils_Tuple2(i, i + $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$home = 36;
var $inkuzmin$elm_multiselect$Multiselect$indexOf = F2(
	function (el, list) {
		var helper = F2(
			function (l, index) {
				helper:
				while (true) {
					if (!l.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var xs = l.b;
						if (_Utils_eq(x, el)) {
							return $elm$core$Maybe$Just(index);
						} else {
							var $temp$l = xs,
								$temp$index = index + 1;
							l = $temp$l;
							index = $temp$index;
							continue helper;
						}
					}
				}
			});
		return A2(helper, list, 0);
	});
var $inkuzmin$elm_multiselect$Multiselect$lastElem = A2(
	$elm$core$List$foldl,
	A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, $elm$core$Basics$always),
	$elm$core$Maybe$Nothing);
var $inkuzmin$elm_multiselect$Multiselect$nextItem = F2(
	function (list, item) {
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? $elm$core$List$head(list) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$nextSelectedItem = F2(
	function (list, item) {
		var takeLast = function (l) {
			if (!l.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!l.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var _v3 = l.b;
					var y = _v3.a;
					return $elm$core$Maybe$Just(y);
				}
			}
		};
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? takeLast(
							$elm$core$List$reverse(list)) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown = 34;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp = 33;
var $inkuzmin$elm_multiselect$Multiselect$prevItem = F2(
	function (list, item) {
		return A2(
			$inkuzmin$elm_multiselect$Multiselect$nextItem,
			$elm$core$List$reverse(list),
			item);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$return = 13;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab = 9;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow = 38;
var $inkuzmin$elm_multiselect$Multiselect$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 4:
				return (model.m === 2) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{m: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY))
							])),
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{m: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY))
							])),
					$elm$core$Maybe$Nothing);
			case 1:
				return model.Z ? _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{m: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				return model.Z ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: true, m: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY)),
								A2($inkuzmin$elm_multiselect$Multiselect$delayInMs, 100, $inkuzmin$elm_multiselect$Multiselect$DisableProtection)
							])),
					$elm$core$Maybe$Nothing);
			case 9:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gc: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v3 = model.s;
					if (_v3.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									s: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{gc: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 8:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gc: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v5 = model.s;
					if (_v5.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									s: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{gc: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 11:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cG: value}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var value = msg.a;
				if (model.Z) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{Z: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var lowerValue = $elm$core$String$toLower(value);
					var valuesMatchingSearch = A2(
						$elm$core$List$filter,
						function (_v8) {
							var val = _v8.b;
							return A2(
								$elm$core$String$contains,
								lowerValue,
								$elm$core$String$toLower(val));
						},
						model.r);
					var maybePrefixedWithValue = ((model.cF === 1) || ((value === '') || A2(
						$elm$core$List$any,
						function (_v7) {
							var val = _v7.b;
							return _Utils_eq(
								$elm$core$String$toLower(val),
								value);
						},
						valuesMatchingSearch))) ? valuesMatchingSearch : A2(
						$elm$core$List$cons,
						_Utils_Tuple2(value, value),
						valuesMatchingSearch);
					var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
						{ea: model.ea, r: maybePrefixedWithValue});
					var _v6 = model.n;
					if (_v6.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									l: filtered,
									n: $elm$core$List$head(filtered),
									s: $elm$core$Maybe$Just(value),
									m: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v6.a;
						return (!$elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i, item);
								},
								filtered))) ? _Utils_Tuple3(
							_Utils_update(
								model,
								{
									l: filtered,
									n: $elm$core$List$head(filtered),
									s: $elm$core$Maybe$Just(value),
									m: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing) : _Utils_Tuple3(
							_Utils_update(
								model,
								{
									l: filtered,
									s: $elm$core$Maybe$Just(value),
									m: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 5:
				var item = msg.a;
				var selected = _Utils_ap(
					model.ea,
					_List_fromArray(
						[item]));
				var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
					{ea: selected, r: model.r});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							l: filtered,
							n: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.l, item),
							s: $elm$core$Maybe$Nothing,
							ea: selected,
							m: $elm$core$List$isEmpty(filtered) ? 0 : 2
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
			case 6:
				var item = msg.a;
				var selected = A2(
					$elm$core$List$filter,
					function (value) {
						return !_Utils_eq(value, item);
					},
					model.ea);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							l: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{ea: selected, r: model.r}),
							n: $elm$core$Maybe$Just(item),
							ea: selected
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$ScrollY,
								$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
			case 7:
				var selected = _List_Nil;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							l: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{ea: selected, r: model.r}),
							s: $elm$core$Maybe$Nothing,
							ea: selected,
							m: 0
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY))
							])),
					$elm$core$Maybe$Just($inkuzmin$elm_multiselect$Multiselect$Cleared));
			case 12:
				var item = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							n: $elm$core$Maybe$Just(item)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								gc: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var y = result.a;
					var _v10 = model.n;
					if (_v10.$ === 1) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					} else {
						var item = _v10.a;
						var _v11 = A2($inkuzmin$elm_multiselect$Multiselect$indexOf, item, model.l);
						if (_v11.$ === 1) {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						} else {
							var idx = _v11.a;
							var vpBoundaries = $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries(y);
							var boundaries = $inkuzmin$elm_multiselect$Multiselect$getBoundaries(idx);
							var scroll = A2($inkuzmin$elm_multiselect$Multiselect$fitViewPort, boundaries, vpBoundaries);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{gc: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollResult,
											A2($inkuzmin$elm_multiselect$Multiselect$domScrollToY, 'multiselectMenu' + model.cY, scroll))
										])),
								$elm$core$Maybe$Nothing);
						}
					}
				}
			default:
				var key = msg.a;
				if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow)) {
					var _v12 = model.n;
					if (_v12.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									n: $elm$core$List$head(model.l)
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v12.a;
						var prev = A2($inkuzmin$elm_multiselect$Multiselect$prevItem, model.l, item);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{n: prev}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Task$attempt,
										$inkuzmin$elm_multiselect$Multiselect$ScrollY,
										$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
									])),
							$elm$core$Maybe$Nothing);
					}
				} else {
					if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) {
						var _v13 = model.n;
						if (_v13.$ === 1) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										n: $elm$core$List$head(model.l)
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var item = _v13.a;
							var next = A2($inkuzmin$elm_multiselect$Multiselect$nextItem, model.l, item);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{n: next}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
										])),
								$elm$core$Maybe$Nothing);
						}
					} else {
						if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$home)) {
							var first = $elm$core$List$head(model.l);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{n: first}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
										])),
								$elm$core$Maybe$Nothing);
						} else {
							if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$end)) {
								var last = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.l);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{n: last}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Task$attempt,
												$inkuzmin$elm_multiselect$Multiselect$ScrollY,
												$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
											])),
									$elm$core$Maybe$Nothing);
							} else {
								if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$return) && ((model.m === 2) || $elm$core$List$isEmpty(model.l))) {
									var _v14 = model.n;
									if (_v14.$ === 1) {
										var _v15 = model.s;
										if (_v15.$ === 1) {
											return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
										} else {
											if (_v15.a === '') {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											} else {
												var input_ = _v15.a;
												return _Utils_Tuple3(
													model,
													$elm$core$Platform$Cmd$none,
													$elm$core$Maybe$Just(
														$inkuzmin$elm_multiselect$Multiselect$NotFound(input_)));
											}
										}
									} else {
										var _v16 = _v14.a;
										var id = _v16.a;
										var val = _v16.b;
										var lowerVal = $elm$core$String$toLower(val);
										if ((!model.cF) && (_Utils_eq(
											$elm$core$Maybe$Just(val),
											model.s) && A2(
											$elm$core$List$all,
											function (_v17) {
												var value = _v17.b;
												return !_Utils_eq(
													$elm$core$String$toLower(value),
													lowerVal);
											},
											model.r))) {
											return _Utils_Tuple3(
												model,
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$NotFound(val)));
										} else {
											var item = _Utils_Tuple2(id, val);
											var selected = _Utils_ap(
												model.ea,
												_List_fromArray(
													[item]));
											var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
												{ea: selected, r: model.r});
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{
														l: filtered,
														n: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.l, item),
														s: $elm$core$Maybe$Nothing,
														ea: selected,
														m: $elm$core$List$isEmpty(filtered) ? 0 : 2
													}),
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2(
															$elm$core$Task$attempt,
															$inkuzmin$elm_multiselect$Multiselect$FocusResult,
															$elm$browser$Browser$Dom$focus('multiselectInput' + model.cY))
														])),
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
										}
									}
								} else {
									if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape)) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{Z: true, m: 0}),
											$elm$core$Platform$Cmd$none,
											$elm$core$Maybe$Nothing);
									} else {
										if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab)) {
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{m: 0}),
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Nothing);
										} else {
											if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace)) {
												var _v18 = model.s;
												if ((!_v18.$) && (_v18.a === '')) {
													var _v19 = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.ea);
													if (_v19.$ === 1) {
														return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
													} else {
														var item = _v19.a;
														var selected = A2(
															$elm$core$List$filter,
															function (value) {
																return !_Utils_eq(value, item);
															},
															model.ea);
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{
																	l: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
																		{ea: selected, r: model.r}),
																	n: $elm$core$Maybe$Just(item),
																	ea: selected
																}),
															$elm$core$Platform$Cmd$batch(
																_List_fromArray(
																	[
																		A2(
																		$elm$core$Task$attempt,
																		$inkuzmin$elm_multiselect$Multiselect$ScrollY,
																		$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.cY))
																	])),
															$elm$core$Maybe$Just(
																$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
													}
												} else {
													return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
												}
											} else {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											}
										}
									}
								}
							}
						}
					}
				}
		}
	});
var $author$project$Ui$PermissionsForm$update2 = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (msg.a.$ === 3) {
					var response = msg.a;
					var teams = response.a;
					var teamsValues = A2(
						$elm$core$List$map,
						function (t) {
							return _Utils_Tuple2(t.a$, t.dL);
						},
						teams);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								au: response,
								O: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, model.O, teamsValues, model.a4)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var response = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{au: response}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (msg.a.$ === 3) {
					var response = msg.a;
					var users = response.a;
					var usersValues = A2(
						$elm$core$List$map,
						function (u) {
							return _Utils_Tuple2(
								u.a$,
								$author$project$Domain$User$fullName(u));
						},
						users);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bl: response,
								P: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, model.P, usersValues, model.a5)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var response = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bl: response}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var subMsg = msg.a;
				var _v1 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.O);
				var subModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Ui$PermissionsForm$GotMultiselectTeamsMsg, subCmd));
			case 3:
				var subMsg = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.P);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{P: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Ui$PermissionsForm$GotMultiselectUsersMsg, subCmd));
			case 5:
				var users = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.P);
				var teams = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.O);
				var _v3 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v3.a;
				var newSeed = _v3.b;
				var cmd = function () {
					var _v4 = model.cV;
					var elementId = _v4;
					var usersIds = A2($elm$core$List$map, $elm$core$Tuple$first, users);
					var teamsIds = A2($elm$core$List$map, $elm$core$Tuple$first, teams);
					return A6($author$project$Api$changeElementPermissions, randomId, model.jo, elementId, usersIds, teamsIds, $author$project$Ui$PermissionsForm$GotChangePermissionsResponse);
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a4: teams, a5: users, cm: newSeed}),
					cmd);
			default:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Ui$PermissionsForm$update = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Ui$PermissionsForm$update2, msg, model);
		var m = _v0.a;
		var c = _v0.b;
		var teams = A2(
			$elm$core$List$map,
			function (_v2) {
				var id = _v2.a;
				var name = _v2.b;
				return {a$: id, dL: name};
			},
			m.a4);
		var users = A2(
			$elm$core$List$map,
			function (_v1) {
				var id = _v1.a;
				var name = _v1.b;
				return {a$: id, dL: name};
			},
			m.a5);
		return _Utils_Tuple3(
			m,
			c,
			{jc: teams, jv: users});
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Page$Library$versionChanged = F2(
	function (model, newIndexStringCurrentVersion) {
		var _v0 = _Utils_Tuple2(
			model.y,
			$elm$core$String$toInt(newIndexStringCurrentVersion));
		if (_v0.a.$ === 1) {
			var _v1 = _v0.a;
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			if (_v0.b.$ === 1) {
				var _v2 = _v0.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			} else {
				var selectedElement = _v0.a.a;
				var newIndexCurrentVersion = _v0.b.a;
				var newCurrentVersion = A2(
					$elm$core$Maybe$withDefault,
					selectedElement.fx,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$second,
						A2(
							$elm_community$list_extra$List$Extra$find,
							function (_v6) {
								var index = _v6.a;
								return _Utils_eq(index, newIndexCurrentVersion - 1);
							},
							A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, selectedElement.jD))));
				var elementWithNewCurrentVersion = _Utils_update(
					selectedElement,
					{fx: newCurrentVersion});
				var _v3 = A4($author$project$Ui$PermissionsForm$init, model.jt.jo, model.cm, newCurrentVersion.a$, elementWithNewCurrentVersion.fx.h3);
				var permissionsFormModel = _v3.a;
				var permissionsFormCmds = _v3.b;
				var _v4 = A2(
					$author$project$Ui$AttributesForm$init,
					model.jt,
					{
						dl: newCurrentVersion.eW,
						dn: false,
						dQ: $elm$core$Maybe$Nothing,
						d5: function () {
							var _v5 = model.a9;
							return 'excel';
						}()
					});
				var attributesFormModel = _v4.a;
				var attributesFormCmds = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aa: $elm$core$Maybe$Just(attributesFormModel),
							ap: $elm$core$Maybe$Just(permissionsFormModel),
							y: $elm$core$Maybe$Just(elementWithNewCurrentVersion)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotPermissionsFormMsg, permissionsFormCmds),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotAttributesFormMsg, attributesFormCmds)
							])));
			}
		}
	});
var $author$project$Page$Library$update2 = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: $elm$random$Random$initialSeed(
								$elm$time$Time$posixToMillis(time))
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var element = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fb: A2($author$project$Domain$Cart$toggle, element, model.fb)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var response = msg.a;
				_v1$2:
				while (true) {
					switch (response.$) {
						case 2:
							if ((response.a.$ === 3) && (response.a.a === 401)) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{ah: response}),
									A2($author$project$Route$push, model.k, $author$project$Route$Logout));
							} else {
								break _v1$2;
							}
						case 3:
							var elements = response.a;
							var reworkedElements = A2(
								$elm$core$List$sortBy,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.eW;
									},
									function ($) {
										return $.dL;
									}),
								elements);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ah: $krisajenkins$remotedata$RemoteData$Success(reworkedElements),
										y: A2(
											$elm$core$Maybe$andThen,
											function (selectedElement) {
												return A2(
													$elm_community$list_extra$List$Extra$find,
													function (e) {
														return A2($author$project$Domain$Attributes$match, e.eW, selectedElement.eW);
													},
													elements);
											},
											model.y)
									}),
								$elm$core$Platform$Cmd$none);
						default:
							break _v1$2;
					}
				}
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ah: response}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var type_ = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a9: type_}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var element = msg.a;
				var _v2 = A4($author$project$Ui$PermissionsForm$init, model.jt.jo, model.cm, element.a$, element.fx.h3);
				var permissionsFormModel = _v2.a;
				var permissionsFormCmds = _v2.b;
				var _v3 = A2(
					$author$project$Ui$AttributesForm$init,
					model.jt,
					{
						dl: element.fx.eW,
						dn: false,
						dQ: $elm$core$Maybe$Nothing,
						d5: function () {
							var _v4 = model.a9;
							return 'excel';
						}()
					});
				var attributesFormModel = _v3.a;
				var attributesFormCmds = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aa: $elm$core$Maybe$Just(attributesFormModel),
							ap: $elm$core$Maybe$Just(permissionsFormModel),
							y: $elm$core$Maybe$Just(element)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotPermissionsFormMsg, permissionsFormCmds),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotAttributesFormMsg, attributesFormCmds)
							])));
			case 13:
				var element = msg.a;
				var _v5 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v5.a;
				var randomSeed = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cm: randomSeed}),
					A5($author$project$Api$deleteElement, randomId, element.fx.a$, true, model.jt.jo, $author$project$Page$Library$GotDeleteElementVersionResponse));
			case 14:
				var element = msg.a;
				var _v6 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v6.a;
				var randomSeed = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cm: randomSeed}),
					A2(
						$elm$core$Task$attempt,
						function (result) {
							var mergeBools = A2(
								$elm$core$Result$map,
								$elm$core$List$all($elm$core$Basics$identity),
								result);
							return $author$project$Page$Library$GotDeleteElementVersionResponse(mergeBools);
						},
						$elm$core$Task$sequence(
							A2(
								$elm$core$List$map,
								function (v) {
									return A4($author$project$Api$deleteElementAsTask, randomId, v.a$, true, model.jt.jo);
								},
								element.jD))));
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var currentIndexVersion = msg.a;
				return A2(
					$author$project$Page$Library$versionChanged,
					model,
					$elm$core$String$fromInt(currentIndexVersion + 1));
			case 5:
				var currentIndexVersion = msg.a;
				return A2(
					$author$project$Page$Library$versionChanged,
					model,
					$elm$core$String$fromInt(currentIndexVersion - 1));
			case 6:
				var newIndexStringCurrentVersion = msg.a;
				return A2($author$project$Page$Library$versionChanged, model, newIndexStringCurrentVersion);
			case 12:
				var attrs = msg.a;
				var element = msg.b;
				var selectedElement = _Utils_update(
					element,
					{eW: attrs});
				var _v7 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v7.a;
				var randomSeed = _v7.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: randomSeed,
							y: $elm$core$Maybe$Just(selectedElement)
						}),
					A2(
						$elm$core$Task$attempt,
						function (result) {
							var mergeBools = A2(
								$elm$core$Result$map,
								$elm$core$List$all($elm$core$Basics$identity),
								result);
							return $author$project$Page$Library$GotSaveAttributesResponse(mergeBools);
						},
						$elm$core$Task$sequence(
							A2(
								$elm$core$List$map,
								function (v) {
									return A4($author$project$Api$changeElementAttributesAsTask, randomId, v.a$, attrs, model.jt.jo);
								},
								element.jD))));
			case 11:
				var attrs = msg.a;
				var element = msg.b;
				var newSelectedElement = ($elm$core$List$length(element.jD) === 1) ? _Utils_update(
					element,
					{eW: attrs}) : _Utils_update(
					element,
					{
						eW: attrs,
						a$: element.fx.a$,
						jD: _List_fromArray(
							[element.fx])
					});
				var _v8 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v8.a;
				var randomSeed = _v8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: randomSeed,
							y: $elm$core$Maybe$Just(newSelectedElement)
						}),
					A5($author$project$Api$changeElementAttributes, randomId, newSelectedElement.a$, attrs, model.jt.jo, $author$project$Page$Library$GotSaveAttributesResponse));
			case 15:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(
							model,
							A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
					} else {
						return _Utils_Tuple2(
							model,
							A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
					}
				} else {
					return _Utils_Tuple2(
						model,
						A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
				}
			case 16:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(
							model,
							A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
					} else {
						return _Utils_Tuple2(
							model,
							A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
					}
				} else {
					return _Utils_Tuple2(
						model,
						A3($author$project$Api$listElements, model.jt.jo, $elm$core$Maybe$Nothing, $author$project$Page$Library$ListElementsResponse));
				}
			case 10:
				var subMsg = msg.a;
				var _v9 = model.aa;
				if (_v9.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var attributesFormModel = _v9.a;
					var _v10 = A2($author$project$Ui$AttributesForm$update, subMsg, attributesFormModel);
					var subModel = _v10.a;
					var subCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aa: $elm$core$Maybe$Just(subModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotAttributesFormMsg, subCmd));
				}
			case 9:
				var subMsg = msg.a;
				var _v11 = model.ap;
				if (_v11.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var permissionsFormModel = _v11.a;
					var _v12 = model.y;
					if (_v12.$ === 1) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var selectedElement = _v12.a;
						var currentVersion = selectedElement.fx;
						var _v13 = A2($author$project$Ui$PermissionsForm$update, subMsg, permissionsFormModel);
						var subModel = _v13.a;
						var subCmd = _v13.b;
						var permissions = _v13.c;
						var currentVersionModified = _Utils_update(
							currentVersion,
							{h3: permissions});
						var selectedElement2 = _Utils_update(
							selectedElement,
							{
								fx: currentVersionModified,
								jD: A2(
									$elm$core$List$map,
									function (v) {
										return _Utils_eq(v.a$, currentVersion.a$) ? currentVersionModified : v;
									},
									selectedElement.jD)
							});
						var elements = A2(
							$krisajenkins$remotedata$RemoteData$map,
							$elm$core$List$map(
								function (e) {
									if (_Utils_eq(e.a$, selectedElement.a$)) {
										var cv = e.fx;
										var cv2 = _Utils_update(
											cv,
											{h3: permissions});
										return _Utils_update(
											e,
											{fx: cv2});
									} else {
										return e;
									}
								}),
							model.ah);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ah: elements,
									ap: $elm$core$Maybe$Just(subModel),
									y: $elm$core$Maybe$Just(selectedElement2)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Page$Library$GotPermissionsFormMsg, subCmd));
					}
				}
			default:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a8: state}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Library$update = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Page$Library$update2, msg, model);
		var newModel = _v0.a;
		var newCmd = _v0.b;
		return _Utils_Tuple3(newModel, newCmd, newModel.fb);
	});
var $author$project$Page$Login$ForgotPasswordScreen = 1;
var $author$project$Page$Login$GotLogin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Login$GotOpenUserResponse = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Login$LoggedInUser = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$Login$LoginInProgress = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$RequestResetLinkResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Login$ResetPasswordScreen = 2;
var $author$project$Api$encoderForgotPassword = function (email) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'email',
							$elm$json$Json$Encode$string(email))
						])))
			]));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{D: r.D, j: r.j, aA: _List_Nil, aE: 'POST', aK: $elm$core$Maybe$Nothing, bg: $elm$core$Maybe$Nothing, h: r.h});
};
var $author$project$Email$toString = function (_v0) {
	var email = _v0;
	return email;
};
var $author$project$Api$forgotPassword = F2(
	function (email, toMsg) {
		return $elm$http$Http$post(
			{
				D: $elm$http$Http$jsonBody(
					$author$project$Api$encoderForgotPassword(
						$author$project$Email$toString(email))),
				j: $elm$http$Http$expectWhatever(toMsg),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['accounts', 'users', 'reset_password']),
					_List_Nil)
			});
	});
var $author$project$Api$login = F2(
	function (_v0, toMsg) {
		var email = _v0.dr;
		var password = _v0.dR;
		return $elm$http$Http$post(
			{
				D: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'email',
											$elm$json$Json$Encode$string(email)),
											_Utils_Tuple2(
											'password',
											$elm$json$Json$Encode$string(password))
										])))
							]))),
				j: A2(
					$elm$http$Http$expectJson,
					toMsg,
					A2($elm$json$Json$Decode$field, 'data', $author$project$LoggedIn$decoderForLocalStorage)),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['accounts', 'users', 'log_in']),
					_List_Nil)
			});
	});
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var loginData = msg.a;
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{ao: true}),
						A2($author$project$Api$login, loginData, $author$project$Page$Login$GotLogin)));
			case 1:
				var email = msg.a;
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{dr: email}),
						$elm$core$Platform$Cmd$none));
			case 2:
				var password = msg.a;
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{dR: password}),
						$elm$core$Platform$Cmd$none));
			case 5:
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{aw: 1}),
						$elm$core$Platform$Cmd$none));
			case 6:
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{aw: 0}),
						$elm$core$Platform$Cmd$none));
			case 4:
				var loggedInUser = msg.a;
				var response = msg.b;
				if (response.$ === 3) {
					var result = response.a;
					return A2(
						$author$project$Page$Login$LoggedInUser,
						_Utils_update(
							loggedInUser,
							{
								f5: result.f5,
								gV: result.gV,
								gW: result.gW,
								jc: $krisajenkins$remotedata$RemoteData$Success(result.jc),
								jt: $krisajenkins$remotedata$RemoteData$Success(result.jt)
							}),
						model.bR);
				} else {
					return A2($author$project$Page$Login$LoggedInUser, loggedInUser, model.bR);
				}
			case 3:
				if (!msg.a.$) {
					var user = msg.a.a;
					return $author$project$Page$Login$LoginInProgress(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{aw: 0}),
							A2(
								$author$project$Api$openUser,
								user.jo,
								$author$project$Page$Login$GotOpenUserResponse(user))));
				} else {
					var err = msg.a.a;
					if ((err.$ === 3) && (err.a === 401)) {
						return $author$project$Page$Login$LoginInProgress(
							_Utils_Tuple2(
								_Utils_update(
									model,
									{bz: true, ao: false}),
								$elm$core$Platform$Cmd$none));
					} else {
						return $author$project$Page$Login$LoginInProgress(
							_Utils_Tuple2(
								_Utils_update(
									model,
									{bz: false}),
								$elm$core$Platform$Cmd$none));
					}
				}
			case 7:
				var email = msg.a;
				return $author$project$Page$Login$LoginInProgress(
					_Utils_Tuple2(
						model,
						A2($author$project$Api$forgotPassword, email, $author$project$Page$Login$RequestResetLinkResponse)));
			default:
				if (!msg.a.$) {
					return $author$project$Page$Login$LoginInProgress(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{aw: 2, ao: false}),
							$elm$core$Platform$Cmd$none));
				} else {
					return $author$project$Page$Login$LoginInProgress(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{ao: false}),
							$elm$core$Platform$Cmd$none));
				}
		}
	});
var $author$project$Page$Register$AlreadyExistingEmail = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Register$GotEmailValidation = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Register$GotPasswordDelayAgo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Register$GotRegisterResponse = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Register$NotAnEmail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Register$StepTransmission = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Register$TestingExistingEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Register$ValidatedEmail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Register$emailToString = function (email) {
	switch (email.$) {
		case 0:
			var string = email.a;
			return string;
		case 1:
			var string = email.a;
			return string;
		case 2:
			var mail = email.a;
			return $author$project$Email$toString(mail);
		case 3:
			var mail = email.a;
			return $author$project$Email$toString(mail);
		default:
			var mail = email.a;
			return $author$project$Email$toString(mail);
	}
};
var $author$project$Email$Email = $elm$core$Basics$identity;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {gN: index, hk: match, hO: number, i1: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{fc: false, hu: false},
		string);
};
var $author$project$Email$isValid = function (email) {
	var _v0 = $elm$regex$Regex$fromString('^[a-zA-Z0-9._!%+-]{1,64}@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
	if (!_v0.$) {
		var regex = _v0.a;
		return A2($elm$regex$Regex$contains, regex, email);
	} else {
		return false;
	}
};
var $author$project$Email$parse = function (email) {
	return $author$project$Email$isValid(email) ? $elm$core$Maybe$Just(email) : $elm$core$Maybe$Nothing;
};
var $author$project$Page$Register$passwordValidationDelay = 1500;
var $author$project$Password$toString = function (_v0) {
	var password = _v0;
	return password;
};
var $author$project$Api$register = F2(
	function (_v0, toMsg) {
		var email = _v0.dr;
		var password = _v0.dR;
		var firstName = _v0.gq;
		var lastName = _v0.g$;
		var passwordValue = $elm$json$Json$Encode$string(
			$author$project$Password$toString(password));
		return $elm$http$Http$post(
			{
				D: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'email',
											$elm$json$Json$Encode$string(
												$author$project$Email$toString(email))),
											_Utils_Tuple2(
											'firstname',
											$elm$json$Json$Encode$string(firstName)),
											_Utils_Tuple2(
											'lastname',
											$elm$json$Json$Encode$string(lastName)),
											_Utils_Tuple2('password', passwordValue),
											_Utils_Tuple2('password_confirmation', passwordValue)
										])))
							]))),
				j: $elm$http$Http$expectWhatever(toMsg),
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['accounts', 'users', 'register']),
					_List_Nil)
			});
	});
var $author$project$Page$Register$GotEmailDelayAgo = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Register$emailValidationDelay = 500;
var $author$project$Page$Register$validationEmailCmd = function (email) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(
			$author$project$Page$Register$GotEmailDelayAgo(email)),
		$elm$core$Process$sleep($author$project$Page$Register$emailValidationDelay));
};
var $author$project$Page$Register$updateStep1 = F2(
	function (msg, data) {
		switch (msg.$) {
			case 2:
				var password = msg.a;
				return _Utils_Tuple2(
					$author$project$Page$Register$Step1(
						_Utils_update(
							data,
							{
								an: !_Utils_eq(data.dR, password)
							})),
					$elm$core$Platform$Cmd$none);
			case 0:
				switch (msg.a.$) {
					case 0:
						var firstName = msg.a.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{gq: firstName})),
							$elm$core$Platform$Cmd$none);
					case 1:
						var lastName = msg.a.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{g$: lastName})),
							$elm$core$Platform$Cmd$none);
					case 2:
						var email = msg.a.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{
										dr: $author$project$Page$Register$EnteringEmail(email)
									})),
							$author$project$Page$Register$validationEmailCmd(email));
					case 3:
						var password = msg.a.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{dR: password, an: true})),
							A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									$author$project$Page$Register$GotPasswordDelayAgo(password)),
								$elm$core$Process$sleep($author$project$Page$Register$passwordValidationDelay)));
					default:
						var password = msg.a.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{Y: password})),
							$elm$core$Platform$Cmd$none);
				}
			case 1:
				var email = msg.a;
				if (!_Utils_eq(
					email,
					$author$project$Page$Register$emailToString(data.dr))) {
					return _Utils_Tuple2(
						$author$project$Page$Register$Step1(data),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v1 = $author$project$Email$parse(email);
					if (!_v1.$) {
						var mail = _v1.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{
										dr: $author$project$Page$Register$TestingExistingEmail(mail)
									})),
							A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									A2($author$project$Page$Register$GotEmailValidation, mail, false)),
								$elm$core$Process$sleep(200)));
					} else {
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								_Utils_update(
									data,
									{
										dr: $author$project$Page$Register$NotAnEmail(email)
									})),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 3:
				var email = msg.a;
				var alreadyExists = msg.b;
				return (!_Utils_eq(
					$author$project$Email$toString(email),
					$author$project$Page$Register$emailToString(data.dr))) ? _Utils_Tuple2(
					$author$project$Page$Register$Step1(data),
					$elm$core$Platform$Cmd$none) : (alreadyExists ? _Utils_Tuple2(
					$author$project$Page$Register$Step1(
						_Utils_update(
							data,
							{
								dr: $author$project$Page$Register$AlreadyExistingEmail(email)
							})),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					$author$project$Page$Register$Step1(
						_Utils_update(
							data,
							{
								dr: $author$project$Page$Register$ValidatedEmail(email)
							})),
					$elm$core$Platform$Cmd$none));
			case 4:
				var dataTransmission = msg.a;
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(dataTransmission),
					A2(
						$author$project$Api$register,
						{dr: dataTransmission.dr, gq: dataTransmission.gq, g$: dataTransmission.g$, dR: dataTransmission.dR},
						$author$project$Page$Register$GotRegisterResponse(dataTransmission.dr)));
			default:
				return _Utils_Tuple2(
					$author$project$Page$Register$Step1(data),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Register$StepAccountCreated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Register$updateStepTransmission = F2(
	function (msg, data) {
		switch (msg.$) {
			case 5:
				if (!msg.b.$) {
					return _Utils_Tuple2(
						$author$project$Page$Register$StepAccountCreated(data),
						$elm$core$Platform$Cmd$none);
				} else {
					if ((msg.b.a.$ === 3) && (msg.b.a.a === 409)) {
						var email = msg.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								{
									dr: $author$project$Page$Register$AlreadyExistingEmail(email),
									gq: data.gq,
									g$: data.g$,
									dR: $author$project$Password$toString(data.dR),
									Y: $author$project$Password$toString(data.dR),
									an: false,
									ba: false
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var email = msg.a;
						return _Utils_Tuple2(
							$author$project$Page$Register$Step1(
								{
									dr: $author$project$Page$Register$ValidatedEmail(email),
									gq: data.gq,
									g$: data.g$,
									dR: $author$project$Password$toString(data.dR),
									Y: $author$project$Password$toString(data.dR),
									an: false,
									ba: true
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(data),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(data),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(data),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(data),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					$author$project$Page$Register$StepTransmission(data),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Register$update = F2(
	function (msg, model) {
		switch (model.$) {
			case 0:
				var data = model.a;
				return A2($author$project$Page$Register$updateStep1, msg, data);
			case 1:
				var data = model.a;
				return A2($author$project$Page$Register$updateStepTransmission, msg, data);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$ResetPassword$ResetPasswordResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$encoderResetPassword = function (password) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'password',
							$elm$json$Json$Encode$string(password)),
							_Utils_Tuple2(
							'password_confirmation',
							$elm$json$Json$Encode$string(password))
						])))
			]));
};
var $author$project$Api$resetPassword = F3(
	function (token, password, toMsg) {
		return $elm$http$Http$request(
			{
				D: $elm$http$Http$jsonBody(
					$author$project$Api$encoderResetPassword(password)),
				j: $elm$http$Http$expectWhatever(toMsg),
				aA: _List_Nil,
				aE: 'PUT',
				aK: $elm$core$Maybe$Nothing,
				bg: $elm$core$Maybe$Nothing,
				h: A2(
					$author$project$Api$buildUrl,
					_List_fromArray(
						['accounts', 'users', 'reset_password', token]),
					_List_Nil)
			});
	});
var $author$project$Page$ResetPassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var password = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dR: password}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var token = msg.a.jo;
				var password = msg.a.dR;
				return _Utils_Tuple2(
					model,
					A3($author$project$Api$resetPassword, token, password, $author$project$Page$ResetPassword$ResetPasswordResponse));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2($author$project$Route$push, model.k, $author$project$Route$Login));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Team$GotCreateTeamMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Team$GotNewTeamResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Team$GotRemoveTeamResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Team$GotUsersFromTeamResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$createTeam = F6(
	function (token, randomId, teamId, newTeamName, users, toMsg) {
		return A5(
			$author$project$Api$command,
			'CreateTeam',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'team_id',
						$elm$json$Json$Encode$string(teamId)),
						_Utils_Tuple2(
						'team_name',
						$elm$json$Json$Encode$string(newTeamName)),
						_Utils_Tuple2(
						'users_ids',
						A2(
							$elm$json$Json$Encode$list,
							function (u) {
								return $elm$json$Json$Encode$string(u.a$);
							},
							users))
					])),
			token,
			toMsg);
	});
var $author$project$Domain$Team$emptySubTeams = _List_Nil;
var $author$project$Page$TeamDetails$init = F3(
	function (token, team, availableTeams) {
		return _Utils_Tuple2(
			{
				aM: $elm$core$Maybe$Nothing,
				au: availableTeams,
				ag: $elm$core$Maybe$Nothing,
				ce: '',
				it: false,
				cm: $elm$random$Random$initialSeed(0),
				cp: $billstclair$elm_sortable_table$Table$initialSort('Name'),
				jb: team,
				jo: token
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Ui$CreateTeam$GotUsersReponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$CreateTeam$Loading = 0;
var $author$project$Ui$CreateTeam$init = function (token) {
	return _Utils_Tuple2(
		{
			fs: false,
			ce: '',
			bG: 0,
			cp: $billstclair$elm_sortable_table$Table$initialSort('name'),
			cs: {dN: '', jv: _List_Nil},
			jo: token,
			jv: _List_Nil,
			jx: false
		},
		A2($author$project$Api$getUsers, token, $author$project$Ui$CreateTeam$GotUsersReponse));
};
var $author$project$Domain$Lifecycle$new = F2(
	function (time, author) {
		return {
			dh: time,
			di: $elm$time$Time$posixToMillis(time),
			dj: author,
			dF: time,
			dG: $elm$time$Time$posixToMillis(time),
			dH: author
		};
	});
var $author$project$Api$encoderRemoveTeam = function (teamId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'team_id',
				$elm$json$Json$Encode$string(teamId))
			]));
};
var $author$project$Api$removeTeam = F4(
	function (token, randomId, teamId, toMsg) {
		return A5(
			$author$project$Api$command,
			'DeleteTeam',
			randomId,
			$author$project$Api$encoderRemoveTeam(teamId),
			token,
			toMsg);
	});
var $author$project$LoggedIn$fullName = function (user) {
	var _v0 = user.jt;
	switch (_v0.$) {
		case 3:
			var u = _v0.a;
			return $author$project$Domain$User$fullName(u);
		case 2:
			return 'name failed to load';
		default:
			return 'name not loaded';
	}
};
var $author$project$LoggedIn$toAuthor = function (user) {
	return {
		a$: user.a$,
		dL: $author$project$LoggedIn$fullName(user)
	};
};
var $author$project$Page$TeamDetails$ChangeTeamMembersResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$TeamDetails$GotRemoveUserResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$TeamDetails$GotUsersDialogMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Domain$Team$getSubteams = function (team) {
	var _v0 = team.cX;
	var subteams = _v0;
	return subteams;
};
var $author$project$Api$changeTeamMembers = F4(
	function (token, randomId, team, toMsg) {
		return A5(
			$author$project$Api$command,
			'ChangeTeamMembers',
			randomId,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'team_id',
						$elm$json$Json$Encode$string(team.a$)),
						_Utils_Tuple2(
						'users_ids',
						A2(
							$elm$json$Json$Encode$list,
							$elm$json$Json$Encode$string,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.a$;
								},
								team.jv))),
						_Utils_Tuple2(
						'teams_ids',
						A2(
							$elm$json$Json$Encode$list,
							$elm$json$Json$Encode$string,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.a$;
								},
								$author$project$Domain$Team$getSubteams(team))))
					])),
			token,
			toMsg);
	});
var $author$project$Ui$AddAndRemoveUsersFromTeam$GotUsersReponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$Loading = 0;
var $author$project$Ui$AddAndRemoveUsersFromTeam$init = F2(
	function (token, usersInTeam) {
		return _Utils_Tuple2(
			{
				fs: false,
				cf: '',
				cg: '',
				bG: 0,
				cp: $billstclair$elm_sortable_table$Table$initialSort('name'),
				jo: token,
				ct: _List_Nil,
				jw: usersInTeam,
				jx: false
			},
			A2($author$project$Api$getUsers, token, $author$project$Ui$AddAndRemoveUsersFromTeam$GotUsersReponse));
	});
var $author$project$Page$TeamDetails$initDialogSubteam = function (model) {
	return {
		au: model.au,
		cy: _List_fromArray(
			[model.jb]),
		bF: $author$project$Domain$Team$getSubteams(model.jb)
	};
};
var $author$project$Domain$Team$setSubTeams = F2(
	function (subteams, team) {
		return _Utils_update(
			team,
			{cX: subteams});
	});
var $author$project$Ui$AddAndRemoveUsersFromTeam$Error = 2;
var $author$project$Ui$AddAndRemoveUsersFromTeam$Loaded = 1;
var $elm_community$list_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elm_community$list_extra$List$Extra$removeHelp = F4(
	function (list, x, xs, previousElements) {
		removeHelp:
		while (true) {
			if (!xs.b) {
				return list;
			} else {
				var y = xs.a;
				var ys = xs.b;
				if (_Utils_eq(x, y)) {
					return A2($elm_community$list_extra$List$Extra$reverseAppend, previousElements, ys);
				} else {
					var $temp$list = list,
						$temp$x = x,
						$temp$xs = ys,
						$temp$previousElements = A2($elm$core$List$cons, y, previousElements);
					list = $temp$list;
					x = $temp$x;
					xs = $temp$xs;
					previousElements = $temp$previousElements;
					continue removeHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		return A4($elm_community$list_extra$List$Extra$removeHelp, xs, x, xs, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $author$project$Ui$AddAndRemoveUsersFromTeam$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fs: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jx: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var response = msg.a;
				if (!response.$) {
					var users = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bG: 1, ct: users}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bG: 2}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cf: newQuery}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cg: newQuery}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jw: A2(
								$elm_community$list_extra$List$Extra$uniqueBy,
								function ($) {
									return $.a$;
								},
								A2(
									$elm$core$List$append,
									model.jw,
									_List_fromArray(
										[user])))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jw: A2($elm_community$list_extra$List$Extra$remove, user, model.jw)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$TeamDetails$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ce: newQuery}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var user = msg.a;
				var team = model.jb;
				var users = A2(
					$elm$core$List$filter,
					function (u) {
						return !_Utils_eq(u.a$, user.a$);
					},
					team.jv);
				var updatedTeam = _Utils_update(
					team,
					{jv: users});
				var _v1 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v1.a;
				var newSeed = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cm: newSeed, jb: updatedTeam}),
					A4($author$project$Api$changeTeamMembers, model.jo, randomId, updatedTeam, $author$project$Page$TeamDetails$GotRemoveUserResponse));
			case 4:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{it: true}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var _v2 = A2($author$project$Ui$AddAndRemoveUsersFromTeam$init, model.jo, model.jb.jv);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aM: $elm$core$Maybe$Just(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$TeamDetails$GotUsersDialogMsg, c));
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ag: $elm$core$Maybe$Just(
								$author$project$Page$TeamDetails$initDialogSubteam(model))
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ag: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var teams = msg.a;
				var updatedTeam = A2($author$project$Domain$Team$setSubTeams, teams, model.jb);
				var _v3 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v3.a;
				var newSeed = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ag: $elm$core$Maybe$Nothing, cm: newSeed, jb: updatedTeam}),
					A4($author$project$Api$changeTeamMembers, model.jo, randomId, updatedTeam, $author$project$Page$TeamDetails$ChangeTeamMembersResponse));
			case 11:
				var ids = msg.a;
				var _v4 = model.ag;
				if (_v4.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var dialogModel = _v4.a;
					var selectedTeams = A2(
						$elm$core$List$filter,
						function (team) {
							return A2($elm$core$List$member, team.a$, ids);
						},
						dialogModel.au);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ag: $elm$core$Maybe$Just(
									_Utils_update(
										dialogModel,
										{bF: selectedTeams}))
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var subMsg = msg.a;
				var _v5 = model.aM;
				if (!_v5.$) {
					var subModel = _v5.a;
					var team = model.jb;
					var _v6 = A2($author$project$Ui$AddAndRemoveUsersFromTeam$update, subMsg, subModel);
					var m = _v6.a;
					var c = _v6.b;
					var _v7 = m.jx ? $author$project$RandomId$generateStringUuid(model.cm) : _Utils_Tuple2(
						'',
						$elm$random$Random$initialSeed(0));
					var randomId = _v7.a;
					var newSeed = _v7.b;
					var teamUpdated = _Utils_update(
						team,
						{jv: m.jw});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aM: (m.fs || m.jx) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(m),
								cm: m.jx ? newSeed : model.cm,
								jb: teamUpdated
							}),
						m.jx ? A4($author$project$Api$changeTeamMembers, model.jo, randomId, teamUpdated, $author$project$Page$TeamDetails$ChangeTeamMembersResponse) : A2($elm$core$Platform$Cmd$map, $author$project$Page$TeamDetails$GotUsersDialogMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Ui$CreateTeam$Error = 2;
var $author$project$Ui$CreateTeam$Loaded = 1;
var $author$project$Ui$CreateTeam$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fs: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jx: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var response = msg.a;
				if (!response.$) {
					var users = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bG: 1, jv: users}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bG: 2}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var newTeamName = msg.a;
				var toReturn = model.cs;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cs: _Utils_update(
								toReturn,
								{dN: newTeamName})
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var user = msg.a;
				var toReturn = model.cs;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cs: _Utils_update(
								toReturn,
								{
									jv: A2(
										$elm_community$list_extra$List$Extra$uniqueBy,
										function ($) {
											return $.a$;
										},
										A2(
											$elm$core$List$append,
											model.cs.jv,
											_List_fromArray(
												[user])))
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ce: newQuery}),
					$elm$core$Platform$Cmd$none);
			default:
				var user = msg.a;
				var toReturn = model.cs;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cs: _Utils_update(
								toReturn,
								{
									jv: A2($elm_community$list_extra$List$Extra$remove, user, model.cs.jv)
								})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Team$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var response = msg.a;
				if (!response.$) {
					var resp = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jc: resp}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var team = msg.a;
				var _v2 = A3($author$project$Page$TeamDetails$init, model.jt.jo, team, model.jc);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bi: $elm$core$Maybe$Just(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Team$GotUsersFromTeamResponse, c));
			case 2:
				var subMsg = msg.a;
				var _v3 = model.bi;
				if (!_v3.$) {
					var subModel = _v3.a;
					var _v4 = A2($author$project$Page$TeamDetails$update, subMsg, subModel);
					var m = _v4.a;
					var c = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bi: m.it ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Page$Team$GotUsersFromTeamResponse, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var newState = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cp: newState}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var team = msg.a;
				var _v5 = $author$project$RandomId$generateStringUuid(model.cm);
				var randomId = _v5.a;
				var newSeed = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: newSeed,
							jc: A2(
								$elm$core$List$filter,
								function (team_) {
									return !_Utils_eq(team.a$, team_.a$);
								},
								model.jc)
						}),
					A4($author$project$Api$removeTeam, model.jt.jo, randomId, team.a$, $author$project$Page$Team$GotRemoveTeamResponse));
			case 6:
				if (!msg.a.$) {
					if (msg.a.a) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var _v6 = $author$project$Ui$CreateTeam$init(model.jt.jo);
				var m = _v6.a;
				var c = _v6.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ae: $elm$core$Maybe$Just(m)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Team$GotCreateTeamMsg, c));
			case 8:
				var subMsg = msg.a;
				var _v7 = model.ae;
				if (_v7.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var subModel = _v7.a;
					var _v8 = A2($author$project$Ui$CreateTeam$update, subMsg, subModel);
					var m = _v8.a;
					var c = _v8.b;
					if (m.jx) {
						var newUsers = m.cs.jv;
						var _v9 = $author$project$RandomId$generateStringUuid(model.cm);
						var randomId = _v9.a;
						var newSeed = _v9.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ae: $elm$core$Maybe$Nothing,
									cm: newSeed,
									jc: _Utils_ap(
										model.jc,
										_List_fromArray(
											[
												{
												a$: randomId,
												g2: A2(
													$author$project$Domain$Lifecycle$new,
													model.cq,
													$author$project$LoggedIn$toAuthor(model.jt)),
												dL: m.cs.dN,
												cX: $author$project$Domain$Team$emptySubTeams,
												jp: $elm$core$List$length(newUsers),
												jv: newUsers
											}
											]))
								}),
							A6($author$project$Api$createTeam, model.jt.jo, randomId, randomId, m.cs.dN, m.cs.jv, $author$project$Page$Team$GotNewTeamResponse));
					} else {
						if (m.fs) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ae: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ae: $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Page$Team$GotCreateTeamMsg, c));
						}
					}
				}
			case 9:
				var newQuery = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ce: newQuery}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: time}),
					$elm$core$Platform$Cmd$none);
			default:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cm: $elm$random$Random$initialSeed(
								$elm$time$Time$posixToMillis(time))
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Test$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Ui$Layout$Main$GotUserMenuMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ui$UserMenu$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bN: true}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bN: false}),
					$elm$core$Platform$Cmd$none);
			default:
				var key = msg.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$Route$push, key, $author$project$Route$Logout));
		}
	});
var $author$project$Ui$Layout$Main$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var time = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cm: $elm$random$Random$initialSeed(
							$elm$time$Time$posixToMillis(time))
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var userMenuMsg = msg.a;
			var _v1 = A2($author$project$Ui$UserMenu$update, userMenuMsg, model.bM);
			var m = _v1.a;
			var c = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{bM: m}),
				A2($elm$core$Platform$Cmd$map, $author$project$Ui$Layout$Main$GotUserMenuMsg, c));
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var url = msg.a;
				return A2($author$project$Main$updateUrl, url, model);
			case 1:
				var req = msg.a;
				if (req.$ === 1) {
					var href = req.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				} else {
					var url = req.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.k,
							$elm$url$Url$toString(url)));
				}
			case 2:
				return _Utils_Tuple2(
					model,
					A2($author$project$Route$push, model.k, $author$project$Route$Login));
			case 3:
				var url = msg.a;
				var response = msg.b;
				if (response.$ === 3) {
					var openUserResult = response.a;
					var _v3 = model.jt;
					if (_v3.$ === 1) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var loggedInUser = _v3.a;
						return A2(
							$author$project$Main$updateUrl,
							url,
							_Utils_update(
								model,
								{
									jt: $elm$core$Maybe$Just(
										_Utils_update(
											loggedInUser,
											{
												f5: openUserResult.f5,
												gV: openUserResult.gV,
												gW: openUserResult.gW,
												jc: $krisajenkins$remotedata$RemoteData$Success(openUserResult.jc),
												jt: $krisajenkins$remotedata$RemoteData$Success(openUserResult.jt)
											}))
								}));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				var _v4 = model.d;
				if ((_v4.$ === 5) && (!_v4.b.$)) {
					var user = _v4.a;
					var pageModel = _v4.b.a;
					var _v5 = A2($author$project$Page$Library$update, subMsg, pageModel);
					var m = _v5.a;
					var c = _v5.b;
					var cart = _v5.c;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fb: cart,
								d: A2(
									$author$project$Main$LoggedIn,
									user,
									$author$project$Main$LibraryPage(m))
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLibraryPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var subMsg = msg.a;
				var _v6 = model.d;
				if ((_v6.$ === 5) && (_v6.b.$ === 1)) {
					var user = _v6.a;
					var pageModel = _v6.b.a;
					var _v7 = A2($author$project$Page$Admin$update, subMsg, pageModel);
					var m = _v7.a;
					var c = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Main$LoggedIn,
									user,
									$author$project$Main$AdminPage(m))
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotAdminPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var subMsg = msg.a;
				var _v8 = model.d;
				if ((_v8.$ === 5) && (_v8.b.$ === 2)) {
					var user = _v8.a;
					var pageModel = _v8.b.a;
					var _v9 = A2($author$project$Page$Test$update, subMsg, pageModel);
					var m = _v9.a;
					var c = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Main$LoggedIn,
									user,
									$author$project$Main$TestPage(m))
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTestPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var subMsg = msg.a;
				var _v10 = model.d;
				if (_v10.$ === 2) {
					var pageModel = _v10.a;
					var _v11 = A2($author$project$Page$Register$update, subMsg, pageModel);
					var m = _v11.a;
					var c = _v11.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Main$RegisterPage(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotRegisterPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				var subMsg = msg.a;
				var _v12 = model.d;
				if (_v12.$ === 4) {
					var pageModel = _v12.a;
					var _v13 = A2($author$project$Page$Confirm$update, subMsg, pageModel);
					var m = _v13.a;
					var c = _v13.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Main$ConfirmRegistrationPage(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotConfirmRegistrationPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var subMsg = msg.a;
				var _v14 = model.d;
				if (_v14.$ === 3) {
					var pageModel = _v14.a;
					var _v15 = A2($author$project$Page$ResetPassword$update, subMsg, pageModel);
					var m = _v15.a;
					var c = _v15.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$Main$ResetPasswordPage(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotResetPasswordPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var subMsg = msg.a;
				var _v16 = model.d;
				if (_v16.$ === 1) {
					var pageModel = _v16.a;
					var _v17 = A2($author$project$Page$Login$update, subMsg, pageModel);
					if (!_v17.$) {
						var _v18 = _v17.a;
						var m = _v18.a;
						var c = _v18.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: $author$project$Main$LoginPage(m)
								}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLoginPageMsg, c));
					} else {
						var user = _v17.a;
						var route = _v17.b;
						var encodedUser = A2(
							$elm$json$Json$Encode$encode,
							0,
							$author$project$LoggedIn$encodeForLocalStorage(user));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									jt: $elm$core$Maybe$Just(user)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$storeUser(encodedUser),
										A2($author$project$Route$push, model.k, route)
									])));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				var subMsg = msg.a;
				var _v19 = model.d;
				if ((_v19.$ === 5) && (_v19.b.$ === 3)) {
					var user = _v19.a;
					var subModel = _v19.b.a;
					var _v20 = A2($author$project$Page$Team$update, subMsg, subModel);
					var m = _v20.a;
					var c = _v20.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Main$LoggedIn,
									user,
									$author$project$Main$TeamPage(m))
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTeamPageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				var subMsg = msg.a;
				var _v21 = model.d;
				if ((_v21.$ === 5) && (_v21.b.$ === 4)) {
					var user = _v21.a;
					var subModel = _v21.b.a;
					var _v22 = A2($author$project$Page$Enterprise$update, subMsg, subModel);
					var m = _v22.a;
					var c = _v22.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: A2(
									$author$project$Main$LoggedIn,
									user,
									$author$project$Main$EnterprisePage(m))
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotEnterprisePageMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var layoutMsg = msg.a;
				var _v23 = model.o;
				if (_v23.$ === 1) {
					var layoutModel = _v23.a;
					var _v24 = A2($author$project$Ui$Layout$Main$update, layoutMsg, layoutModel);
					var m = _v24.a;
					var c = _v24.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								o: $author$project$Main$Main(m)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotMainLayoutMsg, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Page$Admin$EnterpriseAdminClicked = {$: 2};
var $author$project$Page$Admin$LogAdminClicked = {$: 1};
var $author$project$Page$Admin$UserAdminClicked = {$: 0};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Utils$icon = F2(
	function (attrs, name) {
		return A2(
			$elm$html$Html$i,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('material-icons align-middle'),
				attrs),
			_List_fromArray(
				[
					$elm$html$Html$text(name)
				]));
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Page$Admin$leftBarView = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col w-44 border bg-przred items-start pl-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row mt-8 items-start h-8 justify-center')
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = model.d;
							if (!_v0.$) {
								return A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-white font-bold mr-1 outline-none select-none')
										]),
									'arrow_right');
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white font-bold cursor-pointer'),
									$elm$html$Html$Events$onClick($author$project$Page$Admin$UserAdminClicked)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.jv)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row mt-8 items-start h-8 justify-center')
						]),
					_List_fromArray(
						[
							function () {
							var _v1 = model.d;
							if (_v1.$ === 1) {
								return A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-white font-bold mr-1 outline-none select-none')
										]),
									'arrow_right');
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white font-bold cursor-pointer'),
									$elm$html$Html$Events$onClick($author$project$Page$Admin$LogAdminClicked)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.g5)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row mt-8 items-start h-8 justify-center')
						]),
					_List_fromArray(
						[
							function () {
							var _v2 = model.d;
							if (_v2.$ === 2) {
								return A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-white font-bold mr-1 outline-none select-none')
										]),
									'arrow_right');
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white font-bold cursor-pointer'),
									$elm$html$Html$Events$onClick($author$project$Page$Admin$EnterpriseAdminClicked)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.f6)
								]))
						]))
				]));
	});
var $author$project$Page$EnterpriseAdmin$CreateEnterpriseClicked = {$: 3};
var $author$project$Page$EnterpriseAdmin$SetTableState = function (a) {
	return {$: 2, a: a};
};
var $billstclair$elm_sortable_table$Table$Config = $elm$core$Basics$identity;
var $billstclair$elm_sortable_table$Table$customConfig = function (_v0) {
	var toId = _v0.jm;
	var toMsg = _v0.jn;
	var columns = _v0.fg;
	var customizations = _v0.fy;
	return {
		fg: A2(
			$elm$core$List$map,
			function (_v1) {
				var cData = _v1;
				return cData;
			},
			columns),
		fy: customizations,
		jm: toId,
		jn: toMsg
	};
};
var $billstclair$elm_sortable_table$Table$simpleRowAttrs = function (_v0) {
	return _List_Nil;
};
var $billstclair$elm_sortable_table$Table$HtmlDetails = F2(
	function (attributes, children) {
		return {eV: attributes, bo: children};
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $billstclair$elm_sortable_table$Table$nbsp = $elm$core$String$fromList(
	_List_fromArray(
		[
			$elm$core$Char$fromCode(160)
		]));
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $billstclair$elm_sortable_table$Table$darkGrey = function (symbol) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', '#555')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				_Utils_ap($billstclair$elm_sortable_table$Table$nbsp, symbol))
			]));
};
var $billstclair$elm_sortable_table$Table$lightGrey = function (symbol) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', '#ccc')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				_Utils_ap($billstclair$elm_sortable_table$Table$nbsp, symbol))
			]));
};
var $elm$html$Html$th = _VirtualDom_node('th');
var $billstclair$elm_sortable_table$Table$simpleTheadHelp = function (_v0) {
	var name = _v0.a;
	var status = _v0.b;
	var click = _v0.c;
	var content = function () {
		switch (status.$) {
			case 0:
				return _List_fromArray(
					[
						$elm$html$Html$text(name)
					]);
			case 1:
				var selected = status.a;
				return _List_fromArray(
					[
						$elm$html$Html$text(name),
						selected ? $billstclair$elm_sortable_table$Table$darkGrey('↓') : $billstclair$elm_sortable_table$Table$lightGrey('↓')
					]);
			default:
				if (status.a.$ === 1) {
					var _v2 = status.a;
					return _List_fromArray(
						[
							$elm$html$Html$text(name),
							$billstclair$elm_sortable_table$Table$lightGrey('↕')
						]);
				} else {
					var isReversed = status.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$text(name),
							$billstclair$elm_sortable_table$Table$darkGrey(
							isReversed ? '↑' : '↓')
						]);
				}
		}
	}();
	return A2(
		$elm$html$Html$th,
		_List_fromArray(
			[click]),
		content);
};
var $billstclair$elm_sortable_table$Table$simpleThead = function (headers) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		A2($elm$core$List$map, $billstclair$elm_sortable_table$Table$simpleTheadHelp, headers));
};
var $billstclair$elm_sortable_table$Table$defaultCustomizations = {cv: $elm$core$Maybe$Nothing, cR: $billstclair$elm_sortable_table$Table$simpleRowAttrs, i5: _List_Nil, cZ: _List_Nil, c$: $elm$core$Maybe$Nothing, c0: $billstclair$elm_sortable_table$Table$simpleThead};
var $author$project$Utils$przTableCustomizations = function () {
	var defaultCustomizations = $billstclair$elm_sortable_table$Table$defaultCustomizations;
	return _Utils_update(
		defaultCustomizations,
		{
			i5: _List_fromArray(
				[
					$elm$html$Html$Attributes$class('tablePrzWhite')
				])
		});
}();
var $billstclair$elm_sortable_table$Table$Column = $elm$core$Basics$identity;
var $billstclair$elm_sortable_table$Table$IncOrDec = function (a) {
	return {$: 3, a: a};
};
var $billstclair$elm_sortable_table$Table$increasingOrDecreasingBy = function (toComparable) {
	return $billstclair$elm_sortable_table$Table$IncOrDec(
		$elm$core$List$sortBy(toComparable));
};
var $billstclair$elm_sortable_table$Table$textDetails = function (str) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $billstclair$elm_sortable_table$Table$stringColumn = F2(
	function (name, toStr) {
		return {
			dL: name,
			ee: $billstclair$elm_sortable_table$Table$increasingOrDecreasingBy(toStr),
			en: A2($elm$core$Basics$composeL, $billstclair$elm_sortable_table$Table$textDetails, toStr)
		};
	});
var $elm$html$Html$caption = _VirtualDom_node('caption');
var $billstclair$elm_sortable_table$Table$applySorter = F3(
	function (isReversed, sorter, data) {
		switch (sorter.$) {
			case 0:
				return data;
			case 1:
				var srt = sorter.a;
				return srt(data);
			case 2:
				var srt = sorter.a;
				return $elm$core$List$reverse(
					srt(data));
			case 3:
				var srt = sorter.a;
				return isReversed ? $elm$core$List$reverse(
					srt(data)) : srt(data);
			default:
				var srt = sorter.a;
				return isReversed ? srt(data) : $elm$core$List$reverse(
					srt(data));
		}
	});
var $billstclair$elm_sortable_table$Table$findSorter = F2(
	function (selectedColumn, columnData) {
		findSorter:
		while (true) {
			if (!columnData.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var name = columnData.a.dL;
				var sorter = columnData.a.ee;
				var remainingColumnData = columnData.b;
				if (_Utils_eq(name, selectedColumn)) {
					return $elm$core$Maybe$Just(sorter);
				} else {
					var $temp$selectedColumn = selectedColumn,
						$temp$columnData = remainingColumnData;
					selectedColumn = $temp$selectedColumn;
					columnData = $temp$columnData;
					continue findSorter;
				}
			}
		}
	});
var $billstclair$elm_sortable_table$Table$sort = F3(
	function (_v0, columnData, data) {
		var selectedColumn = _v0.a;
		var isReversed = _v0.b;
		var _v1 = A2($billstclair$elm_sortable_table$Table$findSorter, selectedColumn, columnData);
		if (_v1.$ === 1) {
			return data;
		} else {
			var sorter = _v1.a;
			return A3($billstclair$elm_sortable_table$Table$applySorter, isReversed, sorter, data);
		}
	});
var $billstclair$elm_sortable_table$Table$getSortedData = F3(
	function (_v0, state, data) {
		var toId = _v0.jm;
		var toMsg = _v0.jn;
		var columns = _v0.fg;
		var customizations = _v0.fy;
		return A3($billstclair$elm_sortable_table$Table$sort, state, columns, data);
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tfoot = _VirtualDom_node('tfoot');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $billstclair$elm_sortable_table$Table$Reversible = function (a) {
	return {$: 2, a: a};
};
var $billstclair$elm_sortable_table$Table$Sortable = function (a) {
	return {$: 1, a: a};
};
var $billstclair$elm_sortable_table$Table$Unsortable = {$: 0};
var $billstclair$elm_sortable_table$Table$onClick = F3(
	function (name, isReversed, toMsg) {
		return A2(
			$elm$html$Html$Events$on,
			'click',
			A2(
				$elm$json$Json$Decode$map,
				toMsg,
				A3(
					$elm$json$Json$Decode$map2,
					$billstclair$elm_sortable_table$Table$State,
					$elm$json$Json$Decode$succeed(name),
					$elm$json$Json$Decode$succeed(isReversed))));
	});
var $billstclair$elm_sortable_table$Table$toHeaderInfo = F3(
	function (_v0, toMsg, _v1) {
		var sortName = _v0.a;
		var isReversed = _v0.b;
		var name = _v1.dL;
		var sorter = _v1.ee;
		switch (sorter.$) {
			case 0:
				return _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Unsortable,
					A3($billstclair$elm_sortable_table$Table$onClick, sortName, isReversed, toMsg));
			case 1:
				return _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Sortable(
						!_Utils_eq(name, sortName)),
					A3($billstclair$elm_sortable_table$Table$onClick, name, false, toMsg));
			case 2:
				return _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Sortable(
						_Utils_eq(name, sortName)),
					A3($billstclair$elm_sortable_table$Table$onClick, name, false, toMsg));
			case 3:
				return _Utils_eq(name, sortName) ? _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Reversible(
						$elm$core$Maybe$Just(!isReversed)),
					A3($billstclair$elm_sortable_table$Table$onClick, name, !isReversed, toMsg)) : _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Reversible($elm$core$Maybe$Nothing),
					A3($billstclair$elm_sortable_table$Table$onClick, name, false, toMsg));
			default:
				return _Utils_eq(name, sortName) ? _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Reversible(
						$elm$core$Maybe$Just(isReversed)),
					A3($billstclair$elm_sortable_table$Table$onClick, name, !isReversed, toMsg)) : _Utils_Tuple3(
					name,
					$billstclair$elm_sortable_table$Table$Reversible($elm$core$Maybe$Nothing),
					A3($billstclair$elm_sortable_table$Table$onClick, name, false, toMsg));
		}
	});
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $elm$html$Html$td = _VirtualDom_node('td');
var $billstclair$elm_sortable_table$Table$viewCell = F2(
	function (data, _v0) {
		var viewData = _v0.en;
		var details = viewData(data);
		return A2($elm$html$Html$td, details.eV, details.bo);
	});
var $billstclair$elm_sortable_table$Table$viewRowHelp = F3(
	function (columns, toRowAttrs, data) {
		return A2(
			$elm$html$Html$tr,
			toRowAttrs(data),
			A2(
				$elm$core$List$map,
				$billstclair$elm_sortable_table$Table$viewCell(data),
				columns));
	});
var $billstclair$elm_sortable_table$Table$viewRow = F4(
	function (toId, columns, toRowAttrs, data) {
		return _Utils_Tuple2(
			toId(data),
			A4($elm$html$Html$Lazy$lazy3, $billstclair$elm_sortable_table$Table$viewRowHelp, columns, toRowAttrs, data));
	});
var $billstclair$elm_sortable_table$Table$view = F3(
	function (conf, state, data) {
		var toId = conf.jm;
		var toMsg = conf.jn;
		var columns = conf.fg;
		var customizations = conf.fy;
		var theadDetails = customizations.c0(
			A2(
				$elm$core$List$map,
				A2($billstclair$elm_sortable_table$Table$toHeaderInfo, state, toMsg),
				columns));
		var thead = A2(
			$elm$html$Html$thead,
			theadDetails.eV,
			_List_fromArray(
				[
					A2($elm$html$Html$tr, _List_Nil, theadDetails.bo)
				]));
		var sortedData = A3($billstclair$elm_sortable_table$Table$getSortedData, conf, state, data);
		var tbody = A3(
			$elm$html$Html$Keyed$node,
			'tbody',
			customizations.cZ,
			A2(
				$elm$core$List$map,
				A3($billstclair$elm_sortable_table$Table$viewRow, toId, columns, customizations.cR),
				sortedData));
		var withFoot = function () {
			var _v1 = customizations.c$;
			if (_v1.$ === 1) {
				return A2($elm$core$List$cons, tbody, _List_Nil);
			} else {
				var attributes = _v1.a.eV;
				var children = _v1.a.bo;
				return A2(
					$elm$core$List$cons,
					A2($elm$html$Html$tfoot, attributes, children),
					A2($elm$core$List$cons, tbody, _List_Nil));
			}
		}();
		return A2(
			$elm$html$Html$table,
			customizations.i5,
			function () {
				var _v0 = customizations.cv;
				if (_v0.$ === 1) {
					return A2($elm$core$List$cons, thead, withFoot);
				} else {
					var attributes = _v0.a.eV;
					var children = _v0.a.bo;
					return A2(
						$elm$core$List$cons,
						A2($elm$html$Html$caption, attributes, children),
						A2($elm$core$List$cons, thead, withFoot));
				}
			}());
	});
var $author$project$Ui$CreateEnterprise$CrossClicked = {$: 0};
var $author$project$Ui$CreateEnterprise$SetManager = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$CreateEnterprise$SetName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$CreateEnterprise$ValidButtonClicked = {$: 1};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Ui$CreateEnterprise$view = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-full h-full flex items-center justify-center top-0 left-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-full h-full bg-gray-800 opacity-50'),
							$elm$html$Html$Events$onClick($author$project$Ui$CreateEnterprise$CrossClicked)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('h-3/6 w-3/12 z-10')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start border shadow bg-white p-4 ')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex w-full h-1/6 flex-col border-b border-gray-200')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex w-full justify-end h-1/2')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Utils$icon,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('cursor-pointer'),
															$elm$html$Html$Events$onClick($author$project$Ui$CreateEnterprise$CrossClicked)
														]),
													'close')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('h-1/2 flex w-full items-center font-bold justify-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.ez)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-full h-2/6 flex flex-col items-center justify-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold w-1/2 flex justify-start mb-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.f9 + ':')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput($author$project$Ui$CreateEnterprise$SetName),
													$elm$html$Html$Attributes$value(model.jd.dL),
													$elm$html$Html$Attributes$class(' ')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-full h-2/6 flex flex-col items-center justify-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold w-1/2 flex justify-start mb-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.f8 + ':')
												])),
											A2(
											$elm$html$Html$select,
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput($author$project$Ui$CreateEnterprise$SetManager)
												]),
											A2(
												$elm$core$List$cons,
												A2(
													$elm$html$Html$option,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('--')
														])),
												A2(
													$elm$core$List$map,
													function (u) {
														return A2(
															$elm$html$Html$option,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$value(u.a$)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Domain$User$fullName(u) + (' (' + (u.dr + ')')))
																]));
													},
													model.jv)))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Ui$CreateEnterprise$ValidButtonClicked),
											$elm$html$Html$Attributes$class('h-1/6 w-full flex justify-center items-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold flex items-center justify-center border bg-przred rounded-lg text-white cursor-pointer px-2 py-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.jy)
												]))
										]))
								]))
						]))
				]));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Ui$Loading$view = function (text) {
	return A2(
		$elm$html$Html$p,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('animate-spin align-top mr-1')
					]),
				'autorenew'),
				$elm$html$Html$text(text)
			]));
};
var $author$project$Page$EnterpriseAdmin$view = F2(
	function (locale, model) {
		var _v0 = _Utils_Tuple2(model.jv, model.aU);
		_v0$1:
		while (true) {
			_v0$2:
			while (true) {
				_v0$3:
				while (true) {
					_v0$4:
					while (true) {
						switch (_v0.a.$) {
							case 1:
								var _v1 = _v0.a;
								return $author$project$Ui$Loading$view(locale.g4);
							case 2:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$2;
									case 0:
										break _v0$2;
									default:
										break _v0$2;
								}
							case 0:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$3;
									case 0:
										break _v0$4;
									default:
										break _v0$4;
								}
							default:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$3;
									case 0:
										var _v4 = _v0.b;
										return $elm$html$Html$text('');
									default:
										var enterprises = _v0.b.a;
										var config = $billstclair$elm_sortable_table$Table$customConfig(
											{
												fg: _List_fromArray(
													[
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'Name',
														function ($) {
															return $.dL;
														}),
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'Members',
														function (e) {
															return A2($elm$core$Basics$composeR, $elm$core$List$length, $elm$core$String$fromInt)(e.hm);
														}),
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'Managers',
														function (e) {
															return A2($elm$core$Basics$composeR, $elm$core$List$length, $elm$core$String$fromInt)(e.dJ);
														})
													]),
												fy: $author$project$Utils$przTableCustomizations,
												jm: function ($) {
													return $.a$;
												},
												jn: $author$project$Page$EnterpriseAdmin$SetTableState
											});
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full pl-8')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Events$onClick($author$project$Page$EnterpriseAdmin$CreateEnterpriseClicked),
															$elm$html$Html$Attributes$class('bg-przred px-2 font-bold text-white cursor-pointer outline-none select-none ')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.hy)
														])),
													A3($billstclair$elm_sortable_table$Table$view, config, model.cp, enterprises),
													function () {
													var _v5 = model.ad;
													if (!_v5.$) {
														var formModel = _v5.a;
														return A2(
															$elm$html$Html$map,
															$author$project$Page$EnterpriseAdmin$GotCreateEnterpriseMsg,
															A2($author$project$Ui$CreateEnterprise$view, locale, formModel));
													} else {
														return $elm$html$Html$text('');
													}
												}()
												]));
								}
						}
					}
					var _v3 = _v0.a;
					return $elm$html$Html$text('');
				}
				return $elm$html$Html$text('fail to load logs');
			}
			return $elm$html$Html$text('fail to load users');
		}
		var _v2 = _v0.b;
		return $author$project$Ui$Loading$view(locale.g4);
	});
var $author$project$Page$LogAdmin$SelectUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$LogAdmin$SetTableState = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$LogAdmin$filterClass = 'w-full mb-3 rounded-sm border border-gray-300 h-8 p-1 outline-none';
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cW, posixMinutes) < 0) {
					return posixMinutes + era.dP;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		dk: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		dK: month,
		es: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dk;
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dK;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $author$project$Ui$Date$toNumericMonth = function (month) {
	switch (month) {
		case 0:
			return '01';
		case 1:
			return '02';
		case 2:
			return '03';
		case 3:
			return '04';
		case 4:
			return '05';
		case 5:
			return '06';
		case 6:
			return '07';
		case 7:
			return '08';
		case 8:
			return '09';
		case 9:
			return '10';
		case 10:
			return '11';
		default:
			return '12';
	}
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).es;
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Ui$Date$toIso8601 = function (posix) {
	var zone = $elm$time$Time$utc;
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, zone, posix)) + ('-' + ($author$project$Ui$Date$toNumericMonth(
		A2($elm$time$Time$toMonth, zone, posix)) + ('-' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, posix))) + ('T' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toHour, zone, posix))) + (':' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, zone, posix))) + (':' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toSecond, zone, posix))) + 'Z'))))))))));
};
var $elm$regex$Regex$never = _Regex_never;
var $elm_community$string_extra$String$Extra$regexFromString = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$fromString,
	$elm$core$Maybe$withDefault($elm$regex$Regex$never));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var head = _v0.a;
					var tail = _v0.b;
					return A2(
						$elm$core$String$cons,
						mutator(head),
						tail);
				},
				$elm$core$String$uncons(word)));
	});
var $elm$core$Char$toUpper = _Char_toUpper;
var $elm_community$string_extra$String$Extra$toSentenceCase = function (word) {
	return A2($elm_community$string_extra$String$Extra$changeCase, $elm$core$Char$toUpper, word);
};
var $elm_community$string_extra$String$Extra$toTitleCase = function (ws) {
	var uppercaseMatch = A2(
		$elm$regex$Regex$replace,
		$elm_community$string_extra$String$Extra$regexFromString('\\w+'),
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.hk;
			},
			$elm_community$string_extra$String$Extra$toSentenceCase));
	return A3(
		$elm$regex$Regex$replace,
		$elm_community$string_extra$String$Extra$regexFromString('^([a-z])|\\s+([a-z])'),
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.hk;
			},
			uppercaseMatch),
		ws);
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Page$LogAdmin$view = F2(
	function (locale, model) {
		var _v0 = _Utils_Tuple2(model.jv, model.b7);
		_v0$1:
		while (true) {
			_v0$2:
			while (true) {
				_v0$3:
				while (true) {
					_v0$4:
					while (true) {
						switch (_v0.a.$) {
							case 1:
								var _v1 = _v0.a;
								return $author$project$Ui$Loading$view(locale.g4);
							case 2:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$2;
									case 0:
										break _v0$2;
									default:
										break _v0$2;
								}
							case 0:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$3;
									case 0:
										break _v0$4;
									default:
										break _v0$4;
								}
							default:
								switch (_v0.b.$) {
									case 1:
										break _v0$1;
									case 2:
										break _v0$3;
									case 0:
										var _v4 = _v0.b;
										return $elm$html$Html$text('');
									default:
										var users = _v0.a.a;
										var logs = _v0.b.a;
										var filteredLogs = function () {
											var _v5 = model.b1;
											if (_v5.$ === 1) {
												return logs;
											} else {
												var userId = _v5.a;
												return A2(
													$elm$core$List$filter,
													function (u) {
														return _Utils_eq(u.ju, userId);
													},
													logs);
											}
										}();
										var config = $billstclair$elm_sortable_table$Table$customConfig(
											{
												fg: _List_fromArray(
													[
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'User',
														function (log) {
															return $elm_community$string_extra$String$Extra$toTitleCase(
																A2(
																	$elm$core$Maybe$withDefault,
																	'Inconnu',
																	A2(
																		$elm$core$Maybe$map,
																		$author$project$Domain$User$fullName,
																		A2($elm$core$Dict$get, log.ju, users))));
														}),
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'Date',
														function (log) {
															return $author$project$Ui$Date$toIso8601(log.gQ);
														}),
														A2(
														$billstclair$elm_sortable_table$Table$stringColumn,
														'Event',
														function ($) {
															return $.gg;
														})
													]),
												fy: $author$project$Utils$przTableCustomizations,
												jm: function (log) {
													return $elm$core$String$fromInt(
														$elm$time$Time$posixToMillis(log.gQ));
												},
												jn: $author$project$Page$LogAdmin$SetTableState
											});
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-full pl-8')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$select,
													_List_fromArray(
														[
															$elm$html$Html$Events$onInput($author$project$Page$LogAdmin$SelectUser),
															$elm$html$Html$Attributes$class($author$project$Page$LogAdmin$filterClass)
														]),
													A2(
														$elm$core$List$cons,
														A2(
															$elm$html$Html$option,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$value('')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('-- Filter by user --')
																])),
														A2(
															$elm$core$List$map,
															function (u) {
																return A2(
																	$elm$html$Html$option,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$value(u.a$)
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(
																			$author$project$Domain$User$fullName(u) + (' (' + (u.dr + ')')))
																		]));
															},
															$elm$core$Dict$values(users)))),
													A3($billstclair$elm_sortable_table$Table$view, config, model.cp, filteredLogs)
												]));
								}
						}
					}
					var _v3 = _v0.a;
					return $elm$html$Html$text('');
				}
				return $elm$html$Html$text('fail to load logs');
			}
			return $elm$html$Html$text('fail to load users');
		}
		var _v2 = _v0.b;
		return $author$project$Ui$Loading$view(locale.g4);
	});
var $author$project$Page$UserAdmin$CreateUserClicked = {$: 3};
var $author$project$Page$UserAdmin$SetQuery = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$UserAdmin$SetTableState = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Page$UserAdmin$RemoveUserClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$UserAdmin$removeColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Page$UserAdmin$RemoveUserClicked(user)),
						$elm$html$Html$Attributes$class('cursor-pointer select-none text-gray-300')
					]),
				'delete')
			]));
};
var $billstclair$elm_sortable_table$Table$None = {$: 0};
var $billstclair$elm_sortable_table$Table$unsortable = $billstclair$elm_sortable_table$Table$None;
var $billstclair$elm_sortable_table$Table$veryCustomColumn = $elm$core$Basics$identity;
var $author$project$Page$UserAdmin$removeColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Page$UserAdmin$removeColumnView});
var $elm_community$maybe_extra$Maybe$Extra$cons = F2(
	function (item, list) {
		if (!item.$) {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		} else {
			return list;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$cons, _List_Nil);
var $author$project$Ui$CreateUser$CrossClicked = {$: 0};
var $author$project$Ui$CreateUser$SetEmail = function (a) {
	return {$: 4, a: a};
};
var $author$project$Ui$CreateUser$SetFirstname = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$CreateUser$SetLastname = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$CreateUser$ValidButtonClicked = {$: 1};
var $author$project$Ui$CreateUser$view = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-full h-full flex items-center justify-center top-0 left-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-full h-full bg-gray-800 opacity-50'),
							$elm$html$Html$Events$onClick($author$project$Ui$CreateUser$CrossClicked)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('h-3/6 w-3/12 z-10')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start border shadow bg-white p-4 ')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex w-full h-1/6 flex-col border-b border-gray-200')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex w-full justify-end h-1/2')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Utils$icon,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('cursor-pointer'),
															$elm$html$Html$Events$onClick($author$project$Ui$CreateUser$CrossClicked)
														]),
													'close')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('h-1/2 flex w-full items-center font-bold justify-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.ez)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-full h-2/6 flex flex-col items-center justify-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold w-1/2 flex justify-start mb-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.cA + ':')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput($author$project$Ui$CreateUser$SetFirstname),
													$elm$html$Html$Attributes$value(model.hB.cA),
													$elm$html$Html$Attributes$class(' ')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-full h-2/6 flex flex-col items-center justify-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold w-1/2 flex justify-start mb-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.cI + ':')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput($author$project$Ui$CreateUser$SetLastname),
													$elm$html$Html$Attributes$value(model.hB.cI),
													$elm$html$Html$Attributes$class('pl-1 w-1/2  border-b border-black outline-none ')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-full h-2/6 flex flex-col items-center justify-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold w-1/2 flex justify-start mb-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.dr + ':')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput($author$project$Ui$CreateUser$SetEmail),
													$elm$html$Html$Attributes$class('w-1/2 flex pl-1 border-b border-black outline-none')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Ui$CreateUser$ValidButtonClicked),
											$elm$html$Html$Attributes$class('h-1/6 w-full flex justify-center items-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('font-bold flex items-center justify-center border bg-przred rounded-lg text-white cursor-pointer px-2 py-1')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.jy)
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$UserAdmin$view = F2(
	function (locale, model) {
		var _v0 = model.jv;
		switch (_v0.$) {
			case 1:
				return $author$project$Ui$Loading$view(locale.g4);
			case 0:
				return $author$project$Ui$Loading$view(locale.g4);
			case 2:
				return $elm$html$Html$text(locale.ge);
			default:
				var users = _v0.a;
				var validUsers = $elm_community$maybe_extra$Maybe$Extra$values(
					A2(
						$elm$core$List$map,
						function (user) {
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(model.ce),
								$elm$core$String$toLower(user.cA)) ? $elm$core$Maybe$Just(user) : $elm$core$Maybe$Nothing;
						},
						users));
				var config = $billstclair$elm_sortable_table$Table$customConfig(
					{
						fg: _List_fromArray(
							[
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Firstname',
								function ($) {
									return $.cA;
								}),
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Lastname',
								function ($) {
									return $.cI;
								}),
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Email',
								function ($) {
									return $.dr;
								}),
								$author$project$Page$UserAdmin$removeColumn
							]),
						fy: $author$project$Utils$przTableCustomizations,
						jm: function ($) {
							return $.a$;
						},
						jn: $author$project$Page$UserAdmin$SetTableState
					});
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full pl-8 flex flex-col items-start')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-bold mt-8 mb-4')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.jv)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row w-full pr-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder(locale.iB),
											$elm$html$Html$Attributes$class('w-3/12 border shadow outline-none'),
											$elm$html$Html$Events$onInput($author$project$Page$UserAdmin$SetQuery)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-9/12 flex justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Page$UserAdmin$CreateUserClicked),
													$elm$html$Html$Attributes$class('bg-przred px-2 font-bold text-white cursor-pointer outline-none select-none ')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.hB)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full pr-8 mt-4')
								]),
							_List_fromArray(
								[
									A3($billstclair$elm_sortable_table$Table$view, config, model.cp, validUsers)
								])),
							function () {
							var _v1 = model.af;
							if (!_v1.$) {
								var formModel = _v1.a;
								return A2(
									$elm$html$Html$map,
									$author$project$Page$UserAdmin$GotCreateUserMsg,
									A2($author$project$Ui$CreateUser$view, locale, formModel));
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]));
		}
	});
var $author$project$Page$Admin$view = F3(
	function (locale, _v0, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex h-full w-full flex-row')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Admin$leftBarView, locale, model),
					function () {
					var _v1 = model.d;
					switch (_v1.$) {
						case 0:
							var m = _v1.a;
							return A2(
								$elm$html$Html$map,
								$author$project$Page$Admin$GotUserAdminMsg,
								A2($author$project$Page$UserAdmin$view, locale, m));
						case 2:
							var m = _v1.a;
							return A2(
								$elm$html$Html$map,
								$author$project$Page$Admin$GotEnterpriseAdminMsg,
								A2($author$project$Page$EnterpriseAdmin$view, locale, m));
						default:
							var m = _v1.a;
							return A2(
								$elm$html$Html$map,
								$author$project$Page$Admin$GotLogAdminMsg,
								A2($author$project$Page$LogAdmin$view, locale, m));
					}
				}()
				]));
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Assets$srcPrezanceLogoBlack = function (assets) {
	return $elm$html$Html$Attributes$src(assets.dV);
};
var $author$project$Page$Confirm$view = F3(
	function (locale, assets, model) {
		var message = function () {
			var _v0 = model.bG;
			switch (_v0) {
				case 0:
					return _List_fromArray(
						[
							$elm$html$Html$text(locale.fk)
						]);
				case 1:
					return _List_fromArray(
						[
							$elm$html$Html$text(locale.fl + '\n'),
							$elm$html$Html$text(locale.ih)
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text(locale.fj)
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center items-center h-screen w-screen bg-przlightgray p-64')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col flex-none justify-center py-5 px-8 bg-white shadow rounded-lg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$author$project$Assets$srcPrezanceLogoBlack(assets),
									$elm$html$Html$Attributes$class('w-64 m-auto')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-2xl font-bold mt-1 mb-3')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.fm)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-md')
										]),
									message)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pb-8 mt-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.e3)
										]))
								]))
						]))
				]));
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Page$Enterprise$InviteFormSetEmail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Enterprise$InviteFormSubmitted = {$: 2};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Page$Enterprise$viewInviteForm = function (locale) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(locale.gS),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('email'),
								$elm$html$Html$Events$onInput($author$project$Page$Enterprise$InviteFormSetEmail)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$Enterprise$InviteFormSubmitted),
						$elm$html$Html$Attributes$class('bg-przred px-2 font-bold text-white cursor-pointer outline-none select-none ')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.gR)
					]))
			]));
};
var $author$project$Page$Enterprise$SetTableState = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Enterprise$viewTable = F4(
	function (locale, tableState, users, enterprise) {
		var managersIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.a$;
			},
			enterprise.dJ);
		var config = $billstclair$elm_sortable_table$Table$customConfig(
			{
				fg: _List_fromArray(
					[
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						locale.cA,
						function ($) {
							return $.cA;
						}),
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						locale.cI,
						function ($) {
							return $.cI;
						}),
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						locale.dr,
						function ($) {
							return $.dr;
						}),
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						locale.f7,
						function (u) {
							return A2($elm$core$List$member, u.a$, managersIds) ? locale.jN : locale.hC;
						})
					]),
				fy: $author$project$Utils$przTableCustomizations,
				jm: function ($) {
					return $.a$;
				},
				jn: $author$project$Page$Enterprise$SetTableState
			});
		return A3($billstclair$elm_sortable_table$Table$view, config, tableState, users);
	});
var $author$project$Page$Enterprise$view = F2(
	function (locale, model) {
		var _v0 = model.jv;
		switch (_v0.$) {
			case 1:
				return $author$project$Ui$Loading$view(locale.g4);
			case 2:
				return $elm$html$Html$text('fail to load users');
			case 0:
				return $elm$html$Html$text('');
			default:
				var users = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.gU)
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.gT)
										]))
								])),
							$author$project$Page$Enterprise$viewInviteForm(locale),
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							A4($author$project$Page$Enterprise$viewTable, locale, model.cp, users, model.f5)
						]));
		}
	});
var $author$project$Ui$SearchAttrsBar$Config = $elm$core$Basics$identity;
var $author$project$Ui$SearchAttrsBar$customConfig = function (_v0) {
	var toMsg = _v0.jn;
	return {jn: toMsg};
};
var $author$project$Page$Library$configSearchAttrsBar = $author$project$Ui$SearchAttrsBar$customConfig(
	{jn: $author$project$Page$Library$SearchBarChangeState});
var $elm$core$String$foldl = _String_foldl;
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$isJust = function (maybeThing) {
	if (!maybeThing.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$String$filter = _String_filter;
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root = function (string) {
	return A2(
		$elm$core$String$filter,
		function (c) {
			return $elm$core$Char$isLower(c) || $elm$core$Char$isDigit(c);
		},
		$elm$core$String$toLower(string));
};
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$searchHelper = F2(
	function (letter, restOfWord) {
		if (!restOfWord.$) {
			var string = restOfWord.a;
			var _v1 = A2(
				$elm$core$String$indexes,
				$elm$core$String$fromChar(letter),
				string);
			if (!_v1.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var found = _v1.a;
				return $elm$core$Maybe$Just(
					A2($elm$core$String$dropLeft, found + 1, string));
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match = F2(
	function (needle, haystack) {
		return $NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$isJust(
			A3(
				$elm$core$String$foldl,
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$searchHelper,
				$elm$core$Maybe$Just(
					$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root(haystack)),
				$NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$root(needle)));
	});
var $author$project$Page$Library$filterElements = F3(
	function (filter, searchTerm, elements) {
		var matchName = function (name) {
			return A2($NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match, searchTerm, name);
		};
		var matchAttrs = function (_v0) {
			var purpose = _v0.ib;
			var entity = _v0.gb;
			return ($elm$core$List$isEmpty(filter.ic) || A2(
				$elm$core$List$member,
				purpose,
				A2($elm$core$List$map, $elm$core$Tuple$second, filter.ic))) && ($elm$core$List$isEmpty(filter.ga) || A2(
				$elm$core$List$member,
				entity,
				A2($elm$core$List$map, $elm$core$Tuple$second, filter.ga)));
		};
		return A2(
			$elm$core$List$filter,
			function (e) {
				return matchName(e.eW.dL);
			},
			A2(
				$elm$core$List$filter,
				function (e) {
					return matchAttrs(e.eW);
				},
				elements));
	});
var $author$project$Ui$SearchAttrsBar$getFilterAndSearchTerm = function (_v0) {
	var state = _v0;
	return _Utils_Tuple2(state.bs, state.cS);
};
var $author$project$Route$href = function (route) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$toString(route));
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $author$project$Domain$ElementForLibrary$toAvailableAttributes = function (elements) {
	var recordWithSets = A3(
		$elm$core$List$foldl,
		F2(
			function (element, acc) {
				var purposes = acc.ic;
				var entities = acc.ga;
				return _Utils_update(
					acc,
					{
						ga: A2($elm$core$Set$insert, element.eW.gb, entities),
						ic: A2($elm$core$Set$insert, element.eW.ib, purposes)
					});
			}),
		{ga: $elm$core$Set$empty, ic: $elm$core$Set$empty},
		elements);
	return {
		ga: $elm$core$Set$toList(recordWithSets.ga),
		ic: $elm$core$Set$toList(recordWithSets.ic)
	};
};
var $author$project$Ui$Dropdown$Config = $elm$core$Basics$identity;
var $author$project$Ui$Dropdown$customConfig = function (_v0) {
	var title = _v0.jl;
	var toMsg = _v0.jn;
	return {jl: title, jn: toMsg};
};
var $author$project$Ui$SearchAttrsBar$configEntitiesDropdown = function (toMsg) {
	return $author$project$Ui$Dropdown$customConfig(
		{jl: 'Entities', jn: toMsg});
};
var $author$project$Ui$SearchAttrsBar$configPurposesDropdown = function (toMsg) {
	return $author$project$Ui$Dropdown$customConfig(
		{jl: 'Purposes', jn: toMsg});
};
var $author$project$Ui$Dropdown$getChosenItems = function (_v0) {
	var state = _v0;
	return state.ac;
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Ui$Dropdown$onClick_ = function (m) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{hn: m, h8: true, i_: true}));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Ui$Dropdown$view = F4(
	function (_v0, _v1, _v2, items) {
		var config = _v1;
		var state = _v2;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dropdown')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '85%'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropbtn'),
									A2($elm$html$Html$Attributes$style, 'width', '160px'),
									$author$project$Ui$Dropdown$onClick_(
									config.jn(
										_Utils_update(
											state,
											{aS: !state.aS})))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(config.jl),
									A2($author$project$Utils$icon, _List_Nil, 'expand_more')
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									function () {
									if (state.aS) {
										var itemsFiltered = A2(
											$elm$core$List$filter,
											function (_v4) {
												var name = _v4.b;
												return A2($NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match, state.cS, name);
											},
											items);
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('dropdown-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('dropdown-search')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$placeholder('Rechercher par ' + config.jl),
																	$elm$html$Html$Attributes$class('border-none outline-none'),
																	$author$project$Ui$Dropdown$onClick_(
																	config.jn(state)),
																	$elm$html$Html$Events$onInput(
																	function (searchTerm) {
																		return config.jn(
																			_Utils_update(
																				state,
																				{cS: searchTerm}));
																	})
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$ul,
															_List_Nil,
															A2(
																$elm$core$List$map,
																function (_v3) {
																	var id = _v3.a;
																	var name = _v3.b;
																	return A2(
																		$elm$html$Html$label,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('w-full block')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$li,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('dropdown-li')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$input,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$type_('checkbox'),
																								A2($elm$html$Html$Attributes$style, 'margin-right', '12px'),
																								A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
																								$elm$html$Html$Attributes$checked(
																								A2(
																									$elm$core$List$member,
																									id,
																									A2($elm$core$List$map, $elm$core$Tuple$first, state.ac))),
																								$elm$html$Html$Events$onCheck(
																								function (isCheck) {
																									var newChosenItems = isCheck ? A2(
																										$elm$core$List$cons,
																										_Utils_Tuple2(id, name),
																										state.ac) : A2(
																										$elm_community$list_extra$List$Extra$remove,
																										_Utils_Tuple2(id, name),
																										state.ac);
																									return config.jn(
																										_Utils_update(
																											state,
																											{ac: newChosenItems}));
																								})
																							]),
																						_List_Nil),
																						$elm$html$Html$text(name)
																					]))
																			]));
																},
																itemsFiltered))
														]))
												]));
									} else {
										return A2($elm$html$Html$div, _List_Nil, _List_Nil);
									}
								}()
								]))
						])),
					(!_Utils_eq(state.ac, _List_Nil)) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('filter')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('filter-button'),
									$author$project$Ui$Dropdown$onClick_(
									config.jn(
										_Utils_update(
											state,
											{ac: _List_Nil})))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'font-size', '14px')
										]),
									'replay')
								]))
						])) : $elm$html$Html$text(''),
					A3(
					$elm$html$Html$node,
					'link',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/assets/style/dropdown.css'),
							$elm$html$Html$Attributes$rel('stylesheet')
						]),
					_List_Nil)
				]));
	});
var $author$project$Ui$SearchAttrsBar$view = F4(
	function (locale, _v0, _v1, availableAttributes) {
		var config = _v0;
		var state = _v1;
		var filter = state.bs;
		var viewSelects = _List_fromArray(
			[
				A4(
				$author$project$Ui$Dropdown$view,
				locale,
				$author$project$Ui$SearchAttrsBar$configPurposesDropdown(
					function (newDropdownPurposes) {
						return config.jn(
							_Utils_update(
								state,
								{
									br: newDropdownPurposes,
									bs: _Utils_update(
										filter,
										{
											ic: $author$project$Ui$Dropdown$getChosenItems(newDropdownPurposes)
										})
								}));
					}),
				state.br,
				A2(
					$elm$core$List$map,
					function (p) {
						return _Utils_Tuple2(p, p);
					},
					availableAttributes.ic)),
				A4(
				$author$project$Ui$Dropdown$view,
				locale,
				$author$project$Ui$SearchAttrsBar$configEntitiesDropdown(
					function (newDropdownEntities) {
						return config.jn(
							_Utils_update(
								state,
								{
									bq: newDropdownEntities,
									bs: _Utils_update(
										filter,
										{
											ga: $author$project$Ui$Dropdown$getChosenItems(newDropdownEntities)
										})
								}));
					}),
				state.bq,
				A2(
					$elm$core$List$map,
					function (p) {
						return _Utils_Tuple2(p, p);
					},
					availableAttributes.ga)),
				$elm$html$Html$text('')
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-evenly shadow-md px-4 py-3'),
					$elm$html$Html$Attributes$class('bg-white m-4')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center border border-przgray px-2 py-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(''),
									$elm$html$Html$Attributes$value(state.cS),
									$elm$html$Html$Attributes$class('border-none outline-none'),
									$elm$html$Html$Events$onInput(
									function (searchTerm) {
										return config.jn(
											_Utils_update(
												state,
												{cS: searchTerm}));
									})
								]),
							_List_Nil),
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ml-2 select-none')
								]),
							'search')
						])),
				viewSelects));
	});
var $author$project$Page$Library$AddOrRemoveToCart = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Library$ElementClicked = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Library$elementClass = F2(
	function (selectedElement, element) {
		if (!selectedElement.$) {
			var elmt = selectedElement.a;
			return _Utils_eq(elmt.a$, element.a$) ? $elm$html$Html$Attributes$class('h-44 shadow border bg-white border-przred  flex flex-row flew-wrap') : $elm$html$Html$Attributes$class('h-44 shadow border bg-white hover:border-przred hover:border-opacity-50 flex flex-row flew-wrap');
		} else {
			return $elm$html$Html$Attributes$class('h-44 shadow border bg-white hover:border-przred hover:border-opacity-50 flex flex-row flew-wrap');
		}
	});
var $author$project$Page$Library$viewElement = F3(
	function (selectedElement, cart, element) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($author$project$Page$Library$elementClass, selectedElement, element)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-4/12')
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = element.fx.jq;
							var excelElement = _v0;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$Page$Library$ElementClicked(element)),
										$elm$html$Html$Attributes$class('w-32 h-32 mx-auto cursor-pointer outline-none flex items-center justify-center')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(excelElement.h5)
											]),
										_List_Nil)
									]));
						}()
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col p-2 w-8/12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cursor-pointer text-lg'),
											A2($author$project$Domain$Cart$contains, element, cart) ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Attributes$class('text-gray-300'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Library$AddOrRemoveToCart(element))
										]),
									'shopping_cart'),
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-green-500 text-lg')
										]),
									'fiber_manual_record')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('border-t border-black flex flex-col pt-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-col p-1')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row text-sm')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Utils$icon,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('mr-2 w-7 h-6 m-0 transform rotate-135')
														]),
													'sell'),
													$elm$html$Html$text(element.eW.dL)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row text-sm')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Utils$icon,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('mr-2 w-7 h-6 m-0 transform rotate-90')
														]),
													'navigation'),
													$elm$html$Html$text(element.eW.ib)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row text-sm')
												]),
											_List_fromArray(
												[
													A2(
													$author$project$Utils$icon,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('mr-2 w-7 h-6 m-0')
														]),
													'pin_drop'),
													$elm$html$Html$text(element.eW.gb)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-sm text-right')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('')
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Library$viewElements = F2(
	function (elements, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mx-4 grid grid-flow-row grid-cols-1 gap-4 lg:grid-cols-2 ')
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Page$Library$viewElement, model.y, model.fb),
				elements));
	});
var $author$project$Page$Library$TabTypeClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Library$viewElementTypeMsg = F2(
	function (model, type_) {
		return _Utils_eq(model.a9, type_) ? $elm$html$Html$Attributes$class('p-2  font-bold border-b-2 border-przred outline-none uppercase') : $elm$html$Html$Attributes$class('p-2 font-bold text-przgray border-b-2 border-transparent outline-none uppercase');
	});
var $author$project$Page$Library$viewElementsType = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col border-b border-przgray ')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row space-x-2'),
							A2($elm$html$Html$Attributes$style, 'transform', 'translate(0, 1px)')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									A2($author$project$Page$Library$viewElementTypeMsg, model, 0),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Library$TabTypeClicked(0))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.iW)
								]))
						]))
				]));
	});
var $author$project$Page$Library$CrossClicked = {$: 3};
var $author$project$Page$Library$DeleteElementAllVersions = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Library$DeleteElementSingleVersion = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Library$NextVersionClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Library$PreviousVersionClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Library$SaveAttributesAllVersions = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Page$Library$SaveAttributesSingleVersion = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Page$Library$VersionChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Domain$Attributes$isValid = function (attrs) {
	return ($elm$core$String$length(attrs.dL) > 0) && (($elm$core$String$length(attrs.ib) > 0) && ($elm$core$String$length(attrs.gb) > 0));
};
var $author$project$Ui$AttributesForm$isValid = function (model) {
	return $author$project$Domain$Attributes$isValid(model.eW);
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $author$project$Page$Library$rightPanelDimension = $elm$html$Html$Attributes$class('mx-8 p-6');
var $author$project$Ui$AttributesForm$EntityChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$AttributesForm$NameChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ui$AttributesForm$PurposeChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ui$AttributesForm$SearchClicked = {$: 4};
var $author$project$Ui$AttributesForm$classInput = 'w-full mb-3 rounded-sm border border-gray-300 h-8 p-1 outline-none';
var $author$project$Ui$SearchElement$ChangeInput = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$SearchElement$CrossClicked = {$: 0};
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $author$project$Ui$SearchElement$CreationArrowClicked = {$: 6};
var $author$project$Ui$SearchElement$CreationFilterClicked = function (a) {
	return {$: 10, a: a};
};
var $author$project$Ui$SearchElement$Decrease = 1;
var $author$project$Ui$SearchElement$EntityArrowClicked = {$: 4};
var $author$project$Ui$SearchElement$EntityFilterClicked = function (a) {
	return {$: 8, a: a};
};
var $author$project$Ui$SearchElement$Increasing = 0;
var $author$project$Ui$SearchElement$LastModifiedArrowClicked = {$: 7};
var $author$project$Ui$SearchElement$LastModifiedFilterClicked = function (a) {
	return {$: 11, a: a};
};
var $author$project$Ui$SearchElement$PurposeArrowClicked = {$: 5};
var $author$project$Ui$SearchElement$PurposeFilterClicked = function (a) {
	return {$: 9, a: a};
};
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, _List_Nil, list, _List_Nil);
};
var $author$project$Ui$SearchElement$Identity = 2;
var $author$project$Ui$SearchElement$Name = 1;
var $author$project$Ui$SearchElement$SelectElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$Char$toLower = _Char_toLower;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$core$String$pad = F3(
	function (n, _char, string) {
		var half = (n - $elm$core$String$length(string)) / 2;
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				$elm$core$Basics$ceiling(half),
				$elm$core$String$fromChar(_char)),
			_Utils_ap(
				string,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$floor(half),
					$elm$core$String$fromChar(_char))));
	});
var $author$project$Ui$Date$toShortDate = F2(
	function (zone, posix) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, posix)) + ('-' + ($author$project$Ui$Date$toNumericMonth(
			A2($elm$time$Time$toMonth, zone, posix)) + ('-' + A3(
			$elm$core$String$pad,
			2,
			'0',
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, zone, posix))))));
	});
var $author$project$Ui$Date$view = function (posix) {
	var shortDate = A2($author$project$Ui$Date$toShortDate, $elm$time$Time$utc, posix);
	var longDate = $author$project$Ui$Date$toIso8601(posix);
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tooltip bottom'),
				A2($elm$html$Html$Attributes$attribute, 'tooltip', longDate)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(shortDate)
			]));
};
var $author$project$Ui$SearchElement$viewElement = F2(
	function (model, element) {
		var searchChars = A2($elm$core$Basics$composeL, $elm$core$String$toList, $elm$core$String$toLower)(model.ar);
		var displayStringWithSearchTerm = function (str) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex flex-row overflow-y-auto ')
					]),
				A2(
					$elm$core$List$map,
					function (_char) {
						return A2(
							$elm$core$List$member,
							$elm$core$Char$toLower(_char),
							searchChars) ? A2(
							$elm$html$Html$pre,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-bold text-przred')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromChar(_char))
								])) : A2(
							$elm$html$Html$pre,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromChar(_char))
								]));
					},
					$elm$core$String$toList(str)));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row h-full w-full items-center justify-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-10 h-10 border flex items-center justify-center pt-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2($author$project$Ui$SearchElement$SelectElement, element, 2)),
											$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
										]),
									'check_circle')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(' w-16 md:w-24 lg:w-32 border h-10 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('truncate')
								]),
							_List_fromArray(
								[
									displayStringWithSearchTerm(element.eW.gb)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-24 md:w-32 lg:w-48 border h-10 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('truncate')
								]),
							_List_fromArray(
								[
									displayStringWithSearchTerm(element.eW.ib)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-48 md:w-56 lg:w-72 border h-10 flex flex-row items-center justify-center')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-36 md:w-44 lg:w-60 overflow-y-auto')
								]),
							_List_fromArray(
								[
									displayStringWithSearchTerm(element.eW.dL)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-12')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													A2($author$project$Ui$SearchElement$SelectElement, element, 1)),
													$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
												]),
											'check_circle')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-32 md:w-48 border h-10 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$author$project$Ui$Date$view(
							$elm$time$Time$millisToPosix(element.g2.di))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-32 md:w-48 border h-10 flex items-center justify-center')
						]),
					_List_fromArray(
						[
							$author$project$Ui$Date$view(
							$elm$time$Time$millisToPosix(element.g2.dG))
						]))
				]));
	});
var $author$project$Ui$SearchElement$viewElementsWanted = F2(
	function (locale, model) {
		var elementsWantedNotFilter = A2(
			$elm$core$List$filter,
			function (element) {
				return (model.ar === '') ? true : ((A2($NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match, model.ar, element.eW.dL) || (A2($NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match, model.ar, element.eW.ib) || A2($NoRedInk$elm_simple_fuzzy$Simple$Fuzzy$match, model.ar, element.eW.gb))) && (function () {
					var _v6 = model.e.cd;
					if (!_v6.$) {
						var filter = _v6.a;
						return _Utils_eq(element.eW.ib, filter);
					} else {
						return true;
					}
				}() && function () {
					var _v7 = model.e.b0;
					if (!_v7.$) {
						var filter = _v7.a;
						return _Utils_eq(element.eW.gb, filter);
					} else {
						return true;
					}
				}()));
			},
			model.ah);
		var elementsWantedTime = A2(
			$elm$core$List$map,
			function (element) {
				return element.g2;
			},
			elementsWantedNotFilter);
		var elementsWantedSortByLifecycle = function () {
			var _v0 = model.e.bp;
			if (!_v0.$) {
				if (!_v0.a) {
					var _v1 = _v0.a;
					return A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.di;
						},
						elementsWantedTime);
				} else {
					var _v2 = _v0.a;
					return $elm$core$List$reverse(
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.di;
							},
							elementsWantedTime));
				}
			} else {
				var _v3 = model.e.bw;
				if (!_v3.$) {
					if (!_v3.a) {
						var _v4 = _v3.a;
						return A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.dG;
							},
							elementsWantedTime);
					} else {
						var _v5 = _v3.a;
						return $elm$core$List$reverse(
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.dG;
								},
								elementsWantedTime));
					}
				} else {
					return elementsWantedTime;
				}
			}
		}();
		var elementsWanted = $elm_community$maybe_extra$Maybe$Extra$values(
			A2(
				$elm$core$List$map,
				function (lifeElement) {
					return A2(
						$elm_community$list_extra$List$Extra$find,
						function (element) {
							return _Utils_eq(lifeElement, element.g2);
						},
						elementsWantedNotFilter);
				},
				elementsWantedSortByLifecycle));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('h-5/6 w-full flex flex-col items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row w-full items-center justify-center ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-7')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-16 md:w-24 lg:w-32 bg-gray-100 border flex flex-row items-center justity-center h-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-2/3')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.e_)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' flex w-1/3 justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Ui$SearchElement$EntityArrowClicked),
													$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
												]),
											model.e.ax ? 'arrow_drop_down' : 'arrow_right')
										])),
									function () {
									if (model.e.ax) {
										var entityWanted = $elm_community$list_extra$List$Extra$unique(
											A2(
												$elm$core$List$map,
												function (element) {
													return element.eW.gb;
												},
												model.ah));
										var entityLength = $elm$core$List$length(entityWanted);
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-search-menu')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('hover-search-menu-inner flex flex-col shadow-xl overflow-y-scroll'),
															$elm$html$Html$Attributes$class(
															'h-' + ((entityLength >= 11) ? '96' : $elm$core$String$fromInt(8 * entityLength)))
														]),
													A2(
														$elm$core$List$cons,
														A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('border bg-white w-16 md:w-24 lg:w-32 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Ui$SearchElement$EntityFilterClicked($elm$core$Maybe$Nothing))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('_')
																])),
														A2(
															$elm$core$List$map,
															function (entity) {
																return A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('border bg-white w-16 md:w-24 lg:w-32 hover:bg-przred cursor-pointer h-8'),
																			$elm$html$Html$Events$onClick(
																			$author$project$Ui$SearchElement$EntityFilterClicked(
																				$elm$core$Maybe$Just(entity)))
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('truncate')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(entity)
																				]))
																		]));
															},
															entityWanted)))
												]));
									} else {
										return $elm$html$Html$text('');
									}
								}()
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-24 md:w-32 lg:w-48 border flex flex-row items-center justity-center h-8 bg-gray-100')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-2/3')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.e0)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' flex w-1/3 justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Ui$SearchElement$PurposeArrowClicked),
													$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
												]),
											model.e.aG ? 'arrow_drop_down' : 'arrow_right')
										])),
									function () {
									if (model.e.aG) {
										var purposeWanted = $elm_community$list_extra$List$Extra$unique(
											A2(
												$elm$core$List$map,
												function (element) {
													return element.eW.ib;
												},
												model.ah));
										var purposeLength = $elm$core$List$length(purposeWanted);
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-search-menu')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('hover-search-menu-inner flex flex-col shadow-xl overflow-y-scroll '),
															$elm$html$Html$Attributes$class(
															'h-' + ((purposeLength >= 11) ? '96' : $elm$core$String$fromInt(8 * purposeLength)))
														]),
													A2(
														$elm$core$List$cons,
														A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('border bg-white w-24 md:w-32 lg:w-48 hover:bg-przred cursor-pointer h-8 flex justify-center items-center'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Ui$SearchElement$PurposeFilterClicked($elm$core$Maybe$Nothing))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('_')
																])),
														A2(
															$elm$core$List$map,
															function (purpose) {
																return A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('border bg-white w-24 md:w-32 lg:w-48 hover:bg-przred cursor-pointer h-8'),
																			$elm$html$Html$Events$onClick(
																			$author$project$Ui$SearchElement$PurposeFilterClicked(
																				$elm$core$Maybe$Just(purpose)))
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('truncate')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(purpose)
																				]))
																		]));
															},
															purposeWanted)))
												]));
									} else {
										return $elm$html$Html$text('');
									}
								}()
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-48 md:w-56 lg:w-72 border h-8 flex items-center justify-center bg-gray-100')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.e$)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-32 md:w-48 border flex flex-row items-center justity-center h-8 bg-gray-100')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-2/3')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.fr)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' flex w-1/3 justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Ui$SearchElement$CreationArrowClicked),
													$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
												]),
											model.e.av ? 'arrow_drop_down' : 'arrow_right')
										])),
									model.e.av ? A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('hover-search-menu')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-search-menu-inner flex flex-col shadow-xl overflow-y-auto h-24 overflow-x-hidden')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$CreationFilterClicked($elm$core$Maybe$Nothing))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('_')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$CreationFilterClicked(
																$elm$core$Maybe$Just(0)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.gM)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$CreationFilterClicked(
																$elm$core$Maybe$Just(1)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.fC)
														]))
												]))
										])) : $elm$html$Html$text('')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-32 md:w-48 border flex flex-row items-center justity-center h-8 bg-gray-100')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-2/3')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.ew)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(' flex w-1/3 justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Ui$SearchElement$LastModifiedArrowClicked),
													$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none')
												]),
											model.e.aB ? 'arrow_drop_down' : 'arrow_right')
										])),
									model.e.aB ? A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('hover-search-menu')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-search-menu-inner flex flex-col shadow-xl overflow-y-auto h-24 overflow-x-hidden')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$LastModifiedFilterClicked($elm$core$Maybe$Nothing))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('_')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$LastModifiedFilterClicked(
																$elm$core$Maybe$Just(0)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.gM)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border bg-white w-32 md:w-48 hover:bg-przred cursor-pointer h-8 flex items-center justify-center'),
															$elm$html$Html$Events$onClick(
															$author$project$Ui$SearchElement$LastModifiedFilterClicked(
																$elm$core$Maybe$Just(1)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.fC)
														]))
												]))
										])) : $elm$html$Html$text('')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overflow-y-scroll flex items-center overflow-x-hidden flex-col')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Ui$SearchElement$viewElement(model),
						elementsWanted))
				]));
	});
var $author$project$Ui$SearchElement$view = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-full h-full top-0 left-0 flex items-center justify-center z-40')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-full h-full bg-gray-800 opacity-50')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bg-white mx-auto rounded shadow-lg z-40 w-10/12 h-4/6')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('bg-white flex flex-col px-4 pt-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('self-end'),
											$elm$html$Html$Events$onClick($author$project$Ui$SearchElement$CrossClicked)
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Utils$icon,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-gray-500')
												]),
											'close')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-col h-5/6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-row items-center justify-center mb-2 h-1/6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('w-1/2 flex flex-row items-center justify-end')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.gZ + ' :')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('border border-gray-300 ml-2'),
															$elm$html$Html$Attributes$placeholder(locale.jL + (', ' + locale.hV)),
															$elm$html$Html$Events$onInput($author$project$Ui$SearchElement$ChangeInput)
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row w-1/2 items-center justify-center')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('font-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.jq + ' :')
														])),
													A2(
													$elm$html$Html$pre,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('flex items-center justify-center mt-1')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(' ' + model.c1)
														]))
												]))
										])),
									function () {
									var _v0 = model.bG;
									if (!_v0.$) {
										return $author$project$Ui$Loading$view(locale.g4 + '...');
									} else {
										return A2($author$project$Ui$SearchElement$viewElementsWanted, locale, model);
									}
								}()
								]))
						]))
				]));
	});
var $author$project$Ui$AttributesForm$view2 = F3(
	function (locale, model, _v0) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col m-auto')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-xs block text-left')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mr-2 transform rotate-135')
										]),
									'sell'),
									$elm$html$Html$text(locale.e$ + '*')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class($author$project$Ui$AttributesForm$classInput),
											$elm$html$Html$Attributes$value(model.eW.dL),
											$elm$html$Html$Events$onInput($author$project$Ui$AttributesForm$NameChanged)
										]),
									_List_Nil),
									model.dn ? A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cursor-pointer outline-none select-none'),
											$elm$html$Html$Events$onClick($author$project$Ui$AttributesForm$SearchClicked)
										]),
									'search') : $elm$html$Html$text('')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-xs block text-left')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mr-2 transform rotate-90')
										]),
									'navigation'),
									$elm$html$Html$text(locale.e0 + '*')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class($author$project$Ui$AttributesForm$classInput),
									$elm$html$Html$Attributes$value(model.eW.ib),
									$elm$html$Html$Events$onInput($author$project$Ui$AttributesForm$PurposeChanged)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-xs block text-left')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mr-2')
										]),
									'pin_drop'),
									$elm$html$Html$text(locale.e_ + '*')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class($author$project$Ui$AttributesForm$classInput),
									$elm$html$Html$Attributes$value(model.eW.gb),
									$elm$html$Html$Events$onInput($author$project$Ui$AttributesForm$EntityChanged)
								]),
							_List_Nil)
						])),
					function () {
					var _v1 = model.M;
					if (!_v1.$) {
						var subModel = _v1.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Ui$AttributesForm$GotSearchElementMsg,
							A2($author$project$Ui$SearchElement$view, locale, subModel));
					} else {
						return $elm$html$Html$text('');
					}
				}()
				]));
	});
var $author$project$Ui$AttributesForm$view = F2(
	function (locale, model) {
		var _v0 = model.bS;
		switch (_v0.$) {
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '305px')
						]),
					_List_fromArray(
						[
							$author$project$Ui$Loading$view(locale.g4)
						]));
			case 3:
				var availableAttrs = _v0.a;
				return A3($author$project$Ui$AttributesForm$view2, locale, model, availableAttrs);
			case 2:
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(locale.ge)
						]));
			default:
				return $elm$html$Html$text('');
		}
	});
var $author$project$Ui$PermissionsForm$Valid = {$: 5};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $inkuzmin$elm_multiselect$Multiselect$ClickOnComponent = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$Focused = 1;
var $inkuzmin$elm_multiselect$Multiselect$Toggle = {$: 4};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.jz);
	});
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$borderColor3 = F3(
	function (c1, c2, c3) {
		var value = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[c1.jz, c2.jz, c3.jz]));
		return A2($rtfeldman$elm_css$Css$property, 'border-color', value);
	});
var $rtfeldman$elm_css$Css$borderLeftWidth = $rtfeldman$elm_css$Css$prop1('border-left-width');
var $rtfeldman$elm_css$Css$borderRightWidth = $rtfeldman$elm_css$Css$prop1('border-right-width');
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		bk: 1,
		bn: 0,
		U: 0,
		bu: 0,
		bD: 0,
		jz: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				bk: alpha / 255,
				bn: blue,
				U: 0,
				bu: green,
				bD: red,
				jz: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $rtfeldman$elm_css$Css$inlineBlock = {aS: 0, jz: 'inline-block'};
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			c3: 0,
			de: 0,
			aX: 0,
			A: 0,
			bx: 0,
			a0: 0,
			al: 0,
			a1: 0,
			a2: 0,
			aC: 0,
			aD: 0,
			V: 0,
			am: numericValue,
			be: 0,
			bh: unitLabel,
			bL: units,
			jz: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$relative = {bC: 0, jz: 'relative'};
var $rtfeldman$elm_css$Css$solid = {E: 0, aI: 0, jz: 'solid'};
var $rtfeldman$elm_css$Css$transparent = {U: 0, jz: 'transparent'};
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$zero = {bx: 0, a0: 0, al: 0, a1: 0, a2: 0, aC: 0, aD: 0, hO: 0, am: 0, cb: 0, bh: '', bL: 0, jz: '0'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#999'),
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-2.5))
		]));
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $rtfeldman$elm_css$Css$pointer = {a: 0, jz: 'pointer'};
var $rtfeldman$elm_css$Css$tableCell = {aS: 0, jz: 'table-cell'};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(25)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$px(5))
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.fz) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.dL, record.fz, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{fz: decl, dL: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.dg;
	var imports = _v0.dz;
	var namespaces = _v0.dM;
	var declarations = _v0.fA;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {dg: charset, fA: finalDeclarations, dz: imports, dM: namespaces};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.dt + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.jz)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.dL;
			var declaration = decl.a.fz;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.dg;
	var imports = _v0.dz;
	var namespaces = _v0.dM;
	var declarations = _v0.fA;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {aQ: charsProcessed, a_: hash, cm: seed, bb: shift};
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1 = 3432918353;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c2 = 461845907;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize = function (data) {
	var acc = (!(!data.a_)) ? (data.cm ^ A2(
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
		A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
			15,
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, data.a_)))) : data.cm;
	var h0 = acc ^ data.aQ;
	var h1 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
			5,
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
					A2(
						$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
						15,
						A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, k1))))) + 3864292196;
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.a_ | ((255 & $elm$core$Char$toCode(c)) << data.bb);
		var _v0 = data.bb;
		if (_v0 === 24) {
			return {
				aQ: data.aQ + 1,
				a_: 0,
				cm: A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$mix, data.cm, res),
				bb: 0
			};
		} else {
			return {aQ: data.aQ + 1, a_: res, cm: data.cm, bb: data.bb + 8};
		}
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString = F2(
	function (seed, str) {
		return $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold,
				A4($rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{fz: str, dL: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.dg;
	var imports = _v0.dz;
	var namespaces = _v0.dM;
	var snippets = _v0.ed;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {dg: charset, fA: declarations, dz: imports, dM: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {dg: $elm$core$Maybe$Nothing, dz: _List_Nil, dM: _List_Nil, ed: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $inkuzmin$elm_multiselect$Multiselect$withOptions = F2(
	function (options, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			function (m) {
				return {hn: m, h8: options.h8, i_: options.i_};
			},
			decoder);
	});
var $inkuzmin$elm_multiselect$Multiselect$onClickNoDefault = function (message) {
	var config = {h8: true, i_: true};
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'click',
		A2(
			$inkuzmin$elm_multiselect$Multiselect$withOptions,
			config,
			$elm$json$Json$Decode$succeed(message)));
};
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$rel = $rtfeldman$elm_css$VirtualDom$Styled$attribute('rel');
var $inkuzmin$elm_multiselect$Multiselect$arrow = function (_v0) {
	var model = _v0;
	var arrowRel = (model.m === 2) ? 'arrowUpside' : 'arrow';
	var arrowCss = (model.m === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow]);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Toggle)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(arrowCss),
						$rtfeldman$elm_css$Html$Styled$Attributes$rel(arrowRel)
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$Clear = {$: 7};
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		aD: 0,
		V: 0,
		hO: 0,
		b9: 0,
		am: val,
		bh: '',
		bL: 0,
		jz: $elm$core$String$fromFloat(val)
	};
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clear = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(18)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1))
		]));
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.jz);
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(17)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('#D0021B'))
				]))
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $inkuzmin$elm_multiselect$Multiselect$clear = function (_v0) {
	var model = _v0;
	return (!$elm$core$List$isEmpty(model.ea)) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Clear)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clear]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('×')
					]))
			])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.jz);
};
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.jz, argB.jz, argC.jz])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$table = {aS: 0, jz: 'table'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$container = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(4)),
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#d9d9d9'),
			$rtfeldman$elm_css$Css$hex('#ccc'),
			$rtfeldman$elm_css$Css$hex('#b3b3b3')),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px(34)),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$table)
		]));
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.jz);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom = function (p) {
	return A2($rtfeldman$elm_css$Css$property, 'box-shadow', p);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderColor(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			$inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom('inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)')
		]));
var $inkuzmin$elm_multiselect$Multiselect$Adjust = function (a) {
	return {$: 11, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Filter = function (a) {
	return {$: 10, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Shortcut = function (a) {
	return {$: 13, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $rtfeldman$elm_css$Html$Styled$Attributes$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $rtfeldman$elm_css$Css$border = $rtfeldman$elm_css$Css$prop1('border');
var $rtfeldman$elm_css$Css$initial = {c5: 0, u: 0, bm: 0, c7: 0, aO: 0, c8: 0, aP: 0, ab: 0, E: 0, bU: 0, U: 0, a: 0, aS: 0, aX: 0, cB: 0, bt: 0, b2: 0, ay: 0, A: 0, aY: 0, f: 0, ak: 0, b4: 0, dC: 0, cH: 0, bx: 0, a0: 0, al: 0, a1: 0, a2: 0, aC: 0, aD: 0, V: 0, b6: 0, c: 0, b: 0, cN: 0, hO: 0, am: 0, cb: 0, a6: 0, S: 0, co: 0, bd: 0, aI: 0, be: 0, bf: 0, aJ: 0, J: 0, bh: '', bL: 0, jz: 'initial', bO: 0, at: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{jz: 'inherit'});
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		ak: 0,
		b4: 0,
		aD: 0,
		V: 0,
		hO: 0,
		b9: 0,
		am: val,
		bh: '',
		bL: 0,
		jz: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$none = {aO: 0, db: 0, E: 0, a: 0, aS: 0, gH: 0, dB: 0, cH: 0, a2: 0, aC: 0, V: 0, c: 0, b: 0, cN: 0, cb: 0, h6: 0, S: 0, ci: 0, iy: 0, bd: 0, aJ: 0, J: 0, g: 0, js: 0, jz: 'none'};
var $rtfeldman$elm_css$Css$outlineStyle = $rtfeldman$elm_css$Css$prop1('outline-style');
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$input = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(14)),
			$rtfeldman$elm_css$Css$outlineStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$paddingTop(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $rtfeldman$elm_css$Css$absolute = {bC: 0, jz: 'absolute'};
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.jz;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$Css$normal = {cz: 0, aY: 0, ak: 0, cP: 0, jz: 'normal', at: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $rtfeldman$elm_css$Css$scroll = {bm: 0, db: 0, dB: 0, a6: 0, iz: 0, jz: 'scroll'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$left(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$scroll),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1))
		]));
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$paddingBottom(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $debois$elm_dom$DOM$previousSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'previousSibling', decoder);
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyDown = function (tagger) {
	var domF = $debois$elm_dom$DOM$target(
		$debois$elm_dom$DOM$previousSibling($debois$elm_dom$DOM$offsetWidth));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$map, tagger, domF));
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $inkuzmin$elm_multiselect$Multiselect$onKeyPress = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $inkuzmin$elm_multiselect$Multiselect$onKeyUp = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $inkuzmin$elm_multiselect$Multiselect$Start = {$: 0};
var $inkuzmin$elm_multiselect$Multiselect$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons = function () {
	var options = {h8: true, i_: false};
	var filterKey = function (code) {
		return (_Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow) || _Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) ? $elm$core$Result$Ok(code) : $elm$core$Result$Err('ignored input');
	};
	var decoder = A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$always($inkuzmin$elm_multiselect$Multiselect$Start),
		A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeR, filterKey, $inkuzmin$elm_multiselect$Multiselect$fromResult),
			$rtfeldman$elm_css$Html$Styled$Events$keyCode));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'keydown',
		A2($inkuzmin$elm_multiselect$Multiselect$withOptions, options, decoder));
}();
var $rtfeldman$elm_css$Html$Styled$Attributes$property = $rtfeldman$elm_css$VirtualDom$Styled$property;
var $rtfeldman$elm_css$VirtualDom$Styled$style = F2(
	function (key, val) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$style, key, val),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$style = $rtfeldman$elm_css$VirtualDom$Styled$style;
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $inkuzmin$elm_multiselect$Multiselect$input = function (_v0) {
	var model = _v0;
	var w = $elm$core$String$fromFloat(model.cG + 23.0);
	var inputStyle = A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', w + 'px');
	var forceClear = function () {
		var _v1 = model.s;
		if (_v1.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$Attributes$value('');
		} else {
			return $rtfeldman$elm_css$Html$Styled$Attributes$classList(_List_Nil);
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons,
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.s))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectInput' + model.cY),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$input])),
						$inkuzmin$elm_multiselect$Multiselect$onKeyDown($inkuzmin$elm_multiselect$Multiselect$Adjust),
						$inkuzmin$elm_multiselect$Multiselect$onKeyPress($inkuzmin$elm_multiselect$Multiselect$Shortcut),
						$inkuzmin$elm_multiselect$Multiselect$onKeyUp($inkuzmin$elm_multiselect$Multiselect$Filter),
						inputStyle,
						A2(
						$rtfeldman$elm_css$Html$Styled$Attributes$property,
						'type',
						$elm$json$Json$Encode$string('text')),
						forceClear
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$OnHover = function (a) {
	return {$: 12, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$borderBottomLeftRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-left-radius');
var $rtfeldman$elm_css$Css$borderBottomRightRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-right-radius');
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.jz);
};
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.jz, argB.jz, argC.jz, argD.jz])));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			bk: alpha,
			bn: b,
			U: 0,
			bu: g,
			bD: r,
			jz: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menu = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomRightRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$borderBottomLeftRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderTopColor(
			$rtfeldman$elm_css$Css$hex('#e6e6e6')),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$zero,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.06)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(-1)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
		]));
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.jz, argB.jz])));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#666')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(8),
			$rtfeldman$elm_css$Css$px(10)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight))
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333'))
		]));
var $rtfeldman$elm_css$Html$Styled$Events$onMouseOver = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$menu = function (_v0) {
	var model = _v0;
	var _v1 = model.m;
	if (_v1 === 2) {
		var hovered = function () {
			var _v3 = model.n;
			if (_v3.$ === 1) {
				return '';
			} else {
				var _v4 = _v3.a;
				var id = _v4.a;
				return id;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menu])),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectMenu' + model.cY)
				]),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var name = _v2.a;
					var value = _v2.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_Utils_eq(name, hovered) ? _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered, $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem]) : _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem])),
								$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault(
								$inkuzmin$elm_multiselect$Multiselect$OnSelect(
									_Utils_Tuple2(name, value))),
								$rtfeldman$elm_css$Html$Styled$Events$onMouseOver(
								$inkuzmin$elm_multiselect$Multiselect$OnHover(
									_Utils_Tuple2(name, value)))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(value)
							]));
				},
				model.l));
	} else {
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
	}
};
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero)
		]));
var $inkuzmin$elm_multiselect$Multiselect$RemoveItem = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $rtfeldman$elm_css$Css$border2 = $rtfeldman$elm_css$Css$prop2('border');
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tag = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			A2(
			$rtfeldman$elm_css$Css$border2,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(2)),
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$em(0.9)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.4)),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
		]));
var $rtfeldman$elm_css$Css$borderRight3 = $rtfeldman$elm_css$Css$prop3('border-right');
var $rtfeldman$elm_css$Css$padding3 = $rtfeldman$elm_css$Css$prop3('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					$rtfeldman$elm_css$Css$hex('#d8eafd'))
				])),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			A3(
			$rtfeldman$elm_css$Css$padding3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(3)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tag = F2(
	function (name, value) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tag]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$inkuzmin$elm_multiselect$Multiselect$RemoveItem(
								_Utils_Tuple2(name, value)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('×')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$inline = {aS: 0, jz: 'inline'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inline)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tags = function (_v0) {
	var model = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap]))
			]),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var name = _v1.a;
				var value = _v1.b;
				return A2($inkuzmin$elm_multiselect$Multiselect$tag, name, value);
			},
			model.ea));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $inkuzmin$elm_multiselect$Multiselect$styledView = function (_v0) {
	var model = _v0;
	var inputCss = (model.m === 1) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused]) : ((model.m === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick($inkuzmin$elm_multiselect$Multiselect$ClickOnComponent)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(inputCss)
					]),
				_List_fromArray(
					[
						$inkuzmin$elm_multiselect$Multiselect$tags(model),
						$inkuzmin$elm_multiselect$Multiselect$input(model),
						$inkuzmin$elm_multiselect$Multiselect$clear(model),
						$inkuzmin$elm_multiselect$Multiselect$arrow(model)
					])),
				$inkuzmin$elm_multiselect$Multiselect$menu(model)
			]));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$nodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_nodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $inkuzmin$elm_multiselect$Multiselect$view = A2($elm$core$Basics$composeR, $inkuzmin$elm_multiselect$Multiselect$styledView, $rtfeldman$elm_css$Html$Styled$toUnstyled);
var $author$project$Ui$PermissionsForm$view = F2(
	function (locale, model) {
		var disableValidButton = _Utils_eq(model.au, $krisajenkins$remotedata$RemoteData$Loading) || (_Utils_eq(model.bl, $krisajenkins$remotedata$RemoteData$Loading) || (_Utils_eq(
			model.a4,
			$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.O)) && _Utils_eq(
			model.a5,
			$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.P))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col text-left permission-form')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('p-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.jc + ':'),
							A2(
							$elm$html$Html$map,
							$author$project$Ui$PermissionsForm$GotMultiselectTeamsMsg,
							$inkuzmin$elm_multiselect$Multiselect$view(model.O))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('p-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.jv + ':'),
							A2(
							$elm$html$Html$map,
							$author$project$Ui$PermissionsForm$GotMultiselectUsersMsg,
							$inkuzmin$elm_multiselect$Multiselect$view(model.P))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Ui$PermissionsForm$Valid),
							$elm$html$Html$Attributes$disabled(disableValidButton),
							disableValidButton ? $elm$html$Html$Attributes$class('opacity-50 cursor-not-allowed') : $elm$html$Html$Attributes$class(''),
							$elm$html$Html$Attributes$class('rounded-lg bg-przred text-white font-bold px-2 cursor-pointer mt-2 outline-none select-none')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.iv)
						]))
				]));
	});
var $author$project$Page$Library$viewSelectedElement = F3(
	function (locale, model, selectedElement) {
		var preview = function () {
			var _v6 = selectedElement.fx.jq;
			var excelElement = _v6;
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(excelElement.h5),
						$elm$html$Html$Attributes$class('mx-auto max-h-56')
					]),
				_List_Nil);
		}();
		var currentIndexVersion = function () {
			var _v3 = A2(
				$elm_community$list_extra$List$Extra$find,
				function (_v4) {
					var elementVersion = _v4.b;
					return _Utils_eq(elementVersion.a$, selectedElement.fx.a$);
				},
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, selectedElement.jD));
			if (!_v3.$) {
				var _v5 = _v3.a;
				var index = _v5.a;
				return index + 1;
			} else {
				return 1;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('shadow flex flex-col rounded lg bg-white'),
					$author$project$Page$Library$rightPanelDimension
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-left')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cursor-pointer select-none'),
									$elm$html$Html$Events$onClick($author$project$Page$Library$CrossClicked)
								]),
							'cancel')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex items-center justify-center')
						]),
					_List_fromArray(
						[preview])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('border-t border-black flex flex-col px-10 pb-4 text-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('p-2 font-bold text-black')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.eV)
								])),
							function () {
							var _v0 = model.aa;
							if (_v0.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var attrsFormModel = _v0.a;
								var disabledClasses = 'opacity-50 cursor-not-allowed';
								var buttonEnabled = (!A2($author$project$Domain$Attributes$match, attrsFormModel.eW, selectedElement.eW)) && $author$project$Ui$AttributesForm$isValid(attrsFormModel);
								var btnClasses = 'rounded-lg bg-przred text-white font-bold px-2 cursor-pointer mt-2 outline-none select-none w-full text-center';
								return A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$author$project$Page$Library$GotAttributesFormMsg,
											A2($author$project$Ui$AttributesForm$view, locale, attrsFormModel)),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row justify-center items-center space-x-1 ')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													($elm$core$List$length(selectedElement.jD) === 1) ? _List_fromArray(
														[
															$elm$html$Html$Attributes$class('hidden')
														]) : (buttonEnabled ? _List_fromArray(
														[
															$elm$html$Html$Attributes$class(btnClasses),
															$elm$html$Html$Events$onClick(
															A2($author$project$Page$Library$SaveAttributesAllVersions, attrsFormModel.eW, selectedElement))
														]) : _List_fromArray(
														[
															$elm$html$Html$Attributes$class(btnClasses),
															$elm$html$Html$Attributes$class(disabledClasses)
														])),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.iw)
														])),
													A2(
													$elm$html$Html$div,
													buttonEnabled ? _List_fromArray(
														[
															$elm$html$Html$Attributes$class(btnClasses),
															$elm$html$Html$Events$onClick(
															A2($author$project$Page$Library$SaveAttributesSingleVersion, attrsFormModel.eW, selectedElement))
														]) : _List_fromArray(
														[
															$elm$html$Html$Attributes$class(btnClasses),
															$elm$html$Html$Attributes$class(disabledClasses)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(locale.ix)
														]))
												]))
										]));
							}
						}()
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col px-10 text-left mb-4')
						]),
					_List_fromArray(
						[
							function () {
							var _v1 = selectedElement.fx.jq;
							var excelElement = _v1;
							return $elm$html$Html$text(excelElement.fh);
						}()
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('border-t border-black flex flex-col px-10 text-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-bold p-2 text-black')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.jB)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('shadow flex flew-row py-1 my-2')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mr-2')
										]),
									'timeline'),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-1/2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.jA)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-1/2 text-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('flex flex-row justify-end pr-2')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$elm$core$String$fromInt(currentIndexVersion) + ('/' + $elm$core$String$fromInt(
														$elm$core$List$length(selectedElement.jD))))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row items-center pt-2')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											(currentIndexVersion <= 1) ? $elm$html$Html$Attributes$class('invisible w-2/12 select-none') : $elm$html$Html$Attributes$class('text-right w-2/12 select-none cursor-pointer pr-2'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Library$PreviousVersionClicked(currentIndexVersion))
										]),
									'chevron_left'),
									($elm$core$List$length(selectedElement.jD) <= 1) ? A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('select-none')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('')
										])) : A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('range'),
											$elm$html$Html$Attributes$min('1'),
											$elm$html$Html$Attributes$max(
											$elm$core$String$fromInt(
												$elm$core$List$length(selectedElement.jD))),
											$elm$html$Html$Attributes$class('cursor-pointer flex flex-grow'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(currentIndexVersion)),
											$elm$html$Html$Events$onInput($author$project$Page$Library$VersionChanged)
										]),
									_List_Nil),
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											(_Utils_cmp(
											currentIndexVersion,
											$elm$core$List$length(selectedElement.jD)) > -1) ? $elm$html$Html$Attributes$class('invisible w-2/12 select-none') : $elm$html$Html$Attributes$class('text-left w-2/12 select-none cursor-pointer pl-2'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Library$NextVersionClicked(currentIndexVersion))
										]),
									'chevron_right')
								])),
							function () {
							var _v2 = model.ap;
							if (_v2.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var permissionsFormModel = _v2.a;
								return A2(
									$elm$html$Html$map,
									$author$project$Page$Library$GotPermissionsFormMsg,
									A2($author$project$Ui$PermissionsForm$view, locale, permissionsFormModel));
							}
						}(),
							function () {
							var btnClasses = 'bg-red-500 mt-10 rounded-md text-white font-bold py-2 w-full text-center cursor-pointer';
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex flex-row justify-center items-center space-x-1 ')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										($elm$core$List$length(selectedElement.jD) === 1) ? _List_fromArray(
											[
												$elm$html$Html$Attributes$class('hidden')
											]) : _List_fromArray(
											[
												$elm$html$Html$Attributes$class(btnClasses),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Library$DeleteElementAllVersions(selectedElement))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(locale.fE)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class(btnClasses),
												$elm$html$Html$Events$onClick(
												$author$project$Page$Library$DeleteElementSingleVersion(selectedElement))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(locale.fF)
											]))
									]));
						}()
						]))
				]));
	});
var $author$project$Page$Library$view = F2(
	function (locale, model) {
		var _v0 = model.ah;
		switch (_v0.$) {
			case 2:
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(locale.gc)
						]));
			case 3:
				var elements = _v0.a;
				var _v1 = $author$project$Ui$SearchAttrsBar$getFilterAndSearchTerm(model.a8);
				var filter = _v1.a;
				var searchTerm = _v1.b;
				var filteredElements = A3($author$project$Page$Library$filterElements, filter, searchTerm, elements);
				var availableAttributes = $author$project$Domain$ElementForLibrary$toAvailableAttributes(filteredElements);
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							var _v2 = model.cK;
							if (_v2.$ === 1) {
								return $elm$html$Html$text('');
							} else {
								var route = _v2.a;
								return A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$author$project$Route$href(route),
											$elm$html$Html$Attributes$class('bg-przred mt-10 rounded-md px-16 text-white font-bold py-2')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Return to the document')
										]));
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-col lg:w-7/12')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2($author$project$Page$Library$viewElementsType, locale, model)
												])),
											A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A4($author$project$Ui$SearchAttrsBar$view, locale, $author$project$Page$Library$configSearchAttrsBar, model.a8, availableAttributes)
												])),
											A2($author$project$Page$Library$viewElements, filteredElements, model)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('hidden w-0 md:block lg:w-5/12')
										]),
									_List_fromArray(
										[
											function () {
											var _v3 = model.y;
											if (!_v3.$) {
												var element = _v3.a;
												return A3($author$project$Page$Library$viewSelectedElement, locale, model, element);
											} else {
												return $elm$html$Html$text('');
											}
										}()
										]))
								]))
						]));
			default:
				return $author$project$Ui$Loading$view(locale.g4);
		}
	});
var $author$project$Page$Login$BackButtonClicked = {$: 6};
var $author$project$Ui$Button$Disabled = {$: 0};
var $author$project$Page$Login$EmailEntered = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ui$Button$Enabled = function (a) {
	return {$: 1, a: a};
};
var $author$project$Ui$Button$Loading = {$: 2};
var $author$project$Page$Login$RequestResetLinkClicked = function (a) {
	return {$: 7, a: a};
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Utils$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $author$project$Ui$Button$primary = F2(
	function (state, buttonName) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					function () {
					switch (state.$) {
						case 0:
							return $elm$html$Html$Attributes$class('opacity-50 cursor-not-allowed');
						case 1:
							var msg = state.a;
							return $elm$html$Html$Events$onClick(msg);
						default:
							return $elm$html$Html$Attributes$class('opacity-50 cursor-not-allowed');
					}
				}(),
					$elm$html$Html$Attributes$class('bg-xeqyellow mt-3 rounded-md px-16 text-xeqbluedark font-bold py-2')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(buttonName)
				]));
	});
var $author$project$Page$Login$viewForgotPassword = F2(
	function (locale, model) {
		var buttonState = function () {
			if (model.ao) {
				return $author$project$Ui$Button$Loading;
			} else {
				var _v1 = $author$project$Email$parse(model.dr);
				if (!_v1.$) {
					var email = _v1.a;
					return $author$project$Ui$Button$Enabled(
						$author$project$Page$Login$RequestResetLinkClicked(email));
				} else {
					return $author$project$Ui$Button$Disabled;
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-2xl font-bold mt-1 mb-3')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.gv)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-md')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.gu)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center mt-8')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mr-6')
								]),
							'email'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('email'),
									$elm$html$Html$Attributes$value(model.dr),
									$elm$html$Html$Attributes$placeholder(locale.dr),
									$elm$html$Html$Attributes$class('border border-black w-56 rounded-sm p-2'),
									$elm$html$Html$Events$onInput($author$project$Page$Login$EmailEntered),
									function () {
									if (buttonState.$ === 1) {
										var msg = buttonState.a;
										return $author$project$Utils$onEnter(msg);
									} else {
										return $elm$html$Html$Attributes$class('');
									}
								}()
								]),
							_List_Nil)
						])),
					A2($author$project$Ui$Button$primary, buttonState, locale.il),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Page$Login$BackButtonClicked),
							$elm$html$Html$Attributes$class('hover:underline text-sm mt-3 outline-none')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.e3)
						]))
				]));
	});
var $author$project$Page$Login$ForgotPasswordClicked = {$: 5};
var $author$project$Page$Login$PasswordEntered = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$SubmitTriggered = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$viewLogin = F2(
	function (locale, model) {
		var loginData = {dr: model.dr, dR: model.dR};
		var buttonState = ((loginData.dr === '') || (loginData.dR === '')) ? $author$project$Ui$Button$Disabled : (model.ao ? $author$project$Ui$Button$Loading : $author$project$Ui$Button$Enabled(
			$author$project$Page$Login$SubmitTriggered(loginData)));
		var maybeOnEnter = function () {
			if (buttonState.$ === 1) {
				var msg = buttonState.a;
				return $author$project$Utils$onEnter(msg);
			} else {
				return $elm$html$Html$Attributes$class('');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							model.bz ? $elm$html$Html$Attributes$class('transition-opacity duration-200 text-red-600 opacity-100') : $elm$html$Html$Attributes$class('opacity-0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.g6)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center pl-2 pr-8 mt-8')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mr-6')
								]),
							'email'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('email'),
									$elm$html$Html$Attributes$value(model.dr),
									$elm$html$Html$Attributes$placeholder(locale.dr),
									$elm$html$Html$Events$onInput($author$project$Page$Login$EmailEntered),
									maybeOnEnter,
									$elm$html$Html$Attributes$class('border border-black w-56 rounded-sm p-2')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center pl-2 pr-8 mt-6')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mr-6')
								]),
							'lock'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$value(model.dR),
									$elm$html$Html$Attributes$placeholder(locale.dR),
									$elm$html$Html$Events$onInput($author$project$Page$Login$PasswordEntered),
									maybeOnEnter,
									$elm$html$Html$Attributes$class('border border-black w-56 rounded-sm p-2')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-left ml-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Login$ForgotPasswordClicked),
									$elm$html$Html$Attributes$class('text-sm ml-12 hover:underline outline-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.gt)
								]))
						])),
					A2($author$project$Ui$Button$primary, buttonState, locale.iP),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mt-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.id + ' ')
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$author$project$Route$href($author$project$Route$Register),
									$elm$html$Html$Attributes$class('text-sm underline text-przdarkblue')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.ij)
								]))
						]))
				]));
	});
var $author$project$Page$Login$viewResetPassword = function (locale) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex flex-col items-center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-2xl font-bold mt-1 mb-3')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.iq)
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-md')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.ip)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$Login$BackButtonClicked),
						$elm$html$Html$Attributes$class('hover:underline text-sm mt-3 outline-none')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.e3)
					]))
			]));
};
var $author$project$Page$Login$view = F3(
	function (locale, assets, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center items-center h-screen w-screen bg-przlightgray p-64')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col flex-none justify-center py-5 px-8 bg-white shadow rounded-lg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$author$project$Assets$srcPrezanceLogoBlack(assets),
									$elm$html$Html$Attributes$class('w-64 m-auto')
								]),
							_List_Nil),
							function () {
							var _v0 = model.aw;
							switch (_v0) {
								case 0:
									return A2($author$project$Page$Login$viewLogin, locale, model);
								case 1:
									return A2($author$project$Page$Login$viewForgotPassword, locale, model);
								default:
									return $author$project$Page$Login$viewResetPassword(locale);
							}
						}()
						]))
				]));
	});
var $author$project$Page$Register$Editing = 1;
var $author$project$Page$Register$Pending = 0;
var $author$project$Page$Register$CreateAccountClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Register$Email = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Register$FirstName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Register$GotTextInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Register$LastName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Register$NoFeedback = 0;
var $author$project$Page$Register$Password = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Register$PasswordConfirmation = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Register$CorrectField = 2;
var $author$project$Page$Register$WrongField = 1;
var $author$project$Page$Register$emailFeedback = function (email) {
	switch (email.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 0;
		case 3:
			return 2;
		default:
			return 1;
	}
};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Page$Register$input = F3(
	function (_v0, toMsg, value) {
		var password = _v0.dR;
		var onEnterMsg = _v0.a3;
		var autocomplete = _v0.aN;
		var placeholder = _v0.a7;
		var fieldName = _v0.aW;
		var feedback = _v0.aV;
		var fieldId = 'signup-' + $elm$core$String$toLower(
			A3($elm$core$String$replace, ' ', '-', fieldName));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(fieldId),
							$elm$html$Html$Attributes$class('font-bold text-sm mb-2 self-start')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(fieldName)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('relative')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full bg-dgblue border boder-solid border-przgray py-1 px-2'),
									$elm$html$Html$Attributes$class('leading-relaxed rounded-sm focus:outline-none'),
									$elm$html$Html$Attributes$placeholder(placeholder),
									A2($elm$html$Html$Attributes$attribute, 'autocomplete', autocomplete),
									$elm$html$Html$Attributes$value(value),
									$elm$html$Html$Events$onInput(toMsg),
									$elm$html$Html$Attributes$id(fieldId),
									function () {
									if (!onEnterMsg.$) {
										var msg = onEnterMsg.a;
										return $author$project$Utils$onEnter(msg);
									} else {
										return $elm$html$Html$Attributes$class('');
									}
								}(),
									password ? $elm$html$Html$Attributes$type_('password') : $elm$html$Html$Attributes$class('')
								]),
							_List_Nil),
							function () {
							switch (feedback) {
								case 0:
									return $elm$html$Html$text('');
								case 1:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('absolute top-0 right-0 mt-2 mr-2')
											]),
										_List_fromArray(
											[
												A2($author$project$Utils$icon, _List_Nil, 'clear')
											]));
								default:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('absolute top-0 right-0 mt-2 mr-2')
											]),
										_List_fromArray(
											[
												A2($author$project$Utils$icon, _List_Nil, 'check')
											]));
							}
						}()
						]))
				]));
	});
var $author$project$Password$minimalLength = 12;
var $author$project$Password$isValid = function (password) {
	return _Utils_cmp(
		$elm$core$String$length(password),
		$author$project$Password$minimalLength) > -1;
};
var $author$project$Page$Register$passwordConfirmationFeedback = function (model) {
	var _v0 = _Utils_Tuple2(model.dR, model.Y);
	if (_v0.a === '') {
		return 0;
	} else {
		if (_v0.b === '') {
			return 0;
		} else {
			var password = _v0.a;
			var confirmation = _v0.b;
			return (_Utils_eq(password, confirmation) && $author$project$Password$isValid(password)) ? 2 : 1;
		}
	}
};
var $author$project$Page$Register$passwordFeedback = function (password) {
	return (password === '') ? 0 : ((!$author$project$Password$isValid(password)) ? 1 : 2);
};
var $author$project$Page$Register$getValidatedEmail = function (email) {
	switch (email.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var mail = email.a;
			return $elm$core$Maybe$Just(mail);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Password$Password = $elm$core$Basics$identity;
var $author$project$Password$parse = function (password) {
	return $author$project$Password$isValid(password) ? $elm$core$Maybe$Just(password) : $elm$core$Maybe$Nothing;
};
var $elm$core$String$trim = _String_trim;
var $author$project$Page$Register$trimedNotEmpty = function (s) {
	return !$elm$core$String$isEmpty(
		$elm$core$String$trim(s));
};
var $author$project$Page$Register$step1toTransmission = function (data) {
	var passwordCheck = _Utils_eq(data.dR, data.Y);
	var nonEmptyCheck = $author$project$Page$Register$trimedNotEmpty(data.gq) && $author$project$Page$Register$trimedNotEmpty(data.g$);
	var _v0 = _Utils_Tuple3(
		$author$project$Page$Register$getValidatedEmail(data.dr),
		passwordCheck && nonEmptyCheck,
		$author$project$Password$parse(data.dR));
	if (((!_v0.a.$) && _v0.b) && (!_v0.c.$)) {
		var email = _v0.a.a;
		var password = _v0.c.a;
		return $elm$core$Maybe$Just(
			{dr: email, gq: data.gq, g$: data.g$, dR: password});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Register$invisibleNoError = function (locale) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('italic font-bold text-center mb-8 invisible')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(locale.hE)
			]));
};
var $author$project$Page$Register$viewError = F2(
	function (locale, data) {
		if (data.ba) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-przgray italic font-bold text-center mb-8')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.eR)
					]));
		} else {
			var _v0 = data.dr;
			switch (_v0.$) {
				case 0:
					return $author$project$Page$Register$invisibleNoError(locale);
				case 1:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-przgray italic font-bold text-center mb-8')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(locale.jj)
							]));
				case 2:
					return $author$project$Page$Register$invisibleNoError(locale);
				case 3:
					return $author$project$Page$Register$invisibleNoError(locale);
				default:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-przgray italic font-bold text-center mb-8')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(locale.eQ)
							]));
			}
		}
	});
var $author$project$Page$Register$viewStep1 = F3(
	function (state, locale, data) {
		var clickNextMsg = A2(
			$elm$core$Maybe$map,
			$author$project$Page$Register$CreateAccountClicked,
			$author$project$Page$Register$step1toTransmission(data));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-8 flex flex-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pr-2 flex-grow')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Page$Register$input,
									{aN: 'given-name', aV: 0, aW: locale.cA, a3: clickNextMsg, dR: false, a7: locale.cA},
									A2($elm$core$Basics$composeR, $author$project$Page$Register$FirstName, $author$project$Page$Register$GotTextInput),
									data.gq)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-shrink mr-1')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pl-2 flex-grow')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Page$Register$input,
									{aN: 'family-name', aV: 0, aW: locale.cI, a3: clickNextMsg, dR: false, a7: locale.cI},
									A2($elm$core$Basics$composeR, $author$project$Page$Register$LastName, $author$project$Page$Register$GotTextInput),
									data.g$)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col pb-8')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Page$Register$input,
							{
								aN: 'email',
								aV: $author$project$Page$Register$emailFeedback(data.dr),
								aW: locale.dr,
								a3: clickNextMsg,
								dR: false,
								a7: locale.f2
							},
							A2($elm$core$Basics$composeR, $author$project$Page$Register$Email, $author$project$Page$Register$GotTextInput),
							$author$project$Page$Register$emailToString(data.dr))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-6')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Page$Register$input,
							{
								aN: 'new-password',
								aV: $author$project$Page$Register$passwordFeedback(data.dR),
								aW: locale.dR,
								a3: clickNextMsg,
								dR: true,
								a7: locale.hZ
							},
							A2($elm$core$Basics$composeR, $author$project$Page$Register$Password, $author$project$Page$Register$GotTextInput),
							data.dR),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-sm text-przgray text-left font-bold mt-1'),
									((!$elm$core$String$isEmpty(data.dR)) && ((!data.an) && $author$project$Password$isValid(data.dR))) ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Attributes$class('invisible')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('* ' + locale.h_)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-8 relative')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Page$Register$input,
							{
								aN: 'off',
								aV: $author$project$Page$Register$passwordConfirmationFeedback(data),
								aW: locale.fi,
								a3: clickNextMsg,
								dR: true,
								a7: locale.hZ
							},
							A2($elm$core$Basics$composeR, $author$project$Page$Register$PasswordConfirmation, $author$project$Page$Register$GotTextInput),
							data.Y)
						])),
					A2($author$project$Page$Register$viewError, locale, data),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-8')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Ui$Button$primary,
							function () {
								if (!clickNextMsg.$) {
									var msg = clickNextMsg.a;
									if (!state) {
										return $author$project$Ui$Button$Loading;
									} else {
										return $author$project$Ui$Button$Enabled(msg);
									}
								} else {
									return $author$project$Ui$Button$Disabled;
								}
							}(),
							locale.ij)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.eO)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('/'),
									$elm$html$Html$Attributes$class('text-black font-bold pl-2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.iP)
								]))
						]))
				]));
	});
var $author$project$Page$Register$view = F3(
	function (locale, assets, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center items-center h-screen w-screen bg-przlightgray p-64')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col flex-none justify-center py-5 px-8 bg-white shadow rounded-lg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$author$project$Assets$srcPrezanceLogoBlack(assets),
									$elm$html$Html$Attributes$class('w-64 m-auto')
								]),
							_List_Nil),
							function () {
							switch (model.$) {
								case 0:
									var data = model.a;
									return A3($author$project$Page$Register$viewStep1, 1, locale, data);
								case 1:
									var data = model.a;
									return A3(
										$author$project$Page$Register$viewStep1,
										0,
										locale,
										{
											dr: $author$project$Page$Register$ValidatedEmail(data.dr),
											gq: data.gq,
											g$: data.g$,
											dR: $author$project$Password$toString(data.dR),
											Y: $author$project$Password$toString(data.dR),
											an: false,
											ba: false
										});
								default:
									var data = model.a;
									return A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mr-2')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(locale.et)
													])),
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('font-bold mr-2')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$Email$toString(data.dr) + '.')
													])),
												$elm$html$Html$text(locale.h4)
											]));
							}
						}()
						]))
				]));
	});
var $author$project$Page$ResetPassword$PasswordEntered = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ResetPassword$ResetPasswordTriggered = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ResetPassword$viewResetPassword = F2(
	function (locale, model) {
		var buttonState = ((model.jo === '') || ($elm$core$String$length(model.dR) < 12)) ? $author$project$Ui$Button$Disabled : $author$project$Ui$Button$Enabled(
			$author$project$Page$ResetPassword$ResetPasswordTriggered(
				{dR: model.dR, jo: model.jo}));
		var maybeOnEnter = function () {
			switch (buttonState.$) {
				case 1:
					var msg = buttonState.a;
					return $author$project$Utils$onEnter(msg);
				case 0:
					return $elm$html$Html$Attributes$class('');
				default:
					return $elm$html$Html$Attributes$class('');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col items-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-2xl font-bold mt-1 mb-3')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.io)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-md')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.$9)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center mt-8')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mr-6')
								]),
							'lock'),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$placeholder(locale.dR),
									$elm$html$Html$Attributes$value(model.dR),
									$elm$html$Html$Attributes$class('border border-black w-56 rounded-sm p-2'),
									$elm$html$Html$Events$onInput($author$project$Page$ResetPassword$PasswordEntered),
									maybeOnEnter
								]),
							_List_Nil)
						])),
					A2($author$project$Ui$Button$primary, buttonState, locale.im),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pb-8 mt-3')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href('/')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.e3)
								]))
						]))
				]));
	});
var $author$project$Page$ResetPassword$view = F3(
	function (locale, assets, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row justify-center items-center h-screen w-screen bg-przlightgray p-64')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-col flex-none justify-center py-5 px-8 bg-white shadow rounded-lg')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$author$project$Assets$srcPrezanceLogoBlack(assets),
									$elm$html$Html$Attributes$class('w-64 m-auto')
								]),
							_List_Nil),
							A2($author$project$Page$ResetPassword$viewResetPassword, locale, model)
						]))
				]));
	});
var $author$project$Page$Team$CreateTeamClicked = {$: 7};
var $author$project$Page$Team$SetQuery = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Team$SetTableState = function (a) {
	return {$: 3, a: a};
};
var $billstclair$elm_sortable_table$Table$intColumn = F2(
	function (name, toInt) {
		return {
			dL: name,
			ee: $billstclair$elm_sortable_table$Table$increasingOrDecreasingBy(toInt),
			en: A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $billstclair$elm_sortable_table$Table$textDetails, $elm$core$String$fromInt),
				toInt)
		};
	});
var $author$project$Page$Team$RemoveTeamClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Team$removeColumnView = function (team) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Page$Team$RemoveTeamClicked(team)),
						$elm$html$Html$Attributes$class('cursor-pointer select-none text-gray-300')
					]),
				'delete')
			]));
};
var $author$project$Page$Team$removeColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Page$Team$removeColumnView});
var $author$project$Page$Team$TeamClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Team$teamClickColumnView = function (team) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Page$Team$TeamClicked(team)),
						$elm$html$Html$Attributes$class('cursor-pointer select-none text-gray-400')
					]),
				'chevron_right')
			]));
};
var $author$project$Page$Team$teamClickColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Page$Team$teamClickColumnView});
var $author$project$Page$TeamDetails$DialogSubteamOpen = {$: 8};
var $author$project$Page$TeamDetails$ReturnToTeamsClicked = {$: 5};
var $author$project$Page$TeamDetails$SetQuery = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$TeamDetails$SetTableState = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$TeamDetails$UpdateUsersClicked = {$: 6};
var $author$project$Page$TeamDetails$DialogSubteamChangeSelection = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$TeamDetails$DialogSubteamClose = {$: 9};
var $author$project$Page$TeamDetails$DialogSubteamOk = function (a) {
	return {$: 10, a: a};
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$Attributes$multiple = $elm$html$Html$Attributes$boolProperty('multiple');
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $abadi199$elm_input_extra$MultiSelect$Option = F3(
	function (value, text, selected) {
		return {ea: selected, jf: text, jz: value};
	});
var $abadi199$elm_input_extra$MultiSelect$optionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$abadi199$elm_input_extra$MultiSelect$Option,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'selected', $elm$json$Json$Decode$bool));
var $abadi199$elm_input_extra$MultiSelect$optionsDecoder = A2(
	$elm$json$Json$Decode$field,
	'options',
	A2(
		$elm$json$Json$Decode$andThen,
		function (list) {
			var onlySuccess = A3(
				$elm$core$List$foldr,
				F2(
					function (_v0, sofar) {
						var next = _v0.b;
						if (next.$ === 1) {
							return sofar;
						} else {
							var x = next.a;
							return A2($elm$core$List$cons, x, sofar);
						}
					}),
				_List_Nil,
				list);
			return $elm$json$Json$Decode$succeed(onlySuccess);
		},
		$elm$json$Json$Decode$keyValuePairs(
			$elm$json$Json$Decode$maybe($abadi199$elm_input_extra$MultiSelect$optionDecoder))));
var $abadi199$elm_input_extra$MultiSelect$selectedOptionsDecoder = function () {
	var filterSelected = function (options) {
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.jz;
			},
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.ea;
				},
				options));
	};
	return A2(
		$elm$json$Json$Decode$map,
		filterSelected,
		A2($elm$json$Json$Decode$field, 'target', $abadi199$elm_input_extra$MultiSelect$optionsDecoder));
}();
var $abadi199$elm_input_extra$MultiSelect$onChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $abadi199$elm_input_extra$MultiSelect$selectedOptionsDecoder));
};
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $abadi199$elm_input_extra$MultiSelect$multiSelect = F3(
	function (options, attributes, currentValue) {
		var isSelected = function (value) {
			return A2(
				$elm$core$List$any,
				$elm$core$Basics$eq(value),
				currentValue);
		};
		var toOption = function (_v0) {
			var value = _v0.jz;
			var text = _v0.jf;
			var enabled = _v0.f3;
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(value),
						$elm$html$Html$Attributes$selected(
						isSelected(value)),
						$elm$html$Html$Attributes$disabled(!enabled)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(text)
					]));
		};
		return A2(
			$elm$html$Html$select,
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$abadi199$elm_input_extra$MultiSelect$onChange(options.hQ),
						$elm$html$Html$Attributes$multiple(true)
					])),
			A2($elm$core$List$map, toOption, options.gX));
	});
var $author$project$Page$TeamDetails$dialogConfigSubteam = function (dialogModel) {
	var selectedIds = A2(
		$elm$core$List$map,
		function ($) {
			return $.a$;
		},
		dialogModel.bF);
	var excludedIds = A2(
		$elm$core$List$map,
		function ($) {
			return $.a$;
		},
		dialogModel.cy);
	var items = A2(
		$elm$core$List$map,
		function (team) {
			return {
				f3: !A2($elm$core$List$member, team.a$, excludedIds),
				jf: team.dL,
				jz: team.a$
			};
		},
		dialogModel.au);
	return {
		D: $elm$core$Maybe$Just(
			A3(
				$abadi199$elm_input_extra$MultiSelect$multiSelect,
				{gX: items, hQ: $author$project$Page$TeamDetails$DialogSubteamChangeSelection},
				_List_Nil,
				selectedIds)),
		ff: $elm$core$Maybe$Just($author$project$Page$TeamDetails$DialogSubteamClose),
		fo: $elm$core$Maybe$Nothing,
		gs: $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(''),
						$elm$html$Html$Events$onClick(
						$author$project$Page$TeamDetails$DialogSubteamOk(dialogModel.bF))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('OK')
					]))),
		gE: $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Change the subteams')
					])))
	};
};
var $author$project$Page$TeamDetails$RemoveUserClicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$TeamDetails$removeColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Page$TeamDetails$RemoveUserClicked(user)),
						$elm$html$Html$Attributes$class('cursor-pointer select-none text-gray-300')
					]),
				'delete')
			]));
};
var $author$project$Page$TeamDetails$removeColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Page$TeamDetails$removeColumnView});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $alex_tan$elm_dialog$Dialog$isJust = function (m) {
	if (!m.$) {
		return true;
	} else {
		return false;
	}
};
var $alex_tan$elm_dialog$Dialog$backdrop = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'modal-backdrop in',
						$alex_tan$elm_dialog$Dialog$isJust(config))
					]))
			]),
		_List_Nil);
};
var $alex_tan$elm_dialog$Dialog$empty = A2($elm$html$Html$span, _List_Nil, _List_Nil);
var $alex_tan$elm_dialog$Dialog$maybe = F3(
	function (_default, f, value) {
		if (!value.$) {
			var value_ = value.a;
			return f(value_);
		} else {
			return _default;
		}
	});
var $alex_tan$elm_dialog$Dialog$wrapBody = function (body) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-body')
			]),
		_List_fromArray(
			[body]));
};
var $alex_tan$elm_dialog$Dialog$wrapFooter = function (footer) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-footer')
			]),
		_List_fromArray(
			[footer]));
};
var $alex_tan$elm_dialog$Dialog$closeButton = function (closeMessage) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('close'),
				$elm$html$Html$Events$onClick(closeMessage)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('x')
			]));
};
var $alex_tan$elm_dialog$Dialog$wrapHeader = F2(
	function (closeMessage, header) {
		return (_Utils_eq(closeMessage, $elm$core$Maybe$Nothing) && _Utils_eq(header, $elm$core$Maybe$Nothing)) ? $alex_tan$elm_dialog$Dialog$empty : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal-header')
				]),
			_List_fromArray(
				[
					A3($alex_tan$elm_dialog$Dialog$maybe, $alex_tan$elm_dialog$Dialog$empty, $alex_tan$elm_dialog$Dialog$closeButton, closeMessage),
					A2($elm$core$Maybe$withDefault, $alex_tan$elm_dialog$Dialog$empty, header)
				]));
	});
var $alex_tan$elm_dialog$Dialog$view = function (maybeConfig) {
	var displayed = $alex_tan$elm_dialog$Dialog$isJust(maybeConfig);
	return A2(
		$elm$html$Html$div,
		function () {
			var _v0 = A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.fo;
				},
				maybeConfig);
			if (_v0.$ === 1) {
				return _List_Nil;
			} else {
				var containerClass = _v0.a;
				return _List_fromArray(
					[
						$elm$html$Html$Attributes$class(containerClass)
					]);
			}
		}(),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('in', displayed)
							])),
						A2(
						$elm$html$Html$Attributes$style,
						'display',
						displayed ? 'block' : 'none')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-dialog')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-content')
									]),
								function () {
									if (maybeConfig.$ === 1) {
										return _List_fromArray(
											[$alex_tan$elm_dialog$Dialog$empty]);
									} else {
										var config = maybeConfig.a;
										return _List_fromArray(
											[
												A2($alex_tan$elm_dialog$Dialog$wrapHeader, config.ff, config.gE),
												A3($alex_tan$elm_dialog$Dialog$maybe, $alex_tan$elm_dialog$Dialog$empty, $alex_tan$elm_dialog$Dialog$wrapBody, config.D),
												A3($alex_tan$elm_dialog$Dialog$maybe, $alex_tan$elm_dialog$Dialog$empty, $alex_tan$elm_dialog$Dialog$wrapFooter, config.gs)
											]);
									}
								}())
							]))
					])),
				$alex_tan$elm_dialog$Dialog$backdrop(maybeConfig)
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$CrossClicked = {$: 0};
var $author$project$Ui$AddAndRemoveUsersFromTeam$SetQueryForUsersFromApi = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$SetQueryForUsersInTeam = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$SetTableState = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$ValidButtonClicked = {$: 1};
var $author$project$Ui$AddAndRemoveUsersFromTeam$AddUserToTeam = function (a) {
	return {$: 4, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$addUserColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('blanc-column')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Ui$AddAndRemoveUsersFromTeam$AddUserToTeam(user)),
						$elm$html$Html$Attributes$class('cursor-pointer text-gray-300 select-none ')
					]),
				'add')
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$addUserColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$AddAndRemoveUsersFromTeam$addUserColumnView});
var $author$project$Ui$AddAndRemoveUsersFromTeam$emailColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate email-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.dr)
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$emailColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Email', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$AddAndRemoveUsersFromTeam$emailColumnView});
var $author$project$Ui$AddAndRemoveUsersFromTeam$idColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate id-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.a$)
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$idColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Id', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$AddAndRemoveUsersFromTeam$idColumnView});
var $author$project$Ui$AddAndRemoveUsersFromTeam$nameColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate name-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.cA)
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$nameColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Name', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$AddAndRemoveUsersFromTeam$nameColumnView});
var $author$project$Ui$AddAndRemoveUsersFromTeam$RemoveUser = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$viewUsers = function (user) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-1/3 flex items-center justify-center px-1 pt-1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('border bg-przred text-white font-bold px-2 rounded-xl flex flex-row w-full')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('truncate w-11/12')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(user.cA)
							])),
						A2(
						$author$project$Utils$icon,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-2 cursor-pointer outline-none select-none w-1/12 mr-1'),
								$elm$html$Html$Events$onClick(
								$author$project$Ui$AddAndRemoveUsersFromTeam$RemoveUser(user))
							]),
						'cancel')
					]))
			]));
};
var $author$project$Ui$AddAndRemoveUsersFromTeam$view = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-full h-full flex items-center justify-center top-0 left-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-full h-full bg-gray-800 opacity-50'),
							$elm$html$Html$Events$onClick($author$project$Ui$AddAndRemoveUsersFromTeam$CrossClicked)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('h-2/3 w-2/3 z-10')
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = model.bG;
							switch (_v0) {
								case 0:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-center justify-center border shadow bg-white')
											]),
										_List_fromArray(
											[
												$author$project$Ui$Loading$view('Loading...')
											]));
								case 1:
									var validUsersInTeam = $elm_community$maybe_extra$Maybe$Extra$values(
										A2(
											$elm$core$List$map,
											function (user) {
												return A2(
													$elm$core$String$contains,
													$elm$core$String$toLower(model.cg),
													$elm$core$String$toLower(user.cA)) ? $elm$core$Maybe$Just(user) : $elm$core$Maybe$Nothing;
											},
											model.jw));
									var validUsersForTable = $elm_community$maybe_extra$Maybe$Extra$values(
										A2(
											$elm$core$List$map,
											function (user) {
												return (A2(
													$elm$core$String$contains,
													$elm$core$String$toLower(model.cf),
													$elm$core$String$toLower(user.cA)) && function () {
													var _v1 = A2(
														$elm_community$list_extra$List$Extra$find,
														function (selectedUser) {
															return _Utils_eq(selectedUser.a$, user.a$);
														},
														model.jw);
													if (!_v1.$) {
														return false;
													} else {
														return true;
													}
												}()) ? $elm$core$Maybe$Just(user) : $elm$core$Maybe$Nothing;
											},
											model.ct));
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start border shadow bg-white p-4 ')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-1/6 w-full flex flex-col')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('w-full flex justify-end h-2/6')
															]),
														_List_fromArray(
															[
																A2(
																$author$project$Utils$icon,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('cursor-pointer'),
																		$elm$html$Html$Events$onClick($author$project$Ui$AddAndRemoveUsersFromTeam$CrossClicked)
																	]),
																'close')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row h-4/6')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 flex items-center justify-start')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$placeholder(locale.iB),
																				$elm$html$Html$Events$onInput($author$project$Ui$AddAndRemoveUsersFromTeam$SetQueryForUsersFromApi),
																				$elm$html$Html$Attributes$class('outline-none border shadow w-3/12')
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 flex items-center justify-start')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$placeholder(locale.iC),
																				$elm$html$Html$Attributes$class('w-3/12 border shadow outline-none'),
																				$elm$html$Html$Events$onInput($author$project$Ui$AddAndRemoveUsersFromTeam$SetQueryForUsersInTeam)
																			]),
																		_List_Nil)
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('w-full h-5/6 flex flex-col')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row h-5/6 border shadow-lg w-full')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 overflow-y-scroll border-r-2')
																	]),
																_List_fromArray(
																	[
																		function () {
																		if (!_Utils_eq(validUsersForTable, _List_Nil)) {
																			var config = $billstclair$elm_sortable_table$Table$customConfig(
																				{
																					fg: _List_fromArray(
																						[$author$project$Ui$AddAndRemoveUsersFromTeam$idColumn, $author$project$Ui$AddAndRemoveUsersFromTeam$nameColumn, $author$project$Ui$AddAndRemoveUsersFromTeam$emailColumn, $author$project$Ui$AddAndRemoveUsersFromTeam$addUserColumn]),
																					fy: $author$project$Utils$przTableCustomizations,
																					jm: function ($) {
																						return $.cA;
																					},
																					jn: $author$project$Ui$AddAndRemoveUsersFromTeam$SetTableState
																				});
																			return A3($billstclair$elm_sortable_table$Table$view, config, model.cp, validUsersForTable);
																		} else {
																			return $elm$html$Html$text('');
																		}
																	}()
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('overflow-y-scroll w-1/2')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex flex-row flex-wrap')
																			]),
																		A2($elm$core$List$map, $author$project$Ui$AddAndRemoveUsersFromTeam$viewUsers, validUsersInTeam))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('w-full flex items-center justify-center h-1/6'),
																$elm$html$Html$Events$onClick($author$project$Ui$AddAndRemoveUsersFromTeam$ValidButtonClicked)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('font-bold flex items-center justify-center border bg-przred rounded-lg text-white cursor-pointer py-1 px-2')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(locale.jy)
																	]))
															]))
													]))
											]));
								default:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Utils$icon,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-1/6 flex flex-row w-full justify-end cursor-pointer'),
														$elm$html$Html$Events$onClick($author$project$Ui$AddAndRemoveUsersFromTeam$CrossClicked)
													]),
												'close'),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-5/6 w-full flex items-center justify-center font-bold')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(locale.gf)
													]))
											]));
							}
						}()
						]))
				]));
	});
var $author$project$Page$TeamDetails$view = F2(
	function (locale, model) {
		var users = $elm_community$maybe_extra$Maybe$Extra$values(
			A2(
				$elm$core$List$map,
				function (user) {
					return A2(
						$elm$core$String$contains,
						$elm$core$String$toLower(model.ce),
						$elm$core$String$toLower(user.cA)) ? $elm$core$Maybe$Just(user) : $elm$core$Maybe$Nothing;
				},
				model.jb.jv));
		var config = $billstclair$elm_sortable_table$Table$customConfig(
			{
				fg: _List_fromArray(
					[
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						'Firstname',
						function ($) {
							return $.cA;
						}),
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						'Lastname',
						function ($) {
							return $.cI;
						}),
						A2(
						$billstclair$elm_sortable_table$Table$stringColumn,
						'Email',
						function ($) {
							return $.dr;
						}),
						$author$project$Page$TeamDetails$removeColumn
					]),
				fy: $author$project$Utils$przTableCustomizations,
				jm: function ($) {
					return $.a$;
				},
				jn: $author$project$Page$TeamDetails$SetTableState
			});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-full flex flex-col items-start pl-8 pt-8')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cursor-pointer select-none flex flex-row'),
							$elm$html$Html$Events$onClick($author$project$Page$TeamDetails$ReturnToTeamsClicked)
						]),
					_List_fromArray(
						[
							A2($author$project$Utils$icon, _List_Nil, 'keyboard_arrow_left'),
							$elm$html$Html$text(locale.e4)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-bold mb-1')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(model.jb.dL)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full flex flex-row justify-center mb-4 pr-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full border border-gray-300')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.cX),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$TeamDetails$DialogSubteamOpen),
									$elm$html$Html$Attributes$class('rounded-md bg-przred text-white font-bold p-1 m-1 outline-none select-none')
								]),
							_List_fromArray(
								[
									A2($author$project$Utils$icon, _List_Nil, 'group_add')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row w-full pr-8')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$ul,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-left')
								]),
							A2(
								$elm$core$List$map,
								function (team) {
									return A2(
										$elm$html$Html$li,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$author$project$Utils$icon,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mr-2')
													]),
												'group'),
												$elm$html$Html$text(team.dL),
												$elm$html$Html$text(
												' (' + ($elm$core$String$fromInt(team.jp) + ' members)'))
											]));
								},
								$author$project$Domain$Team$getSubteams(model.jb)))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-bold mt-4')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(locale.hm)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row w-full pr-8 ')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder(locale.iB),
									$elm$html$Html$Events$onInput($author$project$Page$TeamDetails$SetQuery),
									$elm$html$Html$Attributes$class('outline-none mb-1 w-2/12 border shadow-md')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex justify-end w-10/12')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cursor-pointer border rounded-md bg-przred text-white font-bold py-1 px-2 outline-none select-none'),
											$elm$html$Html$Events$onClick($author$project$Page$TeamDetails$UpdateUsersClicked)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(locale.eB)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full pr-8 mt-4')
						]),
					_List_fromArray(
						[
							A3($billstclair$elm_sortable_table$Table$view, config, model.cp, users)
						])),
					function () {
					var _v0 = model.aM;
					if (!_v0.$) {
						var subModel = _v0.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Page$TeamDetails$GotUsersDialogMsg,
							A2($author$project$Ui$AddAndRemoveUsersFromTeam$view, locale, subModel));
					} else {
						return $elm$html$Html$text('');
					}
				}(),
					$alex_tan$elm_dialog$Dialog$view(
					A2($elm$core$Maybe$map, $author$project$Page$TeamDetails$dialogConfigSubteam, model.ag))
				]));
	});
var $author$project$Ui$CreateTeam$ChangeNewTeamName = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$CreateTeam$CrossClicked = {$: 0};
var $author$project$Ui$CreateTeam$SetQuery = function (a) {
	return {$: 6, a: a};
};
var $author$project$Ui$CreateTeam$SetTableState = function (a) {
	return {$: 4, a: a};
};
var $author$project$Ui$CreateTeam$ValidButtonClicked = {$: 1};
var $author$project$Ui$CreateTeam$AddUserToTeam = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ui$CreateTeam$addUserColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('blanc-column')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Utils$icon,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Ui$CreateTeam$AddUserToTeam(user)),
						$elm$html$Html$Attributes$class('cursor-pointer text-gray-300 select-none ')
					]),
				'add')
			]));
};
var $author$project$Ui$CreateTeam$addUserColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: '', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$CreateTeam$addUserColumnView});
var $author$project$Ui$CreateTeam$emailColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate email-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.dr)
			]));
};
var $author$project$Ui$CreateTeam$emailColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Email', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$CreateTeam$emailColumnView});
var $author$project$Ui$CreateTeam$idColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate id-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.a$)
			]));
};
var $author$project$Ui$CreateTeam$idColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Id', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$CreateTeam$idColumnView});
var $author$project$Ui$CreateTeam$nameColumnView = function (user) {
	return A2(
		$billstclair$elm_sortable_table$Table$HtmlDetails,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('truncate name-column')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(user.cA)
			]));
};
var $author$project$Ui$CreateTeam$nameColumn = $billstclair$elm_sortable_table$Table$veryCustomColumn(
	{dL: 'Name', ee: $billstclair$elm_sortable_table$Table$unsortable, en: $author$project$Ui$CreateTeam$nameColumnView});
var $author$project$Ui$CreateTeam$RemoveUser = function (a) {
	return {$: 7, a: a};
};
var $author$project$Ui$CreateTeam$viewUsers = function (user) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('w-1/3 flex items-center justify-center px-1 pt-1')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('border bg-przred text-white font-bold px-2 rounded-xl flex flex-row w-full')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('truncate w-11/12')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(user.cA)
							])),
						A2(
						$author$project$Utils$icon,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('ml-2 cursor-pointer outline-none select-none w-1/12 mr-1'),
								$elm$html$Html$Events$onClick(
								$author$project$Ui$CreateTeam$RemoveUser(user))
							]),
						'cancel')
					]))
			]));
};
var $author$project$Ui$CreateTeam$view = F2(
	function (locale, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed w-full h-full flex items-center justify-center top-0 left-0')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute w-full h-full bg-gray-800 opacity-50'),
							$elm$html$Html$Events$onClick($author$project$Ui$CreateTeam$CrossClicked)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('h-2/3 w-2/3 z-10')
						]),
					_List_fromArray(
						[
							function () {
							var _v0 = model.bG;
							switch (_v0) {
								case 0:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-center justify-center border shadow bg-white')
											]),
										_List_fromArray(
											[
												$author$project$Ui$Loading$view('Loading...')
											]));
								case 1:
									var acceptableUsers = $elm_community$maybe_extra$Maybe$Extra$values(
										A2(
											$elm$core$List$map,
											function (user) {
												return (A2(
													$elm$core$String$contains,
													$elm$core$String$toLower(model.ce),
													$elm$core$String$toLower(user.cA)) && function () {
													var _v1 = A2(
														$elm_community$list_extra$List$Extra$find,
														function (selectedUser) {
															return _Utils_eq(selectedUser.a$, user.a$);
														},
														model.cs.jv);
													if (!_v1.$) {
														return false;
													} else {
														return true;
													}
												}()) ? $elm$core$Maybe$Just(user) : $elm$core$Maybe$Nothing;
											},
											model.jv));
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start border shadow bg-white p-4 ')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-1/6 w-full')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row w-full h-1/2')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$placeholder(locale.f4),
																		$elm$html$Html$Attributes$class('w-4/12 border shadow outline-none h-6'),
																		$elm$html$Html$Events$onInput($author$project$Ui$CreateTeam$ChangeNewTeamName)
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-full flex justify-end')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$author$project$Utils$icon,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('cursor-pointer'),
																				$elm$html$Html$Events$onClick($author$project$Ui$CreateTeam$CrossClicked)
																			]),
																		'close')
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('h-1/2 w-full flex items-center justify-start')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 flex justify-start')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$placeholder(locale.iB),
																				$elm$html$Html$Events$onInput($author$project$Ui$CreateTeam$SetQuery),
																				$elm$html$Html$Attributes$class('outline-none border shadow w-3/12 h-6')
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 flex justify-start')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$placeholder(locale.iB),
																				$elm$html$Html$Events$onInput($author$project$Ui$CreateTeam$SetQuery),
																				$elm$html$Html$Attributes$class('outline-none border shadow w-3/12 h-6')
																			]),
																		_List_Nil)
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('w-full h-5/6')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex flex-row h-5/6 border shadow-lg')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('w-1/2 overflow-y-scroll border-r-2')
																	]),
																_List_fromArray(
																	[
																		function () {
																		if (!_Utils_eq(acceptableUsers, _List_Nil)) {
																			var config = $billstclair$elm_sortable_table$Table$customConfig(
																				{
																					fg: _List_fromArray(
																						[$author$project$Ui$CreateTeam$idColumn, $author$project$Ui$CreateTeam$nameColumn, $author$project$Ui$CreateTeam$emailColumn, $author$project$Ui$CreateTeam$addUserColumn]),
																					fy: $author$project$Utils$przTableCustomizations,
																					jm: function ($) {
																						return $.cA;
																					},
																					jn: $author$project$Ui$CreateTeam$SetTableState
																				});
																			return A3($billstclair$elm_sortable_table$Table$view, config, model.cp, acceptableUsers);
																		} else {
																			return $elm$html$Html$text('');
																		}
																	}()
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('overflow-y-scroll w-1/2')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex flex-row flex-wrap')
																			]),
																		A2($elm$core$List$map, $author$project$Ui$CreateTeam$viewUsers, model.cs.jv))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('w-full flex items-center justify-center h-1/6'),
																$elm$html$Html$Events$onClick($author$project$Ui$CreateTeam$ValidButtonClicked)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('font-bold flex items-center justify-center border bg-przred rounded-lg text-white cursor-pointer py-1 px-2')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(locale.jy)
																	]))
															]))
													]))
											]));
								default:
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex flex-col h-full w-full items-start justify-start')
											]),
										_List_fromArray(
											[
												A2(
												$author$project$Utils$icon,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-1/6 flex flex-row w-full justify-end cursor-pointer'),
														$elm$html$Html$Events$onClick($author$project$Ui$CreateTeam$CrossClicked)
													]),
												'close'),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('h-5/6 w-full flex items-center justify-center font-bold')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(locale.gf)
													]))
											]));
							}
						}()
						]))
				]));
	});
var $author$project$Page$Team$view = F2(
	function (locale, model) {
		var _v0 = model.jt.f5;
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(locale.ho)
					]));
		} else {
			var _v1 = model.bi;
			if (!_v1.$) {
				var subModel = _v1.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Page$Team$GotUsersFromTeamResponse,
					A2($author$project$Page$TeamDetails$view, locale, subModel));
			} else {
				var teamToPushInTable = $elm_community$maybe_extra$Maybe$Extra$values(
					A2(
						$elm$core$List$map,
						function (team) {
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(model.ce),
								$elm$core$String$toLower(team.dL)) ? $elm$core$Maybe$Just(team) : $elm$core$Maybe$Nothing;
						},
						model.jc));
				var config = $billstclair$elm_sortable_table$Table$customConfig(
					{
						fg: _List_fromArray(
							[
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Team name',
								function ($) {
									return $.dL;
								}),
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Creation date',
								function (team) {
									return A2($author$project$Ui$Date$toShortDate, $elm$time$Time$utc, team.g2.dh);
								}),
								A2(
								$billstclair$elm_sortable_table$Table$stringColumn,
								'Created by',
								function (team) {
									return team.g2.dj.dL;
								}),
								A2(
								$billstclair$elm_sortable_table$Table$intColumn,
								'Direct members',
								function (team) {
									return $elm$core$List$length(team.jv);
								}),
								A2(
								$billstclair$elm_sortable_table$Table$intColumn,
								'Total members',
								function (team) {
									return team.jp;
								}),
								$author$project$Page$Team$removeColumn,
								$author$project$Page$Team$teamClickColumn
							]),
						fy: $author$project$Utils$przTableCustomizations,
						jm: function ($) {
							return $.a$;
						},
						jn: $author$project$Page$Team$SetTableState
					});
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('w-full flex flex-col items-start')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pl-8 font-bold mt-8 mb-4')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(locale.jc)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex flex-row w-full pr-8 pl-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$placeholder(locale.iB),
											$elm$html$Html$Attributes$class('w-3/12 border shadow outline-none'),
											$elm$html$Html$Events$onInput($author$project$Page$Team$SetQuery)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('w-9/12 flex justify-end')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Page$Team$CreateTeamClicked),
													$elm$html$Html$Attributes$class('bg-przred px-2 font-bold text-white cursor-pointer outline-none select-none ')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(locale.hA)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('w-full pr-8 mt-4 pl-8')
								]),
							_List_fromArray(
								[
									A3($billstclair$elm_sortable_table$Table$view, config, model.cp, teamToPushInTable)
								])),
							function () {
							var _v2 = model.ae;
							if (!_v2.$) {
								var subModel = _v2.a;
								return A2(
									$elm$html$Html$map,
									$author$project$Page$Team$GotCreateTeamMsg,
									A2($author$project$Ui$CreateTeam$view, locale, subModel));
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]));
			}
		}
	});
var $author$project$Page$Test$view = F3(
	function (_v0, _v1, _v2) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('do you tests here but don\'t commit!')
				]));
	});
var $author$project$LoggedIn$isAdmin = function (user) {
	return user.gV;
};
var $author$project$Ui$LeftNavBar$view = F4(
	function (user, currentRoute, libraryRoute, teamRoute) {
		var sidebarSelectedBg = $elm$html$Html$Attributes$class('w-full bg-opacity-50 bg-gray-600');
		var sidebarHoverBg = $elm$html$Html$Attributes$class('w-full hover:bg-gray-400 hover:bg-opacity-25');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col flex-grow justify-start items-center'),
					$elm$html$Html$Attributes$class('bg-red-pink-gradient w-12')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$Route$href(libraryRoute),
							$elm$html$Html$Attributes$class('select-none'),
							sidebarHoverBg
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white text-4xl py-4'),
									function () {
									if (currentRoute.$ === 3) {
										return sidebarSelectedBg;
									} else {
										return $elm$html$Html$Attributes$class('');
									}
								}()
								]),
							'dataset')
						])),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$Route$href(teamRoute),
							$elm$html$Html$Attributes$class('select-none'),
							sidebarHoverBg
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white text-4xl py-4'),
									_Utils_eq(currentRoute, $author$project$Route$Team) ? sidebarSelectedBg : $elm$html$Html$Attributes$class('')
								]),
							'groups')
						])),
					user.gW ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$Route$href($author$project$Route$Enterprise),
							$elm$html$Html$Attributes$class('select-none'),
							sidebarHoverBg
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white text-4xl py-4'),
									_Utils_eq(currentRoute, $author$project$Route$Enterprise) ? sidebarSelectedBg : $elm$html$Html$Attributes$class('')
								]),
							'manage_accounts')
						])) : $elm$html$Html$text(''),
					$author$project$LoggedIn$isAdmin(user) ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$author$project$Route$href($author$project$Route$Admin),
							$elm$html$Html$Attributes$class('select-none'),
							sidebarHoverBg
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-white text-4xl py-4'),
									_Utils_eq(currentRoute, $author$project$Route$Admin) ? sidebarSelectedBg : $elm$html$Html$Attributes$class('')
								]),
							'admin_panel_settings')
						])) : $elm$html$Html$text('')
				]));
	});
var $author$project$Ui$UserMenu$UserMenuOpened = {$: 0};
var $author$project$Assets$srcBlankProfile = function (assets) {
	return $elm$html$Html$Attributes$src(assets.da);
};
var $author$project$Ui$UserMenu$LogoutClicked = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$UserMenu$UserMenuClosed = {$: 1};
var $author$project$Ui$UserMenu$viewUserMenu = F4(
	function (locale, model, toMsg, key) {
		return model.bN ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fixed inset-0'),
							$elm$html$Html$Events$onClick(
							toMsg($author$project$Ui$UserMenu$UserMenuClosed))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('absolute py-2 shadow-2xl border-solid bg-white top-0 right-0'),
							$elm$html$Html$Attributes$class('flex flex-col text-przgray mt-1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									toMsg(
										$author$project$Ui$UserMenu$LogoutClicked(key))),
									$elm$html$Html$Attributes$class('flex flex-row items-center px-4 py-2'),
									$elm$html$Html$Attributes$class('hover:bg-przlightgray')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Utils$icon,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mr-2')
										]),
									'power_settings_new'),
									$elm$html$Html$text(locale.g7)
								]))
						]))
				])) : $elm$html$Html$text('');
	});
var $author$project$Ui$UserMenu$view = F6(
	function (locale, model, user, assets, key, toMsg) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
							A2($elm$html$Html$Attributes$style, 'margin', '13px 8px 0 0'),
							A2($elm$html$Html$Attributes$style, 'float', 'left')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Utils$icon,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select-none'),
									A2($elm$html$Html$Attributes$style, 'margin-right', '6px')
								]),
							'notifications_none'),
							$elm$html$Html$text(
							$author$project$LoggedIn$fullName(user)),
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							function () {
								var _v0 = user.f5;
								if (_v0.$ === 1) {
									return _List_Nil;
								} else {
									var e = _v0.a;
									return _List_fromArray(
										[
											$elm$html$Html$text(e.dL)
										]);
								}
							}())
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'inline-block')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									toMsg($author$project$Ui$UserMenu$UserMenuOpened)),
									$elm$html$Html$Attributes$class('mx-2 border-none outline-none')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('rounded-full border-black border-2 w-12 h-12 my-0'),
											$author$project$Assets$srcBlankProfile(assets)
										]),
									_List_Nil)
								]))
						])),
					A4($author$project$Ui$UserMenu$viewUserMenu, locale, model, toMsg, key)
				]));
	});
var $author$project$Ui$Layout$Main$viewTopNavBar = F3(
	function (locale, model, toMsg) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-row border-b border-gray')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$author$project$Assets$srcPrezanceLogoBlack(model.v),
							$elm$html$Html$Attributes$class('h-16 my-0')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row flex-grow items-center'),
							$elm$html$Html$Attributes$class('items-stretch justify-center my-0')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-row items-center flex-grow-0 my-0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							toMsg,
							A6($author$project$Ui$UserMenu$view, locale, model.bM, model.jt, model.v, model.k, $author$project$Ui$Layout$Main$GotUserMenuMsg))
						]))
				]));
	});
var $author$project$Ui$Layout$Main$view = F4(
	function (locale, model, toMsg, page) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bg-przlightgray')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fixed h-16 w-full z-50 bg-white')
						]),
					_List_fromArray(
						[
							A3($author$project$Ui$Layout$Main$viewTopNavBar, locale, model, toMsg)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fixed w-12 h-full top-16 bg-pink-500 flex flex-col')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Ui$LeftNavBar$view,
							model.jt,
							model.bE,
							A2($author$project$Route$Library, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
							$author$project$Route$Team)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pl-16 pt-20 min-h-screen w-full pr-4')
						]),
					_List_fromArray(
						[page]))
				]));
	});
var $author$project$Ui$Layout$Unlogged$view = function (page) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex flex-row justify-center items-center h-screen w-screen bg-przlightgray p-64')
			]),
		_List_fromArray(
			[page]));
};
var $author$project$Main$view = function (model) {
	var page = function () {
		var _v1 = model.d;
		switch (_v1.$) {
			case 0:
				return $elm$html$Html$text('');
			case 5:
				switch (_v1.b.$) {
					case 0:
						var pageModel = _v1.b.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Main$GotLibraryPageMsg,
							A2($author$project$Page$Library$view, model.w, pageModel));
					case 1:
						var pageModel = _v1.b.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Main$GotAdminPageMsg,
							A3($author$project$Page$Admin$view, model.w, model.v, pageModel));
					case 2:
						var pageModel = _v1.b.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Main$GotTestPageMsg,
							A3($author$project$Page$Test$view, model.w, model.v, pageModel));
					case 3:
						var pageModel = _v1.b.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Main$GotTeamPageMsg,
							A2($author$project$Page$Team$view, model.w, pageModel));
					default:
						var pageModel = _v1.b.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Main$GotEnterprisePageMsg,
							A2($author$project$Page$Enterprise$view, model.w, pageModel));
				}
			case 1:
				var pageModel = _v1.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotLoginPageMsg,
					A3($author$project$Page$Login$view, model.w, model.v, pageModel));
			case 2:
				var pageModel = _v1.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotRegisterPageMsg,
					A3($author$project$Page$Register$view, model.w, model.v, pageModel));
			case 4:
				var pageModel = _v1.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotConfirmRegistrationPageMsg,
					A3($author$project$Page$Confirm$view, model.w, model.v, pageModel));
			default:
				var pageModel = _v1.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$GotResetPasswordPageMsg,
					A3($author$project$Page$ResetPassword$view, model.w, model.v, pageModel));
		}
	}();
	var content = function () {
		var _v0 = model.o;
		if (!_v0.$) {
			return $author$project$Ui$Layout$Unlogged$view(page);
		} else {
			var layoutModel = _v0.a;
			return A4($author$project$Ui$Layout$Main$view, model.w, layoutModel, $author$project$Main$GotMainLayoutMsg, page);
		}
	}();
	return {
		D: _List_fromArray(
			[content]),
		jl: 'xEquiv'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{gO: $author$project$Main$init, hR: $author$project$Main$UrlChanged, hS: $author$project$Main$UrlRequested, i4: $author$project$Main$subscriptions, jr: $author$project$Main$update, jI: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (user) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (locale) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (assets) {
							return $elm$json$Json$Decode$succeed(
								{v: assets, w: locale, jt: user});
						},
						A2(
							$elm$json$Json$Decode$field,
							'assets',
							A2(
								$elm$json$Json$Decode$andThen,
								function (videoPath) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (videoLinkPath) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (ticketPath) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (textPath) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (tableauPath) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (tablePath) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (stepPath) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (sonnettePath) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (settingsPath) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (projectPath) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (prezanceLogoBlackPath) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (positionPath) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (pdfPath) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (paysagePath) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (magnetWhitePath) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (magnetUpdateWhitePath) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (magnetIconPath) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (magnetColorPath) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (lorem3Path) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (lorem2Path) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (lorem1Path) {
																																																	return A2(
																																																		$elm$json$Json$Decode$andThen,
																																																		function (iconModeZa1Path) {
																																																			return A2(
																																																				$elm$json$Json$Decode$andThen,
																																																				function (h2IconPath) {
																																																					return A2(
																																																						$elm$json$Json$Decode$andThen,
																																																						function (h1IconPath) {
																																																							return A2(
																																																								$elm$json$Json$Decode$andThen,
																																																								function (graphPath) {
																																																									return A2(
																																																										$elm$json$Json$Decode$andThen,
																																																										function (framePath) {
																																																											return A2(
																																																												$elm$json$Json$Decode$andThen,
																																																												function (fakeAlignPath) {
																																																													return A2(
																																																														$elm$json$Json$Decode$andThen,
																																																														function (ellipsePath) {
																																																															return A2(
																																																																$elm$json$Json$Decode$andThen,
																																																																function (bookshelfPath) {
																																																																	return A2(
																																																																		$elm$json$Json$Decode$andThen,
																																																																		function (blankProfilePath) {
																																																																			return A2(
																																																																				$elm$json$Json$Decode$andThen,
																																																																				function (basrosePath) {
																																																																					return A2(
																																																																						$elm$json$Json$Decode$andThen,
																																																																						function (basnoirPath) {
																																																																							return A2(
																																																																								$elm$json$Json$Decode$andThen,
																																																																								function (barPath) {
																																																																									return A2(
																																																																										$elm$json$Json$Decode$andThen,
																																																																										function (attributeIconPath) {
																																																																											return A2(
																																																																												$elm$json$Json$Decode$andThen,
																																																																												function (addIconPath) {
																																																																													return $elm$json$Json$Decode$succeed(
																																																																														{ex: addIconPath, eU: attributeIconPath, e5: barPath, e6: basnoirPath, e7: basrosePath, da: blankProfilePath, e8: bookshelfPath, f1: ellipsePath, gm: fakeAlignPath, gw: framePath, gA: graphPath, gC: h1IconPath, gD: h2IconPath, gI: iconModeZa1Path, g8: lorem1Path, g9: lorem2Path, ha: lorem3Path, hc: magnetColorPath, he: magnetIconPath, hi: magnetUpdateWhitePath, hj: magnetWhitePath, h$: paysagePath, h1: pdfPath, h7: positionPath, dV: prezanceLogoBlackPath, ia: projectPath, iH: settingsPath, iU: sonnettePath, iZ: stepPath, i7: tablePath, i8: tableauPath, jh: textPath, jk: ticketPath, jG: videoLinkPath, jH: videoPath});
																																																																												},
																																																																												A2($elm$json$Json$Decode$field, 'addIconPath', $elm$json$Json$Decode$string));
																																																																										},
																																																																										A2($elm$json$Json$Decode$field, 'attributeIconPath', $elm$json$Json$Decode$string));
																																																																								},
																																																																								A2($elm$json$Json$Decode$field, 'barPath', $elm$json$Json$Decode$string));
																																																																						},
																																																																						A2($elm$json$Json$Decode$field, 'basnoirPath', $elm$json$Json$Decode$string));
																																																																				},
																																																																				A2($elm$json$Json$Decode$field, 'basrosePath', $elm$json$Json$Decode$string));
																																																																		},
																																																																		A2($elm$json$Json$Decode$field, 'blankProfilePath', $elm$json$Json$Decode$string));
																																																																},
																																																																A2($elm$json$Json$Decode$field, 'bookshelfPath', $elm$json$Json$Decode$string));
																																																														},
																																																														A2($elm$json$Json$Decode$field, 'ellipsePath', $elm$json$Json$Decode$string));
																																																												},
																																																												A2($elm$json$Json$Decode$field, 'fakeAlignPath', $elm$json$Json$Decode$string));
																																																										},
																																																										A2($elm$json$Json$Decode$field, 'framePath', $elm$json$Json$Decode$string));
																																																								},
																																																								A2($elm$json$Json$Decode$field, 'graphPath', $elm$json$Json$Decode$string));
																																																						},
																																																						A2($elm$json$Json$Decode$field, 'h1IconPath', $elm$json$Json$Decode$string));
																																																				},
																																																				A2($elm$json$Json$Decode$field, 'h2IconPath', $elm$json$Json$Decode$string));
																																																		},
																																																		A2($elm$json$Json$Decode$field, 'iconModeZa1Path', $elm$json$Json$Decode$string));
																																																},
																																																A2($elm$json$Json$Decode$field, 'lorem1Path', $elm$json$Json$Decode$string));
																																														},
																																														A2($elm$json$Json$Decode$field, 'lorem2Path', $elm$json$Json$Decode$string));
																																												},
																																												A2($elm$json$Json$Decode$field, 'lorem3Path', $elm$json$Json$Decode$string));
																																										},
																																										A2($elm$json$Json$Decode$field, 'magnetColorPath', $elm$json$Json$Decode$string));
																																								},
																																								A2($elm$json$Json$Decode$field, 'magnetIconPath', $elm$json$Json$Decode$string));
																																						},
																																						A2($elm$json$Json$Decode$field, 'magnetUpdateWhitePath', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'magnetWhitePath', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'paysagePath', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'pdfPath', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'positionPath', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'prezanceLogoBlackPath', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'projectPath', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'settingsPath', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'sonnettePath', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'stepPath', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'tablePath', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'tableauPath', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'textPath', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'ticketPath', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'videoLinkPath', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'videoPath', $elm$json$Json$Decode$string))));
				},
				A2($elm$json$Json$Decode$field, 'locale', $elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$field,
			'user',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
					])))))(0)}});}(this));